/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBurgenland: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 52960,
        enable: true,
        projectName: 'Autocenter Burgenland',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"Nunito Sans", "Open Sans", "Arial", sans-serif',
        primaryColor: '#ee8339',
    },
};

export default configBurgenland;
