/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configHofmann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30169,
        isGruppe: true,
        enable: true,
        projectName: 'Auto Hofmann',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'iznKSZrRW6',
            origin: 'auto-hofmann.de',
            tradeIn: true,
        },
    },
    style: {
        ...configDefault.style,
        fontFamily: '"Mulish", "Open Sans", "Arial", sans-serif',
        fontSize: 16,
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
        }),
        primaryColor: '#194e8c',
    },
};

export default configHofmann;
