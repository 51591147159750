export enum EffizienzClass {
    A = 10,
    B = 9,
    C = 8,
    D = 7,
    E = 6,
    F = 5,
    G = 4,
    N_A = 0,
}

type EffizienzPropsActiveSteps = { combined: EffizienzClass, discharged: EffizienzClass | undefined };

export type EffizienzProps = {
    stepHeight: number,
} & ({
    steps?: EffizienzPropsActiveSteps,
    type: 'stair',
} | {
    step: EffizienzClass,
    type: 'step',
});

export type EffizienzStepProps = {
    height: number,
    index: number,
    selector?: boolean,
    selectorShown?: boolean,
    type: EffizienzClass,
};
