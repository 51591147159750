import { Divider, Stack, Typography } from '@mui/material';
import { EffizienzClass, EffizienzProps, EffizienzStepProps } from './types';
import { effizienzSteps } from './steps';
import { checkUndefinedOrNull, getStyleQuery } from '../../utils/tools';
import Iconify from '../iconify/component';
import useLocales from '../../hooks/useLocales';

export function EffizienzStep(props: EffizienzStepProps) {
    const { height, index, selector, selectorShown, type } = props;
    const heightX05 = height * 0.5;
    const selectedStep = effizienzSteps?.find((s) => s?.i === type);

    return <>
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                [getStyleQuery()]: {
                    color: `${selector ? '#232323' : selectedStep?.color} !important`,
                    fontSize: `${height}px !important`,
                    height: `${height}px !important`,
                    position: 'relative',
                    opacity: `${!selector || selectorShown ? 1 : 0} !important`,
                },
            }}
        >
            <Iconify icon={{
                body: `<path d="${selector ? ' M0,12.5L25,0L75,0L75,25L25,25L0,12.5' : `M0,0L${50 + (25 * index)},0L${75 + (25 * index)},12.5L${50 + (25 * index)},25L0,25L0,0`}" fill="currentColor" />`,
                height: 25,
                width: selector ? 75 : (75 + (25 * index)),
            }} sx={{
                [getStyleQuery()]: {
                    color: 'currentColor !important',
                    height: `${height}px !important`,
                    width: 'auto !important',
                },
            }} width="auto" />
            <Typography component="span" sx={{
                [getStyleQuery()]: {
                    color: '#fff !important',
                    fontSize: '0.7em !important',
                    fontWeight: '700 !important',
                    lineHeight: `${height}px !important`,
                    [selector ? 'pr' : 'pl']: `${heightX05}px !important`,
                    position: 'absolute',
                    top: 0,
                    [selector ? 'right' : 'left']: 0,
                    bottom: 0,
                    width: 'fit-content !important',
                },
            }}>
                {selectedStep?.t}
            </Typography>
        </Stack>
    </>;
}

export function Effizienz(props: EffizienzProps) {
    const { stepHeight, type } = props;
    const { translate } = useLocales();
    const stepsToShow = effizienzSteps?.filter((e) => e?.i !== EffizienzClass.N_A);
    if (type === 'stair') {
        const { steps } = props;
        return <Stack direction="row" gap={2} alignItems="center" sx={{
            [getStyleQuery()]: {
                fontSize: `${stepHeight}px !important`,
                width: 1,
            },
        }}>
            <Stack alignItems="flex-start" gap={0.5} sx={{
                [getStyleQuery()]: {
                    width: 1,
                },
            }}>
                {!checkUndefinedOrNull(steps?.discharged) && <Typography sx={{
                    [getStyleQuery()]: {
                        fontWeight: '500 !important',
                        fontSize: '0.85em !important',
                        opacity: 0,
                        textAlign: 'right !important',
                        whiteSpace: 'pre !important',
                        width: '100% !important',
                    },
                }}>
                    {`${translate('common:carView.verbrauch.combined')},\n${translate('common:carView.verbrauch.weighted')}`}

                </Typography>}
                {stepsToShow?.map((e, i) => (
                    <EffizienzStep key={`Effizienz_Steps_${e?.i}`} height={stepHeight} index={i} type={e?.i as EffizienzClass} />
                ))}
            </Stack>
            {
                !checkUndefinedOrNull(steps?.combined) && <Stack alignItems="flex-end" gap={0.5}>
                    {!checkUndefinedOrNull(steps?.discharged) && <Typography sx={{
                        [getStyleQuery()]: {
                            fontWeight: '500 !important',
                            fontSize: '0.85em !important',
                            textAlign: 'right !important',
                            whiteSpace: 'pre !important',
                            width: '100% !important',
                        },
                    }}>
                        {`${translate('common:carView.verbrauch.combined')},\n${translate('common:carView.verbrauch.weighted')}`}
                    </Typography>}
                    {stepsToShow?.map((e, i) => (
                        <EffizienzStep key={`Effizienz_Steps_${e?.i}`} height={stepHeight} index={i} selector selectorShown={steps?.combined === e?.i} type={e?.i as EffizienzClass} />
                    ))}
                </Stack>
            }
            {
                !checkUndefinedOrNull(steps?.discharged) && <>
                    <Divider flexItem orientation="vertical" />
                    <Stack alignItems="flex-end" gap={0.5}>
                        <Typography sx={{
                            [getStyleQuery()]: {
                                fontWeight: '500 !important',
                                fontSize: '0.85em !important',
                                textAlign: 'right !important',
                                whiteSpace: 'pre !important',
                                width: '100% !important',
                            },
                        }}>
                            {translate('common:carView.verbrauch.discharged')}
                        </Typography>
                        {stepsToShow?.map((e, i) => (
                            <EffizienzStep key={`Effizienz_Steps_${e?.i}`} height={stepHeight} index={i} selector selectorShown={steps?.discharged === e?.i} type={e?.i as EffizienzClass} />
                        ))}
                    </Stack>
                </>
            }
        </Stack >;
    } else if (type === 'step') {
        const { step } = props;
        return <EffizienzStep height={stepHeight} index={0} type={step} />;
    } else {
        return `Unknown type: ${type}`;
    }
}
