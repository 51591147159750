/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configVollmer: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 11440,
        enable: true,
        projectName: 'Autohaus Vollmer',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/impressum',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        background: '#58585a',
        borderRadius: 0,
        fontFamily: '"Montserrat", "Open Sans", "Arial", sans-serif',
        fontSize: 15,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'div#ap-widget-button': {
                            bottom: '60px !important',
                            position: 'absolute !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'background': '#fff',
                            'color': '<colorPrimary>',
                            '&:hover': {
                                background: '<colorPrimary>',
                                color: '#fff',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                borderColor: 'rgba(145, 158, 171, 0.24)',
                                color: 'rgba(145, 158, 171, 0.8)',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            // contact buttons next to fabs & exposee button
                            '&[data-testid^="UseContact_ContactTextButton_"], &#detailview2_button_exposee': {
                                background: '#d6e2fe',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& p.MuiTypography-h4': {
                                marginBottom: '0 !important',
                            },
                            '&[data-testid="CalcFdSummary_Card"] .MuiTypography-h5': {
                                color: '#fff',
                            },
                            '&[data-testid^="carTile_"] p.MuiTypography-body1': { // Car details grid text
                                color: '#000',
                            },
                            '& .MuiTypography-h5': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': 'transparent',
                            'borderBottom': '1px rgba(145, 158, 171, 0.2) dashed',
                            '&[data-testid="MiniFooter_Divider_0"] + div.MuiStack-root > span.MuiTypography-caption': { // Kleingedrucktes Messwerte
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&#calculatorSection p.MuiTypography-caption': { // Kleingedrucktes Rate
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': { // Car details grid text
                                color: '#000',
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent !important',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="backToList"]': {
                                color: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5': {
                                color: '#000',
                            },
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text.MuiButton-textInherit.MuiButton-textSizeMedium': { // sort & view buttons
                                'color': '<colorSecondary>',
                            },
                            '&[data-testid="FahrzeugSucheFilterTopArea_Container"]': { // topbar filter
                                background: '#fff',
                                padding: '20px',
                            },
                            '&[data-testid="FahrzeugSucheFilterChips_results"] h6.MuiTypography-subtitle1, &[data-testid="FahrzeugSucheFilterChips_results"] button.MuiIconButton-root[data-testid="CarListSort_smallSortBtn"]': { // "xyz Fahrzeuge gefunden" & small sort icon-button
                                color: '<colorSecondary>',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            outline: 'none',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#656565',
                    marginBottom: 0,
                },
            },
            caption: {
                [getStyleQuery()]: {
                    marginBottom: 0,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    marginBottom: '0 !important',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    marginBottom: '5 !important',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    marginBottom: '0 !important',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#000',
                    marginBottom: '0 !important',
                },
            },
        }),
        primaryColor: '#014A99',
        secondaryColor: '#99CC00',
    },
};

export default configVollmer;
