import { FaarenCarPricePlan, FaarenPickupDeliveryOption } from '../_tools/types/faaren';
import { EffizienzClass } from '../components/effizienz/types';
import { IconifyProps } from '../components/iconify/types';

export type SelectOption = {
    i: string | number,
    iSend?: string | string[] | number | number[]
    t: string,
    et?: string,
    icon?: IconifyProps,
    group?: string,
    details?: string[],
    nested?: number,
    showLabel?: boolean,
};

export type CarPlugType = 'TYPE_1' | 'TYPE_2' | 'CCS_COMBO' | 'CHADEMO' | 'SCHUKO' | 'TESLA_SC';

type WltpData = {
    /* Costs 15000km */
    a_costs15?: number,
    /* Charging Time */
    chargingTime?: number,
    /* Charging Time Fast */
    chargingTimeFast?: number,
    /* CO2 Class */
    co2class?: string,
    /* CO2 Class Discharged */
    co2classDischarged?: string,
    /* CO2 Class Weighted */
    co2classWeighted?: string,
    /* CO2-Costs low */
    co2costslow?: number,
    /* CO2-Costs medium */
    co2costsmedium?: number,
    /* CO2-Costs high */
    co2costshigh?: number,
    /* CO2-Costs from year */
    co2costsfromyear?: number,
    /* CO2-Costs to year */
    co2coststoyear?: number,
    /* CO2-Tarif low */
    co2tarifflow?: number,
    /* CO2-Tarif medium */
    co2tariffmedium?: number,
    /* CO2-Tarif high */
    co2tariffhigh?: number,
    /* consumptionunit */
    consumptionunit?: string,
    /* envkvtype */
    envkvtype?: string,
    /* Fuel-Costs */
    fuel_costs?: number,
    /* Fuel-tarif year */
    fueltariffyear?: number,
    /** ID Car */
    idCar?: Car<false>['id'],
    /** Float Combined fuel consumption for all nonelectric vehicles, optional for plugin hybrids, number in l/100km (natural gas (CNG) in kg/100km) */
    mchanged?: boolean,
    /* Plug types */
    plugTypes?: CarPlugType[],
    /** Tax */
    tax?: number,
    /** Consumption Fuel Combined */
    w_cfc?: number,
    /** Consumption Fuel Combined Discharged */
    w_cfcDischarged?: number,
    /** Consumption Fuel Combined Weighted */
    w_cfcw?: number,
    /** Consumption Fuel Low */
    w_cfl?: number,
    /** Consumption Fuel Medium */
    w_cfm?: number,
    /** Consumption Fuel High */
    w_cfh?: number,
    /** Consumption Fuel ExtraHigh */
    w_cfeh?: number,
    /** CO2 Emission Combined */
    w_co2ec?: number,
    /** CO2 Emission Combined Weighted */
    w_co2ecw?: number,
    /** CO2-Emission Combined Discharged */
    w_co2ecDischarged?: number,
    /** Consumption Power Combined */
    w_cpc?: number,
    /** Consumption Power Combined Weighted */
    w_cpcw?: number,
    /** Consumption Power Low */
    w_cpl?: number,
    /** Consumption Power Medium */
    w_cpm?: number,
    /** Consumption Power High */
    w_cph?: number,
    /** Consumption Power ExtraHigh */
    w_cpeh?: number,
    /** Range */
    w_er?: number,
};

export enum CarKategorie {
    MOTORRAD = 'KRAD',
    PKW = 'PKW',
}

export type User = {
    i?: number,
    idf?: number,
    a?: string,
    s?: number,
    vn?: string,
    nn?: string,
    m?: string,
    t?: string,
    img?: string
};

export type UserSelect = {
    i?: number,
    idf?: number,
    a?: string,
    s?: number,
    vn?: string,
    nn?: string,
    m?: string,
    t?: string,
    img?: string
};

export type CarVerbrauchParsed = {
    combined: string,
    combinedSecondary: string,
    combinedDischarged: string,
    effizienz: EffizienzClass,
    effizienzDischarged: EffizienzClass,
    emission: string,
    emissionDischarged: string,
    energycosts: string,
    envkv: boolean,
    co2costsLow: string,
    co2costsMedium: string,
    co2costsHigh: string,
    euronorm: string,
    extraHigh: string,
    extraHighDischarged: string,
    high: string,
    highDischarged: string,
    motor: string,
    low: string,
    lowDischarged: string,
    medium: string,
    mediumDischarged: string,
    range: string,
};

export type CarVerbrauchParsedNumbered = {
    combined: number,
    combinedSecondary: number,
    combinedDischarged: number,
    effizienz: EffizienzClass,
    effizienzDischarged: EffizienzClass,
    emission: number,
    emissionDischarged: number,
    energycosts: number,
    co2costsLow: number,
    co2costsMedium: number,
    co2costsHigh: number,
    extraHigh: number,
    extraHighDischarged: number,
    high: number,
    highDischarged: number,
    low: number,
    lowDischarged: number,
    medium: number,
    mediumDischarged: number,
    range: number,
};

export type CarAusstattungKey = keyof Omit<Car<false>['desc'], 'fuss' | 'isList' | 'kopf'>;
export const sortedAusCat: CarAusstattungKey[] = [
    'highlights',
    'pakete',
    'assistenz',
    'multimedia',
    'innen',
    'sitze',
    'scheinwerfer',
    'aussen',
    'raeder',
    'technik',
    'sonstiges',
    'fussnoten',
];

export type Car<Raw extends boolean> = {
    aktion?: Raw extends true ? ('0' | '1' | '') : boolean,
    antrieb?: string,
    aufbau?: string,
    ausstattung?: Record<Raw extends true ? string : CarAusstattungKey, {
        txt?: string,
        typ?: number,
        sg?: number,
        style?: number,
        w?: number,
        origin?: string,
        kat?: number
    }[]>,
    autoAbo?: {
        additional_milage_costs: number,
        damage_participation: number,
        delivery_option: FaarenPickupDeliveryOption[],
        delivery_time_after_booking: number,
        fixed_duration: boolean,
        immediately_available: boolean,
        min_license_duration: number,
        minimum_age: number,
        pick_up_option: FaarenPickupDeliveryOption[],
        plans: FaarenCarPricePlan[],
    },
    bilder?: {
        '360'?: string,
        '360a'?: string,
        '360a_w'?: number,
        '360a_h'?: number,
        anz360a?: number,
        bilder?: number,
        ec?: boolean,
        hd?: boolean,
        pano?: string,
        url?: string[],
        urlHD?: string[],
        urlS?: string[]
    },
    desc?: {
        assistenz?: string,
        aussen?: string,
        finanzierung?: string,
        fuss?: string,
        fussnoten?: string,
        garantie?: string,
        highlights?: string,
        innen?: string,
        isList?: boolean,
        kopf?: string,
        multimedia?: string,
        pakete?: string,
        preissenkung?: string,
        raeder?: string,
        scheinwerfer?: string,
        sitze?: string,
        sonstiges?: string,
        technik?: string,
    },
    design?: string,
    engine?: {
        effizienz?: string,
        envkv?: boolean,
        hubraum?: number,
        kraftstoff?: string,
        kraftstoff_1?: string,
        leistung?: Raw extends true ? [
            number?,
            number?,
        ] : string,
        motor?: string,
        motorTooltip?: string,
        plakette?: string,
        schadstoff?: string,
    } & (Raw extends true ? {
        verbrauch_0?: [
            number?,
            number?,
            number?,
            number?,
        ],
        verbrauch_1?: [
            number?,
            number?,
            number?,
            number?,
        ],
        wltp?: WltpData,
    } : {
        leistungKw: string,
        leistungKwNumbered: number,
    }),
    ez?: Raw extends true ? string : Date,
    fahrbereit?: boolean,
    farbe?: Raw extends true ? [
        string?,
        string?,
        string?,
    ] : {
        grund?: string,
        name?: string,
    },
    farbe_innen?: Raw extends true ? [
        string?,
        string?,
    ] : {
        grund?: string,
        name?: string,
    },
    finanzierung?: {
        abschlussgebühr?: number,
        anbieterbank?: string,
        anzahlung?: number,
        art_sollzinssatzes?: string,
        bruttokreditbetrag?: number,
        effektiver_jahreszins?: number,
        km?: Raw extends true ? string : number,
        ksb?: string,
        ksb_betrag?: Raw extends true ? string : number,
        laufzeit?: number,
        monatliche_rate?: number,
        monatliche_rate_netto?: number,
        nettrokreditbetrag?: Raw extends true ? string : number,
        schlussrate?: number,
        soll_zinssatz?: Raw extends true ? string : number,
        text1?: string,
        text2?: string,
    },
    Firma?: string,
    firmaName?: string,
    firmaAlias?: string,
    garantie?: Raw extends true ? [
        number?,
        string?,
    ] : { id?: number, name?: string },
    getriebe?: string,
    herkunft?: number,
    hersteller?: string,
    hsn?: string,
    hu?: Raw extends true ? string : Date,
    id?: number,
    idStandort?: number,
    ident?: string,
    kategorie?: CarKategorie,
    km?: number,
    idFirma?: number,
    is_eyecatcher?: string,
    is_id_logo?: number,
    is_id_firmenimage?: number,
    land?: string,
    leasing?: {
        anbieterbank?: string,
        anzahlung?: number,
        DestinationCharges?: Raw extends true ? string : number,
        effektiver_jahreszins?: Raw extends true ? string : number,
        ExtraMileageCosts?: Raw extends true ? string : number,
        km?: Raw extends true ? string : number,
        laufzeit?: Raw extends true ? string : number,
        LowMileageCompensation?: Raw extends true ? string : number,
        monatliche_rate?: Raw extends true ? string : number,
        monatliche_rate_netto?: Raw extends true ? string : number,
        nettrokreditbetrag?: Raw extends true ? string : number,
        RegistrationFees?: Raw extends true ? string : number,
        soll_zinssatz?: Raw extends true ? string : number,
        TotalAmount?: Raw extends true ? string : number,
        vk?: number,
        zielgruppe?: 'PRIVATE',
    },
    leasingComm?: {
        anbieterbank?: string,
        anzahlung?: number,
        DestinationCharges?: Raw extends true ? string : number,
        ExtraMileageCosts?: Raw extends true ? string : number,
        effektiver_jahreszins?: Raw extends true ? string : number,
        km?: Raw extends true ? string : number,
        laufzeit?: Raw extends true ? string : number,
        LowMileageCompensation?: Raw extends true ? string : number,
        monatliche_rate?: Raw extends true ? string : number,
        monatliche_rate_netto?: Raw extends true ? string : number,
        nettrokreditbetrag?: Raw extends true ? string : number,
        RegistrationFees?: Raw extends true ? string : number,
        soll_zinssatz?: Raw extends true ? string : number,
        TotalAmount?: Raw extends true ? string : number,
        vk?: number,
        zielgruppe?: 'COMMERCIAL',
    },
    lHighlights?: string[],
    lieferzeit?: number,
    loc?: {
        alias?: string,
        hm?: string,
        id?: number,
        image?: string,
        /** @deprecated Use latitude instead. */
        lat?: number,
        latitude?: number,
        /** @deprecated Use longitude instead. */
        lon?: number,
        longitude?: number,
        mail?: string,
        name?: string,
        openMo?: string,
        openDi?: string,
        openMi?: string,
        openDo?: string,
        openFr?: string,
        openSa?: string,
        openSo?: string,
        openServiceMo?: string,
        openServiceDi?: string,
        openServiceMi?: string,
        openServiceDo?: string,
        openServiceFr?: string,
        openServiceSa?: string,
        openServiceSo?: string,
        openWerkstattMo?: string,
        openWerkstattDi?: string,
        openWerkstattMi?: string,
        openWerkstattDo?: string,
        openWerkstattFr?: string,
        openWerkstattSa?: string,
        openWerkstattSo?: string,
        ort?: string,
        plz?: string,
        str?: string,
        tel?: string,
        tel_hpt?: string,
        url?: string,
    },
    mod_g?: string,
    mod_o?: string,
    modelkey?: string,
    modelkey6?: string,
    modell?: string,
    modelljahr?: Raw extends true ? number : Date,
    name?: string,
    nameSlug?: string,
    nfz?: boolean,
    polster?: string,
    preis?: {
        mwst?: number,
        mwst_satz?: number,
        transport?: number,
        upe?: number,
        vk?: number,
        vkn?: number,
    },
    scheckheft?: Raw extends true ? ('ja' | 'nein') : boolean,
    siegel?: Raw extends true ? [
        number?,
        string?,
    ] : number,
    simc?: Raw extends true ? ListCar[] : Record<Car<false>['id'], Car<false>>,
    sitze?: number,
    sonderangebot?: Raw extends true ? ('0' | '1' | '') : boolean,
    standort?: string,
    typ?: string,
    tueren?: number,
    tsn?: string,
    unfall?: boolean,
    user?: User['i'][],
    users?: User[],
    verfuegbarAb?: Raw extends true ? number : Date,
    vin?: string,
    vorbesitzer?: number,
} & (Raw extends true ? {
    wltp?: WltpData,
} : {
    usersSelect?: UserSelect[],
    verbrauch?: CarVerbrauchParsed,
    verbrauchNumbered?: CarVerbrauchParsedNumbered,
});

export type ListCar = {
    aktion?: '0' | '1' | '',
    bilder?: {
        anzahl?: number,
        ec?: boolean,
        url?: string[],
    },
    engine?: {
        effizienz?: string,
        hubraum?: number,
        kraftstoff?: string,
        kraftstoff_1?: string,
        leistung?: [
            number?,
            number?,
        ],
        motor?: string,
        verbrauch?: [
            number?,
            number?,
        ],
    },
    envkv?: boolean,
    ez?: string,
    farbe?: [
        string?,
        string?,
    ],
    finanzierung?: {
        abschlussgebühr?: number,
        anbieterbank?: string,
        anzahlung?: number,
        art_sollzinssatzes?: string,
        bruttokreditbetrag?: number,
        effektiver_jahreszins?: number,
        km?: string,
        ksb?: string,
        ksb_betrag?: string,
        laufzeit?: number,
        monatliche_rate?: number,
        nettrokreditbetrag?: string,
        schlussrate?: number,
        soll_zinssatz?: string,
    },
    garantie?: [
        number?,
        string?,
    ],
    getriebe?: string,
    hersteller?: string,
    id?: number,
    ident?: string,
    idFirma?: number,
    idGF?: number,
    idStandort?: number,
    km?: number,
    leasing?: {
        anzahlung?: string,
        DestinationCharges?: string,
        effektiver_jahreszins?: string,
        ExtraMileageCosts?: string,
        km?: string,
        laufzeit?: string,
        LowMileageCompensation?: string,
        monatliche_rate?: string,
        nettrokreditbetrag?: string,
        RegistrationFees?: string,
        soll_zinssatz?: string,
        TotalAmount?: string,
    },
    leasingComm?: {
        anzahlung?: string,
        DestinationCharges?: string,
        effektiver_jahreszins?: string,
        ExtraMileageCosts?: string,
        km?: string,
        laufzeit?: string,
        LowMileageCompensation?: string,
        monatliche_rate?: string,
        nettrokreditbetrag?: string,
        RegistrationFees?: string,
        soll_zinssatz?: string,
        TotalAmount?: string,
    },
    lieferzeit?: number,
    link?: string,
    loc?: {
        alias?: string,
        id?: number,
        image?: string,
        mail?: string
        name?: string,
        str?: string,
        ort?: string,
        plz?: string,
        tel?: string,
        url?: string,
    },
    mod_o?: string,
    mod_g?: string,
    modell?: string,
    mwst?: number,
    name?: string,
    nfz?: boolean,
    preis?: number,
    siegel?: [
        number?,
        string?,
    ],
    sonderangebot?: '0' | '1' | '',
    transport?: number,
    tueren?: number,
    typ?: string,
    upe?: number,
    verfuegbarAb?: number,
    vkn?: number,
    vorbesitzer?: number,
    wltp?: WltpData
};

export type NavigateCar = {
    hersteller?: Car<false>['hersteller'],
    id?: Car<false>['id'],
    loc?: Car<false>['loc'],
    modell?: Car<false>['modell'],
    name?: Car<false>['name'],
    nameSlug?: Car<false>['nameSlug'],
    typ?: Car<false>['typ'],
};

export type CarField<
    T,
    P1 extends keyof NonNullable<T> = keyof NonNullable<T>,
> = [P1?, any?, any?];

export type CarList = {
    facets?: FacetsService,
    searchResult?: {
        cars?: ListCar[],
        numFound?: number,
    }
};

export enum FilterSort {
    Hersteller = 'Hersteller',
    Rate = 'rate',
    Standzeit = 'stime',
    VerfugbarAb = 'VerfuegbarAb',
    Verkaufspreis = 'vk',
    Zufall = 'random',
}

export enum FilterOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type Filter = {
    aktion?: boolean,
    akttypList?: number[],
    aufbau?: number,
    aufbauten?: number[],
    EZ?: number[],
    EZVon?: number,
    EZBis?: number,
    Farben?: number[],
    freier_text?: string,
    fd?: number[],
    Getriebe?: number,
    hersteller0?: number,
    ia?: string[],
    idFirma?: number[],
    ignoreFirma?: number[],
    ignoreStandort?: number[],
    kategorie?: string[],
    KM?: number[],
    KMVon?: number,
    KMBis?: number,
    Leistung?: number[],
    LeistungVon?: number,
    LeistungBis?: number,
    modell0?: number,
    MotorG?: number,
    motoren?: number[],
    nfzHersteller?: number,
    NFZ?: boolean,
    order?: FilterOrder,
    page?: number,
    pageSize?: number,
    Preis?: number[],
    PreisVon?: number,
    PreisBis?: number,
    Rate?: number[],
    RateVon?: number,
    RateBis?: number,
    siegel?: number,
    sofort?: boolean,
    sort?: FilterSort,
    Standort?: number[],
    StandortQuelle?: number[],
    tags?: string,
};

export enum ViewOptionsView {
    Grid = 'grid',
    List = 'list',
}

export type ViewOptions = {
    advanced?: boolean,
    car?: [string, number] | undefined,
    view?: ViewOptionsView,
};

export type FacetService = {
    [key: string]: [number, number]
};

export type FacetServiceLocation = {
    alias: string,
    fax: string,
    hm: string,
    id: number,
    /** @deprecated */
    lat: number,
    latitude: number,
    /** @deprecated */
    lon: number,
    longitude: number,
    mail: string,
    name: string,
    ort: string,
    plz: string,
    str: string,
    tel: string,
    tel_hpt: string,
};

export type FacetsService = {
    MotorG?: FacetService,
    MotorGAll?: FacetService,
    aufbau?: FacetService,
    aufbauAll?: FacetService,
    aufbauten?: FacetService,
    bereich?: FacetService,
    bereichAll?: FacetService,
    ez?: FacetService,
    ezAll?: FacetService,
    farben?: FacetService,
    farbenAll?: FacetService,
    firmen?: FacetService,
    firmenAll?: FacetService,
    firmenData?: Record<number, Car<true>['loc']>,
    getriebe?: FacetService,
    getriebeAll?: FacetService,
    hersteller?: FacetService,
    herstellerAll?: FacetService,
    kategorie?: FacetService,
    km?: FacetService,
    kmAll?: FacetService,
    Leistung?: FacetService,
    LeistungAll?: FacetService,
    modell?: FacetService,
    modellAll?: FacetService,
    preis?: FacetService,
    preisAll?: FacetService,
    rate?: FacetService,
    rateAll?: FacetService,
    siegel?: FacetService,
    siegelAll?: FacetService,
    standort?: FacetService,
    standortAll?: FacetService,
    standortData?: Record<number, Car<true>['loc']>,
    tags?: FacetService,
    tagsAll?: FacetService,
    fd?: FacetService,
    fdAll?: FacetService,
};

export type Facet = (SelectOption & { sd?: Car<false>['loc'] & { resultCount?: number }, tSlug: string });

export type Facets = {
    aus?: Facet[],
    MotorG?: Facet[],
    MotorGAll?: Facet[],
    aufbau?: Facet[],
    aufbauAll?: Facet[],
    aufbauten?: Facet[],
    bereich?: Facet[],
    bereichAll?: Facet[],
    ez?: Facet[],
    ezAll?: Facet[],
    ezSelect?: Facet[],
    ezSelectAll?: Facet[],
    farben?: Facet[],
    farbenAll?: Facet[],
    firmen?: Facet[],
    firmenAll?: Facet[],
    getriebe?: Facet[],
    getriebeAll?: Facet[],
    hersteller?: Facet[],
    herstellerAll?: Facet[],
    kategorie?: Facet[],
    km?: Facet[],
    kmAll?: Facet[],
    kmSelect?: Facet[],
    kmSelectAll?: Facet[],
    Leistung?: Facet[],
    LeistungAll?: Facet[],
    leistungSelect?: Facet[],
    leistungSelectAll?: Facet[],
    modell?: Facet[],
    modellAll?: Facet[],
    preis?: Facet[],
    preisAll?: Facet[],
    preisSelect?: Facet[],
    preisSelectAll?: Facet[],
    rate?: Facet[],
    rateAll?: Facet[],
    rateSelect?: Facet[],
    rateSelectAll?: Facet[],
    rateAllFinanzierung?: Facet[],
    siegel?: Facet[],
    siegelAll?: Facet[],
    standort?: Facet[],
    standortAll?: Facet[],
    tags?: Facet[],
    tagsAll?: Facet[],
    fd?: Facet[],
    fdAll?: Facet[],
};
