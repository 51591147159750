/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configHoppmann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54612,
        enable: true,
        projectName: 'Hoppmann',
        searchPath: '/Fahrzeugsuche/Fahrzeugliste',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 3,
            offsetY: 16,
            position: 'left',
        },
        isLeadSystem: true,
        isCatchLeadSystem: true,
        linkDisclaimer: '/impressum/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus', 'tags'],
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1.5,
        fontFamily: '"PT Sans", Helvetica, Arial, sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    ['@media screen and (min-width: 1400px)']: {
                        'body': {
                            background: ' #dae5f1 !important',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff !important',
                            'textDecoration': 'none !important',
                            '&:hover': {
                                'color': '#fff !important',
                                'backgroundColor': '<colorSecondary> !important',
                                'textDecoration': 'none !important',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': '50%',
                            'color': '#fff !important',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                    multiline: {
                        [getStyleQuery()]: {
                            '&.Mui-disabled': {
                                backgroundColor: 'transparent !important',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="Schnellsuche_Container"] > a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeLarge.MuiButton-containedSizeLarge': {
                                color: '#fff !imporant',
                                fontSize: '19px !important',
                                textDecoration: 'none !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                },
            },
        }),
        noAnimations: true,
        primaryColor: '#301539',
        secondaryColor: '#e6205d',
    },
};

export default configHoppmann;
