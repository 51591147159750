/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configKaesmann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54622,
        enable: true,
        projectName: 'Käsmann',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        titleWithDesign: true,
        tradeInLink: '/inzahlungnahme',
    },
    style: {
        ...configDefault.style,
        fontSize: 16,
        fontFamily: '"vwtextweb-regular"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
        }),
        primaryColor: '#333',
    },
};

export default configKaesmann;
