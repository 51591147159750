/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configDippoldiswalde: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54130,
        enable: true,
        projectName: 'Autohaus Dippoldiswalde',
        metaTitleSuffix: 'Autohaus Dippoldiswalde',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'bklFy0z6jf',
            origin: 'autohaus-dippoldiswalde.de',
            priceRating: true,
            sourceName: 'Autohaus-dippoldiswalde.de',
            tradeIn: true,
        },
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        fontFamily: '"hyundaisanshead-light"',
        fontSize: 15,
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"hyundaisanshead-bold"',
                    fontWeight: 900,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#002c5f',
    },
};

export default configDippoldiswalde;
