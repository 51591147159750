import { InputAdornment, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps, formControlLabelClasses } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from '../../_types/Car';
import { checkUndefinedNullOrEmpty, getStyleQuery } from '../../utils/tools';
import Iconify from '../iconify/component';
import { IconifyProps } from '../iconify/types';

// ----------------------------------------------------------------------

export type RHFCheckboxProps = Omit<FormControlLabelProps, 'control'> & {
    helperText?: ReactNode;
    icon?: IconifyProps,
    name: string;
    outlined?: boolean;
    uncheckIsNull?: boolean,
};

export function RHFCheckbox({ disabled, helperText, icon, label, name, outlined, sx, uncheckIsNull }: RHFCheckboxProps) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                outlined
                    ? <TextField
                        disabled={disabled}
                        error={!!error}
                        fullWidth
                        helperText={error ? error?.message : helperText}
                        inputProps={{
                            sx: { [getStyleQuery()]: { cursor: disabled ? 'default' : 'pointer' } },
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <Checkbox checked={field.value} disableRipple sx={{ [getStyleQuery()]: { p: 0 } }} />
                            </InputAdornment>,
                            readOnly: true,
                            startAdornment: !checkUndefinedNullOrEmpty(icon) ? <InputAdornment position="start" sx={{ pr: 0.5 }}>
                                <Iconify icon={icon} />
                            </InputAdornment> : undefined,
                            sx: { [getStyleQuery()]: { cursor: disabled ? 'default' : 'pointer' } },
                        }}
                        InputLabelProps={{
                            shrink: false,
                            sx: { [getStyleQuery()]: { color: 'text.secondary', cursor: disabled ? 'default' : 'pointer', ml: !checkUndefinedNullOrEmpty(icon) ? 5 : undefined, top: 1 } },
                        }}
                        label={label}
                        onClick={() => field.onChange({
                            target: {
                                value: (
                                    uncheckIsNull === true && field?.value === true
                                        ? null
                                        : !field.value
                                ),
                            },
                        })}
                        sx={{ [getStyleQuery()]: { cursor: disabled ? 'default' : 'pointer' } }}
                        variant="outlined"
                    />
                    : <FormControl sx={sx}>
                        <FormControlLabel data-testid={`RHFCheckbox_${name}`} control={<Checkbox {...field} checked={field.value} />} label={label} />
                        {(!!error || helperText) && (
                            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
                        )}
                    </FormControl>
            )}
        />
    );
}

// ----------------------------------------------------------------------

export type RHFMultiCheckboxProps = Omit<FormControlLabelProps, 'control' | 'label'> & {
    helperText?: React.ReactNode;
    label?: string;
    name: string;
    options: SelectOption[];
    row?: boolean;
    spacing?: number;
};

export function RHFMultiCheckbox({
    helperText,
    label,
    name,
    options,
    row,
    spacing,
    sx,
    ...other
}: RHFMultiCheckboxProps) {
    const { control } = useFormContext();

    const getSelected = (selectedItems: (string | number)[], item: string | number) =>
        selectedItems?.includes(item)
            ? selectedItems?.filter((value) => value !== item)
            : [...(selectedItems || []), item];

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl component="fieldset">
                    {label && (
                        <FormLabel component="legend" sx={{ typography: 'body2' }}>
                            {label}
                        </FormLabel>
                    )}

                    <FormGroup
                        sx={{
                            ...(row && {
                                flexDirection: 'row',
                            }),
                            [`& .${formControlLabelClasses.root}`]: {
                                '&:not(:last-of-type)': {
                                    mb: spacing || 0,
                                },
                                ...(row && {
                                    'mr': 0,
                                    '&:not(:last-of-type)': {
                                        mr: spacing || 2,
                                    },
                                }),
                            },
                            ...sx,
                        }}
                    >
                        {options?.map((option) => (
                            <FormControlLabel
                                key={`RHFCheckbox_${name}_option_${option.i}`}
                                control={
                                    <Checkbox
                                        checked={field.value.includes(option.i)}
                                        onChange={() => field.onChange(getSelected(field.value, option.i))}
                                    />
                                }
                                label={option.t}
                                {...other}
                            />
                        ))}
                    </FormGroup>

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error} sx={{ mx: 0 }}>
                            {error ? error?.message : helperText}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}
