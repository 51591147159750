/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';
import configVoelkel from './54785';

const configVoelkel_Dev: ConfigObject = {
    ...configVoelkel,
    general: {
        ...configDefault.general,
        idFirma: 54785,
        enable: true,
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
}

export default configVoelkel_Dev;
