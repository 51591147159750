/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Daniel Rittrich
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configGrampp: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 51530,
        enable: true,
        projectName: 'GRAMPP',
        searchPath: '/fahrzeugsuche',
        showFinanzierungen: false,
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'standort', 'preis', 'ez', 'aus'],
        },
        titleWithDesign: true,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"acumin-pro-condensed", sans-serif',
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        'color': '#fff',
                        '&:hover': {
                            backgroundColor: '#e58f16',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        color: '#fff',
                    },
                },
            },
        }),
        primaryColor: '#ff9f19',
    },
};

export default configGrampp;
