/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configVogt: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54783,
        enable: true,
        metaTitleSuffix: 'Autohaus Vogt',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Vogt',
        searchPath: '/gebrauchtwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 10,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
        tradeInLink: 'https://inzahlungnahme.nissan.de/dealer/3188',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: 'arial-narrow',
        fontSize: 16,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                background: '#de0036',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontSize: '12px',
                            textTransform: 'uppercase',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'background': '#f5d9de',
                            'border': '1px solid #f5d9de',
                            'color': '#af132c',
                            'padding': '10px 20px',
                            '&:hover': {
                                backgroundColor: '#fff',
                                color: '#de0036',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '#f5d9de !important',
                            'border': '1px solid #f5d9de',
                            'color': '#af132c !important',
                            'padding': '10px 20px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '14px',
                                fontWeight: 'bold',
                                padding: '6px 8px',
                                textTransform: 'none',
                            },
                            '&:hover': {
                                backgroundColor: '#fff !important',
                                border: '1px solid <colorPrimary>',
                                color: '#de0036 !important',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            backgroundColor: '#fff !important',
                            border: '1px solid <colorPrimary>',
                            color: '<colorPrimary>',

                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            fontSize: 16,
                            fontWeight: 'bold',
                            textTransform: 'none',
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& label.MuiFormLabel-root.MuiInputLabel-root': {
                                background: '#fff',
                                color: '#637381',
                            },
                            '& input.MuiOutlinedInput-input': {
                                background: '#fff',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '#f5d9de',
                            'color': '#af132c',
                            '&:hover': {
                                backgroundColor: '#fff',
                                color: '#de0036',
                            },
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&#modell0, &#hersteller0, &#akttypList, &#aufbau, &[id$=":-label"], &#motoren, &#Getriebe, &#ia': {
                                color: '#fff',
                                background: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiGridRoot2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"]': {
                                color: '#25282b',
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            '&[data-testid="RHFTextField_freier_text"]': {
                                color: '#fff !important',
                            },
                            '&[data-testid="RHFTextField_freier_text"]::placeholder': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="FahrzeugSucheFilterChips_results"] > h6.MuiTypography-subtitle1': { // "Fahrzeuge gefunden"
                                color: '#fff',
                            },
                            '&[data-testid="FahrzeugSucheFilterChips_results"] button[data-testid="CarListSort_smallSortBtn"]': { // small sort button
                                color: '#fff',
                            },
                            '&[data-testid="FahrzeugSucheFilterSideBar_Container"], &[data-testid="FahrzeugSucheFilterTopArea_Container"]': { // filter bars all in white
                                '& div.MuiFormControl-root': {
                                    border: '1px solid #fff',
                                    color: '#fff',
                                },
                                '& div.Mui-focused': {
                                    border: 'none !important',
                                    outline: 'none !important',
                                },
                                '& div.MuiInputAdornment-positionStart': {
                                    color: '#fff',
                                },
                                '& svg.MuiSvgIcon-fontSizeSmall': {
                                    color: '#fff',
                                },
                                '& svg.MuiSelect-iconOutlined': {
                                    color: '#fff',
                                },
                                '& span.MuiSlider-root': {
                                    color: '#fff',
                                },
                                '& span.MuiSlider-markLabel': {
                                    color: '#fff',
                                },
                                '& input.MuiInputBase-inputAdornedStart': {
                                    background: 'transparent',
                                },
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#4f5459',
                },
            },
            body2: {
                [getStyleQuery()]: {
                    paddingTop: 0,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontSize: 14,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#fff',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#fff',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#25282b',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#25282b',
                    paddingTop: 0,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    paddingTop: 0,
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#c31631',
    },
};

export default configVogt;
