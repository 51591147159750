import { Modules } from './_json/modules';
import { FaarenListCar } from './_tools/types/faaren';
import type { Version } from './types';

export const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION as Version;
export const LOCALSERVER = process.env.REACT_APP_LOCALSERVER === 'true';
export const DEV = process.env.REACT_APP_DEV === 'true';
export const LIVE = process.env.REACT_APP_LIVE === 'true';
export const NEXT = process.env.REACT_APP_NEXT === 'true';
export const PRE_RELEASE = process.env.REACT_APP_PRE_RELEASE === 'true';
export const PORT = process.env.REACT_APP_PORT;
export const SERVLETPORT = process.env.REACT_APP_SERVLETPORT;
export const TEST = process.env.REACT_APP_TEST === 'true';

export const domainHpt = LOCALSERVER ? `https://localhost:${PORT}` : `https://hpt${DEV ? '-dev' : NEXT ? '-next' : PRE_RELEASE ? '-pre-release' : TEST ? '-test' : ''}.autohaus-digital.de`;
export const urlHpt = (version: Version) => `${domainHpt}/${version}`;
export const baseUrl = urlHpt(CURRENT_VERSION);

/** Necessary Cookie */
export const LOCAL_STORAGE_LANG = 'i18nextLng';

export type AppProps = {
    allFaarenVehicles: FaarenListCar[],
    module: Modules;
    useOldRouter: boolean;
};

export type MetaData = { canonical: string, desc: string, image: string, imageAlt: string, tags: string, title: string, titleRaw: string, url: string };

