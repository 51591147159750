/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configHenke: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54790,
        enable: true,
        projectName: 'Auto Henke',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
    },
    module: {
        ...configDefault.module,
        standorte: false,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
         filter: {
             sidebar: ['tags', 'sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
             top: ['[SM]tags', '[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
         },
         tradeInLink: '/ankauf',
     },
    style: {
        ...configDefault.style,
        fontFamily: 'vwtextweb-regular',
        fontSize: 16,
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                },
            },
        }),
        primaryColor: '#01305e',
        secondaryColor: '#666666',
    },
};

export default configHenke;
