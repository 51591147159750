import { Modules } from '../_json/modules';
import { Translations } from './translations';

const deDE: Translations = {
    buttons: {
        acceptMaps: 'Klicken Sie hier um der Nutzung von Google Maps zuzustimmen!',
        advancedSearch: 'Erweiterte Suche',
        back: 'Zurück',
        backToSearch: 'Zurück zur Suche',
        chooseVehicleHeading: 'Fahrzeug auswählen',
        clear: 'Leeren',
        close: 'Schließen',
        collapse: 'Zuklappen',
        expand: 'Ausklappen',
        filters: 'Filter',
        loadingView: 'Ansicht wird geladen...',
        loadMoreResults: 'Weitere Ergebnisse werden geladen...',
        loadMoreVehicles: 'Neue Fahrzeuge werden geladen...',
        nextPage: 'Nächste',
        no: 'Nein',
        prevPage: 'Vorherige',
        rateCalculator: 'Ratenrechner',
        resetFilter: 'Filter zurücksetzen',
        search: 'Suchen',
        showAllVehicles: 'Alle {0} Fahrzeuge anzeigen',
        vehicle: 'Fahrzeug',
        vehicles: 'Fahrzeuge',
    },
    car: {
        ez: 'Erstzulassung',
        km: 'Laufleistung',
        ident: 'Auftragsnummer',
        kategorie: 'Kategorie',
        typ: 'Fahrzeugtyp',
        types: {
            singular: {
                '-': '(unbekannt)',
                '1': 'Neuwagen',
                '2': 'Gebrauchtwagen',
                '4': 'Vorführwagen',
                '8': 'Mietwagen',
                '16': 'Sonderwagen',
                '32': 'Tageszulassung',
            },
            plural: {
                '-': '',
                '1': 'Neuwagen',
                '2': 'Gebrauchtwagen',
                '4': 'Vorführwagen',
                '8': 'Mietwagen',
                '16': 'Sonderwagen',
                '32': 'Tageszulassungen',
            },
        },
        sitze: 'Sitze',
        tueren: 'Türen',
        engine: {
            effizienz: 'Effizienz',
            hubraum: 'Hubraum',
            leistung: 'Leistung',
            motor: 'Motor',
            verbrauch: {
                '0': 'Verbrauch komb.',
                '1': 'CO2-Emission',
                '2ndDrive': '2. Antrieb',
                'combined': 'Kombiniert',
                'effizienz': 'Effizienz',
                'schadstoff': 'Euro-Norm',
                'heading': 'Verbrauch und Emission',
                'inner': 'Innerorts',
                'outer': 'Außerorts',
                'slow': 'Stadt',
                'medium': 'Stadtrand',
                'fast': 'Landstraße',
                'veryFast': 'Autobahn',
                'range': 'Reichweite',
                'city': 'Stadt',
                'protocol': 'Verbrauchsangaben',
                'protocolBy': 'Verbrauchsangaben nach {0}',
                'nefz': 'NEFZ',
                'wltp': 'WLTP',
                'noNEFZAvailable': 'NEFZ-Werte nicht verfügbar',
            },
        },
        garantie: 'Garantie',
        getriebe: 'Getriebe',
        siegel: 'Siegel',
        standort: 'Standort',
        farbe: 'Farbe',
        antrieb: 'Antrieb',
        verfuegbarkeit: 'Verfügbarkeit',
        aktion: 'Aktion',
        verfuegbarAb: 'Verfügbar ab',
        nowAvailable: 'sofort verfügbar',
        lieferzeit: 'Lieferzeit',
        preis: {
            inclMwst: 'inkl. {0} MwSt.',
            exclMwst: 'MwSt. nicht ausweisbar',
        },
        finanzierung: {
            fd: 'Finanzdienstleistung',
            title: 'Finanzierung',
            desc: 'Beim AutoCredit sind die monatlichen Raten durch eine erhöhte Schlussrate besonders niedrig. Am Vertragsende können Sie das Fahrzeug in vertragsgemäßem Zustand beim ausliefernden Händler zurückgeben, der dann die Schlussrate für Sie begleicht. Dieses Rückgaberecht wird von Ihrem Autohaus – weshalb es natürlich nur dort gelten kann – sogar ausdrücklich verbrieft. Alternativ können Sie die Schlussrate selbst begleichen oder weiterfinanzieren.',
            onRequest: 'auf Anfrage',
            laufzeit: 'Laufzeit',
            anzahlung: 'Anzahlung',
            rate: 'Rate',
            monate: 'Monate',
            monatlich: 'mtl.',
            effJahreszins: 'Eff. Jahreszins',
            sollzinssatz: 'Sollzinssatz',
            schlussrate: 'Schlussrate',
            ksb: 'KSB',
            ksb_betrag: 'KSB Betrag',
            laufleistung: 'Jährliche Laufleistung',
            nettokreditbetrag: 'Nettokreditbetrag',
            bruttokreditbetrag: 'Bruttokreditbetrag',
            inklTransfer: 'inkl. Überführungskosten',
            netto: 'netto',
            brutto: 'brutto',
            angebot: 'Ein Angebot der',
            noProducts: 'Keine Angebote für dieses Fahrzeug vorhanden',
            noOffer: 'Kein Angebot gefunden',
            angebotAnfragen: 'Angebot anfragen',
            individuellBerechnen: 'Jetzt individuell berechnen',
            bonitat: 'Bonität vorausgesetzt',
        },
        leasing: {
            title: 'Leasing',
            desc: 'Neues Auto ohne langfristige Bindung und finanzielles Risiko? - Kalkulierbare Leasingraten für Ihre Wunschleasingzeit von 12-48 Monaten.',
            onRequest: 'auf Anfrage',
            notAvailable: 'nicht verfügbar',
            typ: 'Art',
            commercial: 'geschäftl.',
            commercialDesc: 'Warum Kapital ins Auto stecken, das viel lukrativer in den Geschäftszweck investiert werden kann? Für gewerbliche Kunden, die niedrige Monatsraten wünschen und das Auto nicht erwerben, sondern nutzen möchten, ist das GeschäftsfahrzeugLeasing ein attraktives Angebot. Für einen festgelegten Zeitraum "mieten" Sie ein Fahrzeug Ihrer Wahl und zahlen dafür niedrige Leasingraten. Am Vertragsende geben Sie Ihr Auto nach vertragsgemäßer Nutzung bei Ihrem Händler einfach wieder ab und leasen ein neues.',
            private: 'privat',
            privateDesc: 'Für Kunden, die niedrige Monatsraten wünschen und das Auto nicht erwerben, sondern nutzen möchten, ist PrivatLeasing ein attraktives Angebot. Durch die niedrige monatliche Leasingrate bleibt finanzieller Spielraum für andere Wünsche. Am Vertragsende geben Sie Ihr Auto nach vertragsgemäßer Nutzung bei Ihrem Händler einfach wieder ab und leasen ein neues.',
            laufzeit: 'Laufzeit',
            monate: 'Monate',
            monatlich: 'mtl.',
            rate: 'Rate',
            effJahreszins: 'Eff. Jahreszins',
            sollzinssatz: 'Sollzinssatz',
            anzahlung: 'Sonderzahlung',
            laufleistung: 'Jährliche Laufleistung',
            zulassungskosten: 'Zulassungskosten',
            transferkosten: 'Überführungskosten',
            mehrkilometer: 'Mehrkilometer',
            minderkilometer: 'Minderkilometer',
            nettokreditbetrag: 'Nettodarlehenbetrag',
            bruttokreditbetrag: 'Gesamtbetrag',
            zzglTransfer: 'zzgl. Überführungskosten',
            lrv: 'LRV',
            netto: 'netto',
            brutto: 'brutto',
            angebot: 'Ein Angebot der',
            bonitat: 'Bonität vorausgesetzt',
        },
        aus: {
            categories: {
                kopf: '',
                highlights: 'Highlights',
                technik: 'Technik und Sicherheit',
                multimedia: 'Multimedia',
                assistenz: 'Assistenzsysteme',
                innen: 'Innenausstattung',
                sitze: 'Sitze und Polster',
                aussen: 'Außenausstattung',
                scheinwerfer: 'Scheinwerfer und Leuchten',
                raeder: 'Räder und Reifen',
                pakete: 'Pakete',
                sonstiges: 'Sonstiges',
                fussnoten: 'Fußnoten',
                fuss: '',
            },
            assistenz: {
                title: 'Assistenzsysteme',
                25: 'Tempomat (GRA)',
                26: 'Einparkhilfe (APS)',
                48: 'Parklenkassistent',
                332: 'Abstandstempomat',
                402: 'Rückfahrkamera',
                514: 'Spurwechselassistent',
                515: 'Spurhalteassistent',
                1145: 'Verkehrszeichen-Erkennung',
            },
            aussen: {
                title: 'Außenausstattung',
                34: 'Schiebedach',
                41: 'Xenon-Licht',
                71: 'Leichtmetallfelgen',
                88: 'Anhängerkupplung',
                238: 'Panorama- / Glasdach',
                1140: 'LED-Scheinwerfer',
            },
            innen: {
                title: 'Innenausstattung',
                22: 'Klimaautomatik',
                23: 'Standheizung',
                24: 'Navigationssystem',
                27: 'elektrische Fensterheber',
                29: 'Sitzheizung',
                36: 'elektrische Sitze',
                81: 'Lederausstattung',
                278: 'Sportsitze',
                1119: 'Bluetooth',
            },
            sonstiges: {
                title: 'Sonstiges',
                61: 'Allradantrieb',
            },
        },
    },
    carSearch: {
        foundCar: 'Fahrzeug gefunden',
        foundCars: 'Fahrzeuge gefunden',
        forFilters: 'für Filter',
        gridView: 'Raster',
        heading: 'Neu- und Gebrauchtwagen',
        heading2: 'Gebrauchtwagen',
        listView: 'Liste',
        page: 'Seite',
        sort: 'Sortierung',
        view: 'Ansicht',
    },
    carView: {
        accessoires: {
            contactPlusAccessoiresPrice: '(+ Zubehör)',
            special: 'Sonderausstattung',
            standard: 'Serienausstattung',
            title: 'Hier finden Sie Zubehör für Ihren {0} {1}',
            titleSelected: 'Ihr ausgewähltes Zubehör',
            withAccessoiresPrice: 'zzgl. {0} € Zubehör',
        },
        contactTab: {
            approach: 'Anfahrt',
            call: 'Anrufen',
            mail: 'E-Mail',
            message: 'Anfrage',
            phone: 'Telefon',
        },
        contact: {
            abort: 'Abbrechen',
            title: 'Kontakt',
            contactPerson: 'Ihr Ansprechpartner',
            copyTel: {
                error: 'Fehler',
                success: 'Kopiert',
            },
            fd: 'Ihre Finanzdienstleistung',
            requestType: 'Anfrageart',
            requestTypes: {
                '0': 'Anfrage senden',
                '0a': 'Anfrage senden an',
                '10': 'Termin anfragen',
                '10a': 'Termin anfragen bei',
                '12': 'Rückruf anfragen',
                '12a': 'Rückruf anfragen bei',
                '20': 'Probefahrt anfragen',
                '20a': 'Probefahrt anfragen bei',
                '27': 'Inzahlungnahme',
                '27a': 'Inzahlungnahme von',
                '0Short': 'Anfrage',
                '10Short': 'Termin',
                '12Short': 'Rückruf',
                '20Short': 'Probefahrt',
                '27Short': 'Inzahlungnahme',
            },
            name: 'Name',
            mail: 'E-Mail',
            phone: 'Telefon',
            date: 'Wunschtermin',
            message: 'Ihre Anfrage',
            disclaimer: 'Ich habe die {0} gelesen',
            disclaimerLink: 'Datenschutzbestimmungen',
            reset: 'Von vorne beginnen',
            send: 'Anfrage senden',
            sending: 'Sende...',
            errorSending: 'Fehler beim Senden der Anfrage',
            successSending: 'Anfrage erfolgreich gesendet',
            tryAgain: 'Bitte versuchen Sie es in einigen Minuten erneut',
            subjectPrefix: 'Fahrzeuganfrage',
            subjectPrefixAbo: 'AutoAbo-Anfrage',
            errors: {
                reCaptcha: 'ReCAPTCHA-Verifizierung fehlgeschlagen',
                requestType: 'Anfrageart fehlt',
                name: 'Name fehlt',
                mailMissing: 'E-Mail fehlt',
                mailInvalid: 'E-Mail in falschem Format',
                phone: 'Telefonnummer fehlt',
                date: 'Wunschtermin fehlt',
                message: 'Ihre Anfrage fehlt',
                acceptDisclaimer: 'Bitte Datenschutzbestimmung akzeptieren',
            },
        },
        finLeas: {
            errors: {
                downPayment: 'Anzahlung fehlt',
                duration: 'Laufzeit fehlt',
                mileage: 'Laufleistung fehlt',
                rsv: 'LRV fehlt',
            },
        },
        newTabs: {
            0: {
                title: 'Ausstattung',
                url: 'ausstattung',
            },
            1: {
                title: 'Beschreibung',
                url: 'beschreibung',
            },
            2: {
                title: 'Verbrauchsangaben',
                url: 'verbrauchsangaben',
            },
            3: {
                title: 'Standort',
                url: 'standort',
            },
        },
        calculator: {
            button: 'Jetzt anfragen für {0} / Monat',
            fields: {
                annualPercentageRate: 'Effektiver Jahreszins',
                carPrice: 'Fahrzeugpreis',
                damage_participation: 'Selbstbeteiligung',
                delivery_option: {
                    faaren_delivery: 'Lieferung durch Faaren',
                    no_delivery: 'Keine Lieferung',
                    partner_delivery: 'Lieferung durch Anbieter',
                    title: 'Lieferoptionen',
                },
                delivery_time_after_booking: 'Lieferzeit nach Bestellung',
                downPaymentFinancing: 'Anzahlung',
                downPaymentLeasing: 'Sonderzahlung',
                duration: 'Laufzeit',
                extraMileageCosts: 'Mehrkilometer',
                fixed_duration: 'monatlich wechselbar',
                lastRate: 'Schlussrate',
                leasePaymentInsurance: {
                    NONE: 'nein',
                    RSV: 'LRV',
                    RSVPLUS: 'LRV Plus',
                    title: 'Leasingratenversicherung',
                },
                lowMileageCompensation: 'Minderkilometer',
                mileage: {
                    autoAbo: 'Monatliche Laufleistung',
                    other: 'Jährliche Laufleistung',
                },
                min_license_duration: 'Mindestbesitzdauer Führerschein',
                minimum_age: 'Mindestalter',
                monthly: 'mtl.',
                monthlyRate: 'Monatliche Rate brutto',
                monthlyRateNet: 'Monatliche Rate netto',
                netLoan: 'Nettodarlehensbetrag',
                nominalInterestRate: 'Sollzins p.a.',
                paymentProtectionInsurance: {
                    NONE: 'nein',
                    RSV: 'KSB',
                    RSVPLUS: 'KSB Plus',
                    title: 'Kreditschutzbrief',
                },
                paymentProtectionInsuranceAmount: 'Betrag Kreditschutzbrief',
                pick_up_option: {
                    faaren_delivery: 'Abholung bei Faaren',
                    no_delivery: 'Keine Lieferung',
                    partner_delivery: 'Abholung bei Anbieter',
                    title: 'Abholoptionen',
                },
                privateLeasing: 'privat',
                businessLeasing: 'geschäftlich',
                transfer: 'Transportkosten',
                total: 'Gesamtbetrag',
                type: {
                    autoAbo: {
                        detail0: 'Alles inklusive bis auf Sprit',
                        detail1: 'Nach Ende der Mindestlaufzeit monatlich kündbar',
                        title: 'Auto-Abo',
                        prevText: 'mieten'
                    },
                    financing: {
                        detail0: 'Geringe monatliche Rate',
                        detail1: 'Schlussrate mit mehreren Abschlussoptionen',
                        title: 'Finanzierung',
                        prevText: 'finanzieren'
                    },
                    leasing: {
                        business: 'Geschäftsleasing',
                        detail0: 'Feste monatliche Rate',
                        detail1: 'Rückgabe des Fahrzeugs am Ende der Laufzeit',
                        private: 'Privatleasing',
                        title: 'Leasing',
                        prevText: 'leasen'
                    },
                    title: 'Typ',
                },
                upe: 'Fahrzeugneupreis',
            },
            title: {
                block: 'Einfach und flexibel finanzieren oder leasen',
                blockWithAbo: 'Einfach und flexibel finanzieren, leasen oder abonnieren',
                rateSettings: 'Deine Rateneinstellungen',
                summary: {
                    financing: 'Dein Finanzierungsangebot',
                    leasing: 'Dein Leasingangebot',
                    autoAbo: 'Dein Auto-Abo Angebot',
                },
                typeChooser: 'Wähle dein Gift',
            },
        },
        downloadExposee: 'Herunterladen',
        exposeeError: 'Bei der Generierung ist ein Fehler aufgetreten!\nBitte versuchen Sie es später erneut',
        exposeeLoading: 'Einen Moment bitte, das Exposé wird generiert',
        exposeeReady: 'Das Exposé steht nun zum Download bereit',
        locationUsers: 'Ihre Ansprechpartner',
        locationUsersContact: 'Kontakt aufnehmen',
        share: {
            copy: 'Link kopieren',
            mail: 'E-Mail',
            title: 'Teilen',
        },
        showExposee: 'Exposé',
        similarCars: 'Ähnliche Fahrzeuge',
        tabs: {
            0: 'Fahrzeugdaten',
            1: 'Beschreibung',
            2: 'Standort',
        },
        tradeIn: {
            heading: 'Inzahlungnahme?',
            content: 'Sie möchten Ihr aktuelles Fahrzeug in Zahlung geben und schnell einen unverbindlichen Marktwert erfahren?',
            button: 'Jetzt berechnen',
        },
        verbrauch: {
            combined: 'Kombiniert',
            combinedWeighted: 'Kombiniert (gewichtet)',
            combinedWeightedShort: 'Komb. gew.',
            co2class: 'CO2 Klasse',
            co2classWeighted: 'CO2 Klasse (gewichtet)',
            co2costs: 'Mögliche CO2-Kosten über die nächsten 10 Jahre (bei 15.000 km / Jahr):',
            co2costsLow: 'niedriger CO2-Preis',
            co2costsMedium: 'mittlerer CO2-Preis',
            co2costsHigh: 'hoher CO2-Preis',
            discharged: 'bei entladener\nBatterie',
            energycosts: 'Energiekosten pro Jahr (bei 15.000 km / Jahr)',
            emission: 'Emissionen',
            emissionWeighted: 'Emissionen (gewichtet)',
            emissionWeightedShort: 'Emiss. gew.',
            euronorm: 'Euro-Norm',
            extraHigh: 'Autobahn',
            header: 'Kraftstoffverbrauch',
            headerDischarged: 'Kraftstoffverbrauch bei entladener Batterie',
            headerElectro: 'Stromverbrauch',
            headerPlugIn: 'Stromverbrauch bei rein elektrischem Betrieb',
            high: 'Landstraße',
            medium: 'Stadtrand',
            motor: 'Motor',
            low: 'Stadt',
            range: 'Elektrische Reichweite',
            title: 'Energieverbrauch und Emission',
            weighted: 'gewichtet',
        },
    },
    common: {
        and: 'und',
        at: 'bei',
        brutto: 'brutto',
        in: 'in',
        netto: 'netto',
        notAvailable: 'nicht verfügbar',
    },
    errorHandler: {
        moduleDisabled: {
            heading: 'Entschuldigung, aber das Modul steht derzeit nicht zur Verfügung!',
            subtitle: 'Sind Sie der Betreiber? Dann wenden Sie sich bitte an den Administrator.',
        },
        noResults: {
            headingAktionsslider: 'Derzeit sind keine Aktionsfahrzeuge verfügbar!',
            heading: 'Derzeit sind keine Fahrzeuge für diese Suche verfügbar!',
            subtitle: 'Bitte versuchen Sie es erneut mit anderen Suchparametern.',
        },
        error: {
            heading: 'Entschuldigung, es ist ein Fehler aufgetreten!',
            subtitle: 'Bitte versuchen Sie es später erneut.',
            tooltip: 'Fehlerdetails anzeigen',
            actions: {
                exitError: {
                    tooltip: 'Fehler ausblenden',
                },
                goHome: {
                    tooltip: 'Zurück zur Startseite',
                },
                reloadPage: {
                    tooltip: 'Seite erneut laden',
                },
            },
        },
        sIntErrors: {
            'webcalc': {
                title: 'Fehler',
                details: 'Leider ist bei der Abfrage der Kalkulationsdaten ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!',
            },
            'unknown': {
                title: 'Unbekannter Fehler',
                details: 'Keine Fehler-Details vorhanden.',
            },
            'totalausfall': {
                title: 'Störung',
                details: 'Liebe Besucher, aktuell gibt es eine Störung bei der Fahrzeugsuche.\nBitte versuchen sie es zu einem späterem Zeitpunkt noch einmal.',
            },
            'networkError': {
                title: 'Netzwerkfehler',
                details: 'Ein Netzwerkfehler ist aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung!',
            },
            'runtimeError': {
                title: 'Laufzeitfehler',
                details: 'Ein Laufzeitfehler ist aufgetreten. Bitte versuchen Sie es später erneut!',
            },
            '0x400': {
                title: 'Bad request',
                details: '',
            },
            '0x401': {
                title: 'Unauthorized',
                details: '',
            },
            '0x403': {
                title: 'Forbidden',
                details: '',
            },
            '0x404': {
                title: 'Not Found',
                details: '',
            },
            '0x405': {
                title: 'Method Not Allowed',
                details: '',
            },
            '0x408': {
                title: 'Request Timeout',
                details: '',
            },
            '0x409': {
                title: 'Conflict',
                details: '',
            },
            '0x410': {
                title: 'Gone',
                details: '',
            },
            '0x414': {
                title: 'URI Too Long',
                details: '',
            },
            '0x415': {
                title: 'Unsupported Media Type',
                details: '',
            },
            '0x423': {
                title: 'Locked',
                details: '',
            },
            '0x425': {
                title: 'Too Early',
                details: '',
            },
            '0x429': {
                title: 'Too Many Requests',
                details: '',
            },
            '1x404': {
                title: 'Firmendaten nicht gefunden',
                details: '',
            },
            '1x500': {
                title: 'Fehler beim Abrufen der Firmendaten',
                details: '',
            },
            '2x404': {
                title: 'Keine Fahrzeuge gefunden',
                details: '',
            },
            '2x500': {
                title: 'Fehler beim Abrufen der Fahrzeugliste',
                details: '',
            },
            '3x204': {
                title: 'Fahrzeug nicht gefunden',
                details: '',
            },
            '3x404': {
                title: 'Fahrzeug nicht gefunden',
                details: '',
            },
            '3x500': {
                title: 'Fehler beim Abrufen der Fahrzeugdaten',
                details: '',
            },
            '3x501': {
                title: 'Fehler beim Abrufen der Fahrzeugdaten',
                details: '',
            },
            '4x000': {
                title: 'Das Modul konnte nicht gefunden werden',
                details: '',
            },
            '4x001': {
                title: 'Feld "idFirma" fehlt in der Konfiguration',
                details: '',
            },
            '4x002': {
                title: 'Die Kontakt-E-Mail-Adresse ist nicht konfiguriert.',
                details: 'Bitte informieren Sie den Kundensupport.',
            },
            '4x003': {
                title: 'Feld "contactLeadSystem" fehlt in der Konfiguration',
                details: '',
            },
            '4x004': {
                title: 'Feld "linkDisclaimer" fehlt in der Konfiguration',
                details: '',
            },
            '4x005': {
                title: 'HOMEPAGE-TOOL deaktiviert',
                details: '',
            },
            '4x006a': {
                title: 'Aktionsslider deaktiviert',
                details: '',
            },
            '4x006b': {
                title: 'Finanzierungsansicht deaktiviert',
                details: '',
            },
            '4x006c': {
                title: 'Schnellsuche deaktiviert',
                details: '',
            },
            '4x006d': {
                title: 'Fahrzeugsuche deaktiviert',
                details: '',
            },
            '4x006e': {
                title: 'Erweiterte Schnellsuche deaktiviert',
                details: '',
            },
            '4x006f': {
                title: 'Fahrzeugslider deaktiviert',
                details: '',
            },
            '4x006g': {
                title: 'Fahrzeugliste deaktiviert',
                details: '',
            },
            '4x006h': {
                title: 'Standorte deaktiviert',
                details: '',
            },
            '4x007': {
                title: 'Host fehlt in URL',
                details: '',
            },
            '0815x001': {
                title: 'Testfehler goBack',
                details: '',
            },
            '0815x002': {
                title: 'Testfehler reloadPage',
                details: '',
            },
            '0815x003': {
                title: 'Testfehler exitError',
                details: '',
            },
        },
    },
    filter: {
        aktion: 'Aktionsfahrzeuge',
        aktionActive: 'Nur Aktionsfahrzeuge',
        farbe: 'Farbe',
        hersteller: 'Hersteller',
        modell: 'Modell',
        typ: 'Fahrzeugtyp',
        stime: 'Standzeit',
        standort: 'Standort',
        standortMap: {
            buttonText: 'Kartenansicht',
            dialog: {
                filterButton: '{0} Fahrzeuge anzeigen',
                filterAllButton: 'Alle Fahrzeuge anzeigen',
                title: 'Fahrzeuge nach Standorten filtern',
                selectAll: 'Alle auswählen',
            },
        },
        aufbau: 'Aufbau',
        aufbauten: 'Aufbauten',
        siegel: 'Siegel',
        preis: 'Preis',
        preisVon: 'Preis von',
        preisBis: 'Preis bis',
        rate: 'Monatliche Rate',
        rateVon: 'Monatliche Rate von',
        rateBis: 'Monatliche Rate bis',
        ez: 'Erstzulassung',
        ezVon: 'Erstzulassung von',
        ezBis: 'Erstzulassung bis',
        kategorie: 'Kategorie',
        kategorieCARAVAN: 'Wohnwagen',
        kategorieKRAD: 'Motorrad',
        kategorieREISEMOBIL: 'Reisemobil',
        kategorieTRANSPORTER: 'Reisemobil',
        km: 'Laufleistung',
        kmVon: 'Laufleistung von',
        kmBis: 'Laufleistung bis',
        leistung: 'Leistung',
        leistungVon: 'Leistung von',
        leistungBis: 'Leistung bis',
        motor: 'Motor',
        getriebe: 'Getriebe',
        sofort: 'Sofort verfügbar',
        sofortActive: 'Nur sofort verfügbar',
        tags: 'Aktion',
        fd: 'Finanzdienstleistungen',
        aus: 'Ausstattung',
        ausHeading: 'Ausstattungen auswählen',
        volltext: 'Volltextsuche',
        availabileDate: 'Verfügbarkeitsdatum',
        asc: 'aufsteigend',
        desc: 'absteigend',
        Alle: 'Alle',
        alle: 'alle',
        from: 'ab',
        to: 'bis',
        moreThan: 'mehr als',
    },
    footerTextNEFZ: 'Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem "Leitfaden über den Kraftstoffverbrauch und die CO2-Emissionen neuer Personenkraftwagen" entnommen werden, der an allen Verkaufsstellen und bei der {0} unentgeltlich erhältlich ist.',
    footerTextWLTP: 'Die Informationen erfolgen gemäß der Pkw-Energieverbrauchskennzeichnungsverordnung. Die angegebenen Werte wurden nach dem vorgeschrieben Messverfahren WLTP (World Harmonised Light Vehicles Test Procedure) ermittelt. Der Kraftstoffverbrauch und der C02-Ausstoß eines PKW sind nicht nur von der effizienten Ausnutzung des Kraftstoffs durch den PKW, sondern auch vom Fahrstil und anderen nichttechnischen Faktoren abhängig. C02 ist das für die Erderwärmung hauptsächlich verantwortliche Treibgas. Ein Leitfaden über den Kraftstoffverbrauch und die C02-Emissionen aller in Deutschland angebotenen neuen PKW-Modelle ist unentgeltlich in elektronischer Form einsehbar an jedem Verkaufsort in Deutschland, an dem neue Personenkraftfahrzeuge ausgestellt oder angeboten werden. Der Leitfaden ist auch abrufbar unter der Internetadresse: {0}. Es werden nur die C02-Emissionen angegeben, die durch den Betrieb des PKW entstehen. C02-Emissionen, die durch die Produktion und Bereitstellung des PKW sowie des Kraftstoffes bzw. der Energieträger entstehen oder vermieden werden, werden bei der Ermittlung der C02-Emissionen gemäß WLTP nicht berücksichtigt.',
    menu: {
        activeConfig: 'Aktive Konfiguration',
        currentVersion: 'Aktuelle Version',
        dark: 'Dunkler Modus',
        disabled: 'Deaktiviert',
        heading: 'Hauptmenü',
        light: 'Heller Modus',
    },
    modules: {
        [Modules.Aktionsslider]: 'Aktionsslider',
        [Modules.ConfigListing]: 'Konfigurationen',
        [Modules.Contact]: 'Kontakt',
        [Modules.ErweiterteSchnellsuche]: 'Erweterte Schnellsuche',
        [Modules.Fahrzeugliste]: 'Fahrzeugliste',
        [Modules.Fahrzeugslider]: 'Fahrzeugslider',
        [Modules.Fahrzeugsuche]: 'Fahrzeugsuche',
        [Modules.Finanzierungsansicht]: 'Finanzierungsansicht',
        [Modules.Install]: 'Installationsdokumentation',
        [Modules.Menu]: 'Menü',
        [Modules.Print]: 'Exposé',
        [Modules.Schnellsuche]: 'Schnellsuche',
        [Modules.Standorte]: 'Standorte',
    },
    opening: {
        closed: 'geschlossen',
        openTill: 'offen bis {0} Uhr',
        openAtToday: 'geschlossen, öffnet um {0} Uhr',
        openAtTomorrow: 'geschlossen, öffnet morgen um {1} Uhr',
        openAtOtherDay: 'geschlossen, öffnet am {0} um {1} Uhr',
        verkauf: 'Verkauf',
        service: 'Service',
        werkstatt: 'Werkstatt',
    },
    time: {
        days: {
            0: 'Sonntag',
            1: 'Montag',
            2: 'Dienstag',
            3: 'Mittwoch',
            4: 'Donnerstag',
            5: 'Freitag',
            6: 'Samstag',
            singular: 'Tag',
            plural: 'Tage',
        },
        month: {
            plural: 'Monate',
            singular: 'Monat',
        },
        week: {
            plural: 'Wochen',
            singular: 'Woche',
        },
        year: {
            plural: 'Jahre',
            singular: 'Jahr',
        },
    },
    validation: {
        invalid: 'Fehlerhaft!',
        invalidEntry: 'Fehlerhafter Wert! Erlaubt: {0}',
        maxCarPrice: 'Der Preis liegt über dem Fahrzeugpreis',
        minPrice: 'Preis zu niedrig',
        required: 'Pflichtfeld',
        wholeNumber: 'Nur Ganzzahlen erlaubt',
    },
};

export default deDE;
