/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configMueller: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54100,
        enable: true,
        metaTitleSuffix: 'Auto-Müller GmbH, Volkswagen, Audi, Hüttenberg, Wetzlar, Gießen',
        metaTitleSeperator: '-',
        projectName: 'Auto-Müller',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", sans-serif',
        fontSize: 15,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    '&[data-testid="vehicleEinzelansicht"]': {
                        '> .MuiGrid-root > .MuiStack-root > .MuiStack-root > .MuiIconButton-root': {
                            'color': 'rgb(99, 115, 129)',
                            'backgroundColor': 'transparent',
                            'borderRadius': '50%',
                            '&:hover': {
                                backgroundColor: 'rgba(99, 115, 129, 0.08)',
                            },
                        },
                    },
                    '&[data-testid="RHFDateTimePicker_termin"]::placeholder': {
                        fontSize: '14px !important',
                    },
                    '&.MuiDivider-root': {
                        padding: '0px',
                    },
                    '[data-testid="CalcFd_Divider_1"]': {
                        marginTop: '40px !important',
                        marginBottom: '8px !important',
                    },
                    '[data-testid="MiniFooter_Divider_0"]': {
                        marginTop: '40px !important',
                        marginBottom: '40px !important',
                    },
                    '[id="usecontact_dialog_divider"]': {
                        borderBottom: 'dashed 1px',
                        borderColor: 'rgba(145, 158, 171, 0.2)',
                        backgroundColor: 'transparent',
                    },
                    '.carTileDivider': {
                        borderBottom: 'dashed 1px',
                        borderColor: 'rgba(145, 158, 171, 0.2)',
                        backgroundColor: 'transparent',
                    },
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '0.6rem !important',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': '0px',
                            'backgroundColor': 'transparent',
                            'boxShadow': 'none',
                            '> svg': {
                                width: '21px',
                                height: '21px',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontSize: '14px',
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'display': 'inline-flex',
                            '&.MuiIconButton-sizeMedium': {
                                'padding': '8px',
                            },
                            '&[id^="useContact_locationUserButton_"]': {
                                borderRadius: '0px',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'border': 'none',
                            'borderRadius': '0.6rem',
                            'color': '#fff',
                            'textShadow': 'none',
                            'textTransform': 'uppercase',
                            '&#detailview2_button_share': {
                                padding: '10px',
                            },
                            '&:hover': {
                                backgroundColor: '#353639',
                                boxShadow: 'none',
                                color: '#fff',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'border': '1px solid #a1a2a4',
                            'borderRadius': '0.6rem',
                            'color': '#46474b',
                            'textShadow': 'none',
                            'textTransform': 'uppercase',
                            '&:hover': {
                                backgroundColor: '#f0f0f1',
                                border: '1px solid <colorPrimary>',
                                color: '<colorPrimary>',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                borderColor: '#e5e8eb',
                                color: '#e5e8eb',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '0.6rem',
                            'textShadow': 'none',
                            'textTransform': 'uppercase',
                            '&#detailview2_button_exposee': {
                                padding: '10px',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'borderRadius': 0,
                            'color': '#212b36 !important',
                            'padding': '6px 8px',
                            'textShadow': 'none',
                            '&:hover': {
                                backgroundColor: '#f0f0f1',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'borderRadius': '50%',
                            'padding': '0px',
                            '& > svg': {
                                width: '21px',
                                height: '21px',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiGrid-container[data-testid="LocationInfo"] > .MuiGrid-root > button': {
                                'padding': '6px 8px',
                                'color': 'inherit',
                                'backgroundColor': 'transparent',
                                'textShadow': 'none',
                                '&:hover': {
                                    color: 'inherit',
                                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                                },
                            },
                            '&.MuiGrid-container[data-testid="LocationInfo_Tel"] > .MuiGrid-root > button': {
                                'padding': '6px 8px',
                                'color': 'inherit',
                                'backgroundColor': 'transparent',
                                'textShadow': 'none',
                                '&:hover': {
                                    color: 'inherit',
                                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                                },
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            '&[id="RHFTextfieldMessage"]': {
                                minHeight: '60px !important',
                            },
                            '&[data-testid="RHFTextField_freier_text"], &[data-testid="RHFTextField_freier_text"]::placeholder': {
                                fontSize: '15px !important',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper': {
                                '> ul > li': {
                                    display: 'flex',
                                },
                            },
                            '> div.MuiPickersLayout-root > div.MuiPickersLayout-contentWrapper > div > div.MuiDateCalendar-root > div.MuiPickersFadeTransitionGroup-root > div > div.MuiYearCalendar-root > div.MuiPickersYear-root > button.MuiPickersYear-yearButton': {
                                padding: '0px',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[aria-label="Verbrauch und Emission"] > button.MuiIconButton-sizeSmall': {
                                'backgroundColor': 'transparent',
                                'borderRadius': '50%',
                                'padding': '5px',
                                'margin': '-5px',
                                'color': 'rgb(99, 115, 129)',
                                '&:hover': {
                                    backgroundColor: 'rgba(99, 115, 129, 0.08)',
                                },
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'color': '#637381',
                            'textShadow': 'none',
                            '&[aria-selected="true"]': {
                                color: 'inherit',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontWeight: 600,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<primaryColor>',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<primaryColor>',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<primaryColor>',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<primaryColor>',
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#45464a',
    },
};

export default configMueller;
