/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configArnold: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53180,
        enable: true,
        projectName: 'VW-Arnold',
        searchPath: '/fahrzeugsuche/sofort-verfuegbare-lagerwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 36,
        },
        isLeadSystem: false,
        linkDisclaimer: '/webseite_neu/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['hersteller', 'modell', 'aufbau', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]motor', 'getriebe', 'aufbau', 'preis', 'rate', 'ez', 'aus'],
        },
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans"',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    containedPrimary: {
                        [getStyleQuery()]: {
                            'padding': '12px 15px',
                            'transition': 'all 0.15s ease-in-out',
                            '&:active, &:focus': {
                                color: '#fff !important',
                                outline: 'none !important',
                            },
                            '&:hover': {
                                transform: null,
                                backgroundColor: '#316191',
                                boxShadow: '0 0 10px 0 ' + '<colorPrimary>' + ' inset, 0 0 5px 2px #aaa',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'padding': '12px 15px',
                            'transition': 'all 0.15s ease-in-out',
                            '&:hover': {
                                transform: null,
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'padding': '12px 15px',
                            'transition': 'all 0.15s ease-in-out',
                            '&:hover': {
                                transform: null,
                            },
                        },
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& div.MuiStack-root[data-testid="CarPriceGrid_Container"] p.MuiTypography-caption': {
                                fontSize: '12px !important',
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderBottom: '1px dashed rgba(145, 158, 171, 0.9) !important',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        [getStyleQuery()]: {
                            boxShadow: 'none',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationUserCard"]': {
                                marginTop: '20px',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontSize: ['1em', '!important'],
                    fontWeight: 400,
                    textTransform: 'none',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontSize: '26px !important',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontSize: '22px !important',
                },
            },
        }),
        primaryColor: '#1a4570',
    },
};

export default configArnold;
