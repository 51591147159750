/**
 * @file Handles all configurations for the homepage tools.
 * @author Bastian Reichelt
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configNeumann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 48801,
        enable: true,
        projectName: 'Autohaus G. Neumann',
        searchPath: '/gebrauchtwagen/gebrauchtwagensuche.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: 'Roboto, Arial, sans-serif',
        fontSize: 15,
        primaryColor: '#97D706',
        secondaryColor: '#F54D03',
    },
};

export default configNeumann;
