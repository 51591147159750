/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { FilterOrder, FilterSort } from '../../_types/Car';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configAvemo: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30179,
        isGruppe: true,
        enable: true,
        projectName: 'AVEMO',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    api: {
        ...configDefault.api,
        tracking: {
            ...configDefault.api.tracking,
            customScripts: {
                ...configDefault.api.tracking.customScripts,
                commonSuccess: true,
                formCommonSuccess: 'gtag(\'event\', \'form\', {\'event_category\':\'engagement\',\'value\':1});',
            }
        },
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.ASC, sort: FilterSort.Standzeit },
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'km', 'motor', 'leistung', 'getriebe', 'aus', 'tags'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'leistung', 'getriebe', 'standort', 'preis', 'rate', 'ez', 'km', 'aus', 'tags'],
        },
        titleWithDesign: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 4,
        fontFamily: '"AVEMO", sans-serif',
        fontSize: 16,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div[data-testid="BilderSlider_LargeImgContainer"] button.MuiIconButton-root': {
                        color: '#fff',
                    },
                    '[data-testid="VehicleEinzelansicht2Toolbar_Label_NowAvailable"]': {
                        fontFamily: '<fontFamily>',
                    },
                    '[data-testid="CalcFd_Divider_1"]': {
                        marginTop: '40px !important',
                        marginBottom: '8px !important',
                    },
                    '[data-testid="MiniFooter_Divider_0"]': {
                        marginTop: '40px !important',
                        marginBottom: '40px !important',
                    },
                    '#sortButton': {
                        '&.MuiButton-contained': {
                            backgroundColor: '<colorSecondary> !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderRadius: 0,
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            lineHeight: '21px !important',
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'fontFamily': '<fontFamily>',
                            '&.MuiButton-sizeMedium': {
                                '&:not([id="searchButtonVolltext"]):not([id="gridviewButton"]):not([id="listviewButton"]):not([id="sortButton"]):not([data-testid^="UseContact_ContactTextButton_"]):not([id="detailview2_button_exposee"]):not([id="detailview2_button_share"])': {
                                    borderRadius: 30,
                                },
                            },
                            '&[data-testid="useContact_calculator_button"]': {
                                borderRadius: 30,
                            },
                            '&.MuiIconButton-root': {
                                'boxShadow': 'none',
                                'color': '#637381',
                                '&:hover': {
                                    color: '#637381',
                                },
                            },
                            '&.MuiTab-root': {
                                'backgroundColor': 'transparent',
                                'color': '#637381',
                                'boxShadow': 'none',
                                '&[aria-selected="true"]': {
                                    color: 'inherit',
                                },
                            },
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'borderRadius': 16,
                                'color': '#000',
                                'backgroundColor': '#d8d8d8',
                                '&:hover': {
                                    color: '#fff',
                                },
                                '&:focus': {
                                    color: '#fff',
                                },
                            },
                            '&[data-testid^="UseContact_ContactButton_"]': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'color': '#fff',
                                'backgroundColor': '#000',
                                'borderRadius': '50%',
                                'boxShadow': '0px 8px 16px 0px rgba(0, 0, 0, 0.24)',
                                'width': '56px',
                                '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '<colorSecondary>',
                                },
                                '&:focus': {
                                    backgroundColor: '<colorSecondary>',
                                },
                            },
                            '&[data-testid="UseContact_Dialog_Close"]': {
                                'display': 'inline-flex',
                                'border': '1px solid rgba(145, 158, 171, 0.32)',
                                'borderColor': '#919eab',
                                'color': '#000',
                                'borderRadius': 16,
                                'boxShadow': 'none',
                                '&:hover': {
                                    border: '1px solid rgb(0, 0, 0)',
                                    backgroundColor: 'rgba(240, 120, 0, 0.08)',
                                    borderColor: '<colorSecondary>',
                                },
                                '&:focus': {
                                    borderColor: '<colorSecondary>',
                                },
                            },
                            '&#detailview2_dialog-without-car_button_close': {
                                'display': 'inline-flex',
                                'border': '1px solid rgba(145, 158, 171, 0.32)',
                                'borderColor': '#919eab',
                                'color': '#000',
                                'borderRadius': 16,
                                'boxShadow': 'none',
                                '&:hover': {
                                    border: '1px solid rgb(0, 0, 0)',
                                    backgroundColor: 'rgba(240, 120, 0, 0.08)',
                                    borderColor: '<colorSecondary>',
                                },
                                '&:focus': {
                                    borderColor: '<colorSecondary>',
                                },
                            },
                            '&[data-testid="UseContact_Dialog_Submit"]': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'color': '#fff',
                                'backgroundColor': '#000',
                                'borderRadius': 16,
                                'boxShadow': 'none',
                                '&:hover': {
                                    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.24)',
                                },
                            },
                            '&[aria-selected="true"]': {
                                outlineWidth: '0px',
                            },
                            '&:focus': {
                                outlineWidth: '0px',
                            },
                            '&.MuiPickersDay-root.MuiPickersDay-dayWithMargin': {
                                'display': 'inline-flex',
                                'borderRadius': '50%',
                                'border': '0px',
                                'color': '#637381',
                                'boxShadow': 'none',
                                'width': '36px !important',
                                'height': '36px !important',
                                '&.Mui-selected': {
                                    color: '#fff',
                                },
                                '&:hover': {
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                    '&[aria-selected="true"]': {
                                        'backgroundColor': '#000',
                                        '&:hover': {
                                            backgroundColor: '<colorSecondary> !important',
                                            color: '#fff',
                                        },
                                        '&:focus': {
                                            backgroundColor: '<colorSecondary> !important',
                                            color: '#fff',
                                        },
                                    },
                                },
                                '&:focus': {
                                    'outline': 'none',
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                },
                                '&[aria-current="date"]': {
                                    'backgroundColor': '#fff',
                                    'color': '#637381',
                                    'border': '1px solid rgb(99, 115, 129)',
                                    '&:hover': {
                                        'backgroundColor': '<colorSecondary> !important',
                                        'color': '#fff',
                                    },
                                    '&:focus': {
                                        'backgroundColor': '<colorSecondary> !important',
                                        'color': '#fff',
                                    },
                                },
                                '&[aria-selected="true"]': {
                                    'backgroundColor': '#000',
                                    'color': '#fff',
                                    '&:hover': {
                                        'backgroundColor': '#000',
                                        'color': '#fff',
                                    },
                                },
                            },
                            '&.MuiPickersCalendarHeader-switchViewButton': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'borderRadius': '50%',
                                'padding': '8px',
                                '&:hover': {
                                    backgroundColor: 'rgba(33, 43, 54, 0.08)',
                                },
                                '&:focus': {
                                    'backgroundColor': 'unset',
                                },
                            },
                            '&.MuiPickersArrowSwitcher-button': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'borderRadius': '50%',
                                'padding': '8px',
                                '&:hover': {
                                    backgroundColor: 'rgba(33, 43, 54, 0.08)',
                                },
                                '&:focus': {
                                    'backgroundColor': 'unset',
                                },
                            },
                            '&#detailview2_button_exposee': {
                                '&:hover': {
                                    color: '#fff !important',
                                    backgroundColor: '<colorSecondary> !important',
                                },
                                '&:focus': {
                                    color: '#fff !important',
                                    backgroundColor: '<colorSecondary> !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                            },
                            '&:focus': {
                                backgroundColor: '<colorSecondary> !important',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            '&:hover': {
                                borderColor: '<colorSecondary> !important',
                                color: '<colorSecondary> !important',
                            },
                            '&:focus': {
                                borderColor: '<colorSecondary> !important',
                                color: '<colorSecondary> !important',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                borderColor: 'rgba(145, 158, 171, 0.24)',
                                color: 'rgba(145, 158, 171, 0.8)',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'border': 'none',
                            '&:hover': {
                                background: '<colorSecondary>',
                                color: '#fff',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'color': '<colorPrimary>',
                            '&:hover': {
                                background: '#fef4eb',
                                color: '<colorSecondary>',
                            },
                        },
                    },
                    textError: {
                        [getStyleQuery()]: {
                            'color': '#ff5630',
                            '&:hover': {
                                color: '#ff5630',
                                background: '#fff2ee',
                            },
                        },
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiAvatar-rounded': {
                                borderRadius: 16,
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& + .MuiBox-root': { // anti-transparency box around contact buttons
                                'display': 'inline-flex',
                                'border': '0px',
                                'color': '#000',
                                'backgroundColor': '#d8d8d8',
                                '&:hover': {
                                    color: '#fff',
                                },
                                '&:focus': {
                                    color: '#fff',
                                },
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'fontFamily': '<fontFamily>',
                            '& > .MuiCardHeader-root > .MuiCardHeader-content > .MuiTypography-root.MuiTypography-h6.MuiCardHeader-title': {
                                color: 'inherit',
                            },
                            '> div.MuiPickersLayout-root > div.MuiPickersLayout-contentWrapper > div > div.MuiDateCalendar-root > div.MuiPickersFadeTransitionGroup-root > div > div.MuiYearCalendar-root > div.MuiPickersYear-root > button.MuiPickersYear-yearButton': {
                                'fontFamily': '<fontFamily>',
                                'color': '#212b36',
                                'boxShadow': 'none',
                                'display': 'inline-flex',
                                'border': '0px',
                                'borderRadius': 16,
                                'outline': 'none',
                                '&.Mui-selected': {
                                    color: '#fff',
                                },
                                '&:hover': {
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                    '&[aria-checked="true"]': {
                                        backgroundColor: '#000',
                                    },
                                },
                                '&[aria-current="date"]': {
                                    'border': '1px solid rgb(99, 115, 129)',
                                    '&:hover': {
                                        'color': '#fff',
                                        'backgroundColor': '<colorSecondary> !important',
                                    },
                                    '&:focus': {
                                        'color': '#fff',
                                        'backgroundColor': '<colorSecondary> !important',
                                    },
                                },
                                '&[aria-checked="true"]': {
                                    backgroundColor: '#000',
                                },
                                '&:focus': {
                                    'outline': 'none',
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > .MuiTypography-root.MuiTypography-h5': {
                                color: 'inherit',
                            },
                            '& > .MuiTypography-root.MuiTypography-h6': {
                                color: 'inherit',
                            },
                            '&[data-testid="CalcFdTypeOptions_CalcFd"]': {
                                '& > .MuiPaper-root > .MuiStack-root': {
                                    lineHeight: 'normal',
                                },
                            },
                            '& > .MuiBox-root > .MuiBox-root': {
                                fontFamily: '<fontFamily>',
                            },
                        },
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'fontFamily': '<fontFamily>',
                            '& > button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedInherit.MuiButton-colorInherit.MuiButton-disableElevation': {
                                '&:hover': {
                                    color: 'inherit',
                                },
                            },
                            '& > button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textInherit.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorInherit.MuiButton-disableElevation': {
                                'outline': 'none',
                                '&:hover': {
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                },
                                '&:focus': {
                                    'color': '#fff',
                                    'backgroundColor': '<colorSecondary> !important',
                                },
                            },
                            '&.MuiPickersLayout-actionBar > button': {
                                border: '0px',
                            },
                            '& button.MuiButton-soft[data-testid="UseContact_Dialog_Close"]': {
                                'border': '1px solid transparent',
                                'color': '<colorPrimary>',
                                '&:hover': {
                                    background: '<colorSecondary>',
                                    color: '#fff',
                                },
                            },
                        },
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'fontFamily': '<fontFamily>',
                            '& > .MuiStack-root > .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded > .MuiStack-root': {
                                lineHeight: 'normal',
                            },
                            '& > .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium ': {
                                'display': 'inline-flex',
                                'border': '0px',
                                'borderRadius': '50%',
                                'color': '#637381',
                                'padding': '8px',
                                '&:focus': {
                                    'backgroundColor': 'unset',
                                },
                                '&:hover': {
                                    'color': '#637381',
                                    'backgroundColor': 'rgba(33, 43, 54, 0.08)',
                                },
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiGrid-container[data-testid="LocationInfo"] > .MuiGrid-root > button': {
                                'color': 'inherit',
                                '&:hover': {
                                    backgroundColor: 'rgba(240, 120, 0, 0.08) !important',
                                    color: '<colorSecondary> !important',
                                },
                                '&:focus': {
                                    backgroundColor: 'rgba(240, 120, 0, 0.08) !important',
                                    color: '<colorSecondary> !important',
                                },
                            },
                            '&.MuiGrid-container[data-testid="LocationInfo_Tel"] > .MuiGrid-root > button': {
                                'color': 'inherit',
                                '&:hover': {
                                    backgroundColor: 'rgba(240, 120, 0, 0.08) !important',
                                    color: '<colorSecondary> !important',
                                },
                                '&:focus': {
                                    backgroundColor: 'rgba(240, 120, 0, 0.08) !important',
                                    color: '<colorSecondary> !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                'backgroundColor': '<colorSecondary> !important',
                                'color': '#fff !important',
                                '> .MuiCheckbox-root > .MuiSvgIcon-root': {
                                    color: '#fff !important',
                                },
                            },
                            '&:focus': {
                                'backgroundColor': '<colorSecondary> !important',
                                'color': '#fff !important',
                                '> .MuiCheckbox-root > .MuiSvgIcon-root': {
                                    color: '#fff !important',
                                },
                            },
                            '&.Mui-selected': {
                                '&:hover': {
                                    backgroundColor: '<colorSecondary> !important',
                                    color: '#fff !important',
                                },
                                '> .MuiCheckbox-root > .MuiSvgIcon-root': {
                                    color: '#fff !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorSecondary> !important',
                            },
                            '&:focus': {
                                color: '<colorSecondary> !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<textPrimary>',
                    fontWeight: 500,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<textPrimary>',
                    fontWeight: 500,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<textPrimary>',
                    fontWeight: 500,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<textPrimary>',
                    fontWeight: 500,
                },
            },
        }),
        primaryColor: '#000',
        secondaryColor: '#f07800',
    },
};

export default configAvemo;
