import { TFunction } from 'i18next';

export type Ausstattung = {
    i: string,
    t: string,
};

type AusstattungObject = {
    [key: ('innen' | 'aussen' | 'assistenz' | 'sonstiges' | string)]: Ausstattung[];
};


function sAttr(translate: TFunction): AusstattungObject {
    return {
        innen: [
            { i: '36', t: translate('common:car.aus.innen.36') },
            { i: '27', t: translate('common:car.aus.innen.27') },
            { i: '1119', t: translate('common:car.aus.innen.1119') },

            { i: '278', t: translate('common:car.aus.innen.278') },
            { i: '22', t: translate('common:car.aus.innen.22') },
            { i: '81', t: translate('common:car.aus.innen.81') },

            { i: '29', t: translate('common:car.aus.innen.29') },
            { i: '23', t: translate('common:car.aus.innen.23') },
            { i: '24', t: translate('common:car.aus.innen.24') },
        ],
        aussen: [
            { i: '1140', t: translate('common:car.aus.aussen.1140') },
            { i: '88', t: translate('common:car.aus.aussen.88') },
            { i: '238', t: translate('common:car.aus.aussen.238') },

            { i: '41', t: translate('common:car.aus.aussen.41') },
            { i: '71', t: translate('common:car.aus.aussen.71') },
            { i: '34', t: translate('common:car.aus.aussen.34') },
        ],
        assistenz: [
            { i: '332', t: translate('common:car.aus.assistenz.332') },
            { i: '26', t: translate('common:car.aus.assistenz.26') },
            { i: '514', t: translate('common:car.aus.assistenz.514') },

            { i: '25', t: translate('common:car.aus.assistenz.25') },
            { i: '402', t: translate('common:car.aus.assistenz.402') },
            { i: '1145', t: translate('common:car.aus.assistenz.1145') },

            { i: '515', t: translate('common:car.aus.assistenz.515') },
            { i: '48', t: translate('common:car.aus.assistenz.48') },
        ],
        sonstiges: [
            { i: '61', t: translate('common:car.aus.sonstiges.61') },
        ],
    };
}

export default sAttr;
