/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configVoelkel: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54785,
        enable: true,
        projectName: 'Autohaus Völkel',
        searchPath: '/gebrauchtwagen.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 9,
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Montserrat", normal',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.MuiStack-root[data-testid="Schnellsuche_Container"]': {
                        '& label.MuiFormLabel-root, & span.MuiChip-label' :{
                            textShadow: 'none',
                        },
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'div[data-testid="CarDetailsGrid_ContainerGridVerbrauch"]': {
                            gridTemplateColumns: '1fr !important',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#151563',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            color: '#fff !important',
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            background: 'transparent !important',
                            borderBottom: '1px dashed rgba(145, 158, 171, 0.2) !important',
                            borderTop: 'none !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                },
            },
        }),
        primaryColor: '#18186f',
    },
};

export default configVoelkel;
