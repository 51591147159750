/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configBlockAmRing: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30167,
        enable: true,
        isGruppe: true,
        projectName: 'Block am Ring',
        searchPath: '/automobile',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'ez', 'aus'],
        },
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"bmwtypewebli_all", "Open Sans", "Arial", sans-serif',
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"bmwtypewebbo_all", ' + '<fontFamily>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"bmwtypewebbo_all", ' + '<fontFamily>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"bmwtypewebbo_all", ' + '<fontFamily>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"bmwtypewebbo_all", ' + '<fontFamily>',
                },
            },
        }),
        primaryColor: '#3a93d8',
    },
};

export default configBlockAmRing;
