/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configBaumann: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54320,
        enable: true,
        projectName: 'Automobile Baumann',
        searchPath: '/unsere-fahrzeuge',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'SWzi4PCQ3Q',
            origin: 'autohaus-baumann.de',
            priceRating: true,
            sourceName: 'Autohaus-baumann.de',
            tradeIn: true,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['hersteller', 'modell', 'aufbau', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]motor', '[LG]getriebe', 'aufbau', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        idExposee: 1608,
        titleWithDesign: false,
        topFilter: true,
        useEyecatcher: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: 'Lato, sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div[data-testid="fahrzeugsuche"] div.au-widget-car-rating': { // AutoUncle badge in Fahrzeugsuche
                        bottom: '48px',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'border': '2px solid <colorPrimary>',
                            'color': '#fff',
                            'fontSize': '16px',
                            'textTransform': 'uppercase',
                            '&:hover': {
                                boxShadow: 'inset 0 0 0 100px rgba(0,0,0,.2)',
                                color: '#fff',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'border': '2px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            'fontSize': '16px',
                            'textTransform': 'uppercase',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '12px',
                            },
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderBottom: '1px dashed',
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                                color: '#212b36',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#777777',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
            // h3: {
            //     [getStyleQuery()]: {
            //         fontFamily: '"Roboto", sans-serif',
            //         fontWeight: 300,
            //     },
            // },
            // h4: {
            //     [getStyleQuery()]: {
            //         fontFamily: '"Roboto", sans-serif',
            //         fontWeight: 300,
            //     },
            // },
            // h5: {
            //     [getStyleQuery()]: {
            //         fontFamily: '"Roboto", sans-serif',
            //         fontWeight: 300,
            //     },
            // },
            // h6: {
            //     [getStyleQuery()]: {
            //         fontFamily: '"Roboto", sans-serif',
            //         fontWeight: 300,
            //     },
            // },
        }),
        noAnimations: true,
        primaryColor: '#446084',
    },
};

export default configBaumann;
