/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configWittenberg: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 11980,
        enable: true,
        projectName: 'Autohaus Wittenberg',
        searchPath: '/fahrzeug-markt.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 6,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji" ,"Segoe UI Emoji" ,"Segoe UI Symbol" , sans-serif',
        primaryColor: '#00b1e6',
    },
};

export default configWittenberg;
