import type { Version } from '../../types';
import { CURRENT_VERSION, urlHpt } from '../../vars';
import checkUndefinedOrNull from './checkUndefinedOrNull';

export default function initHptScript(version: Version): string | true {
    if (typeof document !== 'undefined') {
        const ahdVersionScript = document.querySelector<HTMLScriptElement>('[id^="ahdVersionScript"]');

        if (checkUndefinedOrNull(ahdVersionScript)) {
            return 'Can\'t find ahdVersionScript';
        } else if (ahdVersionScript?.src?.includes(`/${CURRENT_VERSION}/`)) {
            return 'ahdVersionScript includes CURRENT_VERSION';
        }

        const ahdVersionScriptOriginalId = ahdVersionScript?.id;
        const ahdScript = document.getElementById('ahdScript') as HTMLScriptElement;

        if (!checkUndefinedOrNull(ahdVersionScript)) {
            ahdVersionScript.remove();
        }

        if (!checkUndefinedOrNull(ahdScript)) {
            ahdScript.remove();
        }

        document.body.append(
            Object.assign(document.createElement('script'), {
                async: true,
                defer: true,
                id: ahdVersionScriptOriginalId,
                src: `${urlHpt(version)}/static/js/version.js?ts=${new Date().valueOf()}`,
            })
        );

        return true;
    }

    return 'Document is not defined!';
}
