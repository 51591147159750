import { parse } from 'date-fns';
import { TFunction } from 'i18next';
import axios, { CustomAxiosPromise, urlSRelease } from '.';
import { ConfigObject, Lang, Language } from '../../_configs/types';
import { Modules } from '../../_json/modules';
import { Car, CarList, CarVerbrauchParsed, CarVerbrauchParsedNumbered, Facet, FacetService, Facets, FacetsService, Filter, NavigateCar, type CarAusstattungKey } from '../../_types/Car';
import { effizienzSteps } from '../../components/effizienz/steps';
import { EffizienzClass } from '../../components/effizienz/types';
import { arrAus } from '../../modules/fahrzeugsuche/filter/filterList';
import { parseDate } from '../../utils/date';
import { stringToSlug } from '../../utils/string-to-slug';
import { checkUndefinedNullOrEmpty, checkUndefinedOrNull } from '../../utils/tools';
import { FaarenListCar } from '../types/faaren';
import { getFaarenPriceList } from './faaren';

const getValue = (field: number, fractionDigits: number, prefix: string): string => {
    return !checkUndefinedNullOrEmpty(field)
        ? `${!checkUndefinedNullOrEmpty(prefix) ? `${prefix} ` : ''}${field?.toLocaleString('de-DE', { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })}`
        : '-';
};

const ausCategoryMap: Record<string, CarAusstattungKey> = {
    // DE
    'Assistenzsysteme': 'assistenz',
    'Aussen': 'aussen',
    'Fußnoten': 'fussnoten',
    'Highlights': 'highlights',
    'Innen': 'innen',
    'Multimedia': 'multimedia',
    'Pakete': 'pakete',
    'Räder + Reifen': 'raeder',
    'Scheinwerfer + Leuchten': 'scheinwerfer',
    'Sitze + Polster': 'sitze',
    'Sonstiges': 'sonstiges',
    'Technik + Sicherheit': 'technik',

    // EN
    'assistance systems': 'assistenz',
    'Outside': 'aussen',
    'Foot notes': 'fussnoten',
    'highlights': 'highlights',
    'Inside': 'innen',
    'multimedia': 'multimedia',
    'Packages': 'pakete',
    'wheels + tires': 'raeder',
    'headlights + lights': 'scheinwerfer',
    'Seats + upholstery': 'sitze',
    'Miscellaneous': 'sonstiges',
    'technology + security': 'technik',

    // FR
    'Systèmes d\'assistance': 'assistenz',
    'Extérieur': 'aussen',
    'Notes de bas de page': 'fussnoten',
    'Intérieur': 'innen',
    'Multimédia': 'multimedia',
    'Packs': 'pakete',
    'Roues + pneus': 'raeder',
    'Phares + feux': 'scheinwerfer',
    'Sièges + garnitures': 'sitze',
    'Autres': 'sonstiges',
    'Technique + sécurité': 'technik',
};

function prepareAccessoires(aus: Car<false>['ausstattung']): Car<true>['ausstattung'] {
    const newAus: Car<true>['ausstattung'] = {};

    Object.keys(aus || {})?.map((key) => {
        newAus[ausCategoryMap[key]] = aus[key];
    })

    return newAus;
}

function prepareCarData(car: Car<true>, settings: ConfigObject): { bilderUrl: Car<false>['bilder']['url'], bilderUrlHD: Car<false>['bilder']['urlHD'], bilderUrlS: Car<false>['bilder']['urlS'], usersSelect: Car<false>['usersSelect'], verbrauch: Car<false>['verbrauch'] } {
    const usersSelect = [];
    if (!checkUndefinedNullOrEmpty(car?.users)) {
        car?.users?.map(function (u) {
            if (!checkUndefinedNullOrEmpty(u) && !checkUndefinedNullOrEmpty(u.i)) {
                const i = u.i || '-1';
                const idf = u.idf || '-1';
                const vn = u.vn || '';
                const nn = u.nn || '';
                usersSelect.push({ i: i, t: [vn, nn].join(' '), avatar: ('https://car-desk.com/r_images/s/v1/firma/' + idf + '/' + i + '/user.jpg'), avatarFallback: [vn?.substring(0, 1), nn?.substring(0, 1)] });
            }
        });
    }

    const wltp = car?.engine?.wltp || car?.wltp;
    let verbrauch: CarVerbrauchParsed = undefined;

    if (!checkUndefinedNullOrEmpty(wltp)) {
        const engineMotor = (car?.engine?.motor || '');
        // const biFuel = engineMotor?.startsWith('Bi Fuel');
        const elektro = engineMotor === 'Elektro';
        // const gasantrieb = (engineMotor === 'Autogas (LPG)' || engineMotor') === 'Erdgas (CNG)');
        const hybrid = engineMotor?.startsWith('Hybrid');
        const plugInHybrid = engineMotor.startsWith('Plug-In-Hybrid');
        const ks1 = car?.engine?.kraftstoff || '';
        const ks2 = car?.engine?.kraftstoff_1 || '';
        const einheiten = [
            (ks1 === 'Strom') || elektro ? 'kWh/100km' : (ks1.includes('Erdgas') || ks1.includes('Autogas')) ? 'kg/100km' : 'l/100km',
            (ks2 === 'Strom') || plugInHybrid ? 'kWh/100km' : (ks2.includes('Erdgas') || ks2.includes('Autogas')) ? 'kg/100km' : 'l/100km',
        ];
        const commonCarVerbrauchParsed: Pick<CarVerbrauchParsed, 'euronorm' | 'envkv' | 'motor'> = {
            envkv: car?.engine?.envkv,
            euronorm: !elektro ? (car?.engine?.schadstoff || '-') : undefined,
            motor: !checkUndefinedNullOrEmpty(engineMotor) ? engineMotor : '-',
        };
        const verbrauchNumbered: CarVerbrauchParsedNumbered = {
            combined: elektro ? wltp?.w_cpc : plugInHybrid ? wltp?.w_cfcw : wltp?.w_cfc,
            combinedDischarged: plugInHybrid ? wltp?.w_cfcDischarged : undefined,
            combinedSecondary: hybrid || plugInHybrid ? wltp?.w_cpcw : hybrid ? wltp?.w_cpc : undefined,
            low: elektro || plugInHybrid ? wltp?.w_cpl : wltp?.w_cfl,
            lowDischarged: plugInHybrid ? wltp?.w_cfl : undefined,
            medium: elektro || plugInHybrid ? wltp?.w_cpm : wltp?.w_cfm,
            mediumDischarged: plugInHybrid ? wltp?.w_cfm : undefined,
            high: elektro || plugInHybrid ? wltp?.w_cph : wltp?.w_cfh,
            highDischarged: plugInHybrid ? wltp?.w_cfh : undefined,
            extraHigh: elektro || plugInHybrid ? wltp?.w_cpeh : wltp?.w_cfeh,
            extraHighDischarged: plugInHybrid ? wltp?.w_cfeh : undefined,
            emission: plugInHybrid ? wltp?.w_co2ecw : wltp?.w_co2ec,
            emissionDischarged: plugInHybrid ? wltp?.w_co2ecDischarged : undefined,
            range: plugInHybrid || elektro ? wltp?.w_er : undefined,
            effizienz: effizienzSteps?.find((e) => e?.t === (plugInHybrid ? wltp?.co2classWeighted : wltp?.co2class))?.i as EffizienzClass || EffizienzClass.N_A,
            effizienzDischarged: plugInHybrid ? (effizienzSteps?.find((e) => e?.t === wltp?.co2classDischarged)?.i as EffizienzClass || EffizienzClass.N_A) : undefined,
            energycosts: wltp?.a_costs15,
            co2costsLow: wltp?.co2costslow,
            co2costsMedium: wltp?.co2costsmedium,
            co2costsHigh: wltp?.co2costshigh,
        };
        verbrauch = {
            ...commonCarVerbrauchParsed,
            combined: !checkUndefinedNullOrEmpty(verbrauchNumbered?.combined)
                ? [
                    getValue(verbrauchNumbered?.combined, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            combinedDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.combinedDischarged)
                ? [
                    getValue(verbrauchNumbered?.combinedDischarged, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            combinedSecondary: !checkUndefinedNullOrEmpty(verbrauchNumbered?.combinedSecondary)
                ? [
                    getValue(verbrauchNumbered?.combinedSecondary, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[1],
                ].join(' ')
                : undefined,
            low: !checkUndefinedNullOrEmpty(verbrauchNumbered?.low)
                ? [
                    getValue(verbrauchNumbered?.low, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[plugInHybrid ? 1 : 0],
                ].join(' ')
                : undefined,
            lowDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.lowDischarged)
                ? [
                    getValue(verbrauchNumbered?.lowDischarged, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            medium: !checkUndefinedNullOrEmpty(verbrauchNumbered?.medium)
                ? [
                    getValue(verbrauchNumbered?.medium, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[plugInHybrid ? 1 : 0],
                ].join(' ')
                : undefined,
            mediumDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.mediumDischarged)
                ? [
                    getValue(verbrauchNumbered?.mediumDischarged, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            high: !checkUndefinedNullOrEmpty(verbrauchNumbered?.high)
                ? [
                    getValue(verbrauchNumbered?.high, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[plugInHybrid ? 1 : 0],
                ].join(' ')
                : undefined,
            highDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.highDischarged)
                ? [
                    getValue(verbrauchNumbered?.highDischarged, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            extraHigh: !checkUndefinedNullOrEmpty(verbrauchNumbered?.extraHigh)
                ? [
                    getValue(verbrauchNumbered?.extraHigh, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[plugInHybrid ? 1 : 0],
                ].join(' ')
                : undefined,
            extraHighDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.extraHighDischarged)
                ? [
                    getValue(verbrauchNumbered?.extraHighDischarged, 1, car?.engine?.envkv === true ? undefined : 'ca.'),
                    einheiten[0],
                ].join(' ')
                : undefined,
            emission: !checkUndefinedNullOrEmpty(verbrauchNumbered?.emission)
                ? [
                    getValue(verbrauchNumbered?.emission, 0, car?.engine?.envkv === true ? undefined : 'ca.'),
                    'g/km',
                ].join(' ')
                : undefined,
            emissionDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.emissionDischarged)
                ? [
                    getValue(verbrauchNumbered?.emissionDischarged, 0, car?.engine?.envkv === true ? undefined : 'ca.'),
                    'g/km',
                ].join(' ')
                : undefined,
            range: !checkUndefinedNullOrEmpty(verbrauchNumbered?.range)
                ? [
                    getValue(verbrauchNumbered?.range, 0, car?.engine?.envkv === true ? undefined : 'ca.'),
                    'km',
                ].join(' ')
                : undefined,
            effizienz: !checkUndefinedNullOrEmpty(verbrauchNumbered?.effizienz)
                ? verbrauchNumbered?.effizienz
                : undefined,
            effizienzDischarged: !checkUndefinedNullOrEmpty(verbrauchNumbered?.effizienzDischarged)
                ? verbrauchNumbered?.effizienzDischarged
                : undefined,
            energycosts: !checkUndefinedNullOrEmpty(verbrauchNumbered?.energycosts)
                ? [
                    getValue(verbrauchNumbered?.energycosts, 2, car?.engine?.envkv === true ? undefined : 'ca.'),
                    '€',
                ].join(' ')
                : undefined,
            co2costsLow: !checkUndefinedNullOrEmpty(verbrauchNumbered?.co2costsLow)
                ? [
                    getValue(verbrauchNumbered?.co2costsLow, 2, car?.engine?.envkv === true ? undefined : 'ca.'),
                    '€',
                ].join(' ')
                : undefined,
            co2costsMedium: !checkUndefinedNullOrEmpty(verbrauchNumbered?.co2costsMedium)
                ? [
                    getValue(verbrauchNumbered?.co2costsMedium, 2, car?.engine?.envkv === true ? undefined : 'ca.'),
                    '€',
                ].join(' ')
                : undefined,
            co2costsHigh: !checkUndefinedNullOrEmpty(verbrauchNumbered?.co2costsHigh)
                ? [
                    getValue(verbrauchNumbered?.co2costsHigh, 2, car?.engine?.envkv === true ? undefined : 'ca.'),
                    '€',
                ].join(' ')
                : undefined,
        };
    }

    const bilderUrl = car?.bilder?.url?.slice(
        !checkUndefinedNullOrEmpty(settings?.fahrzeugsuche?.firstImageIndex)
            ? settings?.fahrzeugsuche?.firstImageIndex
            : car?.bilder?.ec === false || (settings?.fahrzeugsuche?.useEyecatcher && car?.bilder?.ec === true)
                ? 0
                : 1,
    ) || [];
    const bilderUrlS = car?.bilder?.urlS?.slice(
        !checkUndefinedNullOrEmpty(settings?.fahrzeugsuche?.firstImageIndex)
            ? settings?.fahrzeugsuche?.firstImageIndex
            : car?.bilder?.ec === false || (settings?.fahrzeugsuche?.useEyecatcher && car?.bilder?.ec === true)
                ? 0
                : 1,
    ) || [];
    const bilderUrlHD = car?.bilder?.urlHD?.slice(
        !checkUndefinedNullOrEmpty(settings?.fahrzeugsuche?.firstImageIndex)
            ? settings?.fahrzeugsuche?.firstImageIndex
            : car?.bilder?.ec === false || (settings?.fahrzeugsuche?.useEyecatcher && car?.bilder?.ec === true)
                ? 0
                : 1,
    ) || [];

    return { bilderUrl, bilderUrlHD, bilderUrlS, usersSelect, verbrauch };
}

export async function handleCarNavigateData(car: Car<true>): Promise<NavigateCar> {
    if (checkUndefinedNullOrEmpty(car)) {
        return undefined;
    } else {
        return {
            hersteller: car?.hersteller,
            id: car?.id,
            loc: {
                alias: car?.loc?.alias,
                hm: car?.loc?.hm,
                id: car?.loc?.id,
                image: car?.loc?.image,
                mail: car?.loc?.mail,
                name: car?.loc?.name,
                openMo: car?.loc?.openMo,
                openDi: car?.loc?.openDi,
                openMi: car?.loc?.openMi,
                openDo: car?.loc?.openDo,
                openFr: car?.loc?.openFr,
                openSa: car?.loc?.openSa,
                openSo: car?.loc?.openSo,
                openServiceMo: car?.loc?.openServiceMo,
                openServiceDi: car?.loc?.openServiceDi,
                openServiceMi: car?.loc?.openServiceMi,
                openServiceDo: car?.loc?.openServiceDo,
                openServiceFr: car?.loc?.openServiceFr,
                openServiceSa: car?.loc?.openServiceSa,
                openServiceSo: car?.loc?.openServiceSo,
                openWerkstattMo: car?.loc?.openWerkstattMo,
                openWerkstattDi: car?.loc?.openWerkstattDi,
                openWerkstattMi: car?.loc?.openWerkstattMi,
                openWerkstattDo: car?.loc?.openWerkstattDo,
                openWerkstattFr: car?.loc?.openWerkstattFr,
                openWerkstattSa: car?.loc?.openWerkstattSa,
                openWerkstattSo: car?.loc?.openWerkstattSo,
                ort: car?.loc?.ort,
                plz: car?.loc?.plz,
                str: car?.loc?.str,
                tel: car?.loc?.tel,
                tel_hpt: car?.loc?.tel_hpt,
            },
            modell: car?.modell,
            name: car?.name,
            nameSlug: stringToSlug(car?.name),
            typ: car?.typ,
        };
    }
}

export async function handleCar(car: Car<true>, module: Modules, settings: ConfigObject, faarenVehicles: FaarenListCar[], loadPricePlans: boolean): Promise<Car<false>> {
    if (checkUndefinedNullOrEmpty(car)) {
        return undefined;
    } else {
        const { bilderUrl, bilderUrlHD, bilderUrlS, usersSelect, verbrauch } = prepareCarData(car, settings);
        const faarenVehicle = faarenVehicles?.find((v) => v?.external_id === car?.ident);

        let faarenPriceList = [];
        try {
            faarenPriceList = (!checkUndefinedNullOrEmpty(faarenVehicle?.price_plans) && loadPricePlans === true ? (await getFaarenPriceList(faarenVehicle?.price_plans, settings?.api?.faaren?.apiKey))?.price_plans : [])?.map((v) => ({ ...v, price: v?.price / 100 }));
        } catch (err) {
            console.error(err);
        }

        return {
            aktion: car?.aktion === '1',
            antrieb: car?.antrieb,
            aufbau: car?.aufbau,
            ausstattung: prepareAccessoires(car?.ausstattung),
            autoAbo: !checkUndefinedNullOrEmpty(faarenVehicle)
                ? {
                    additional_milage_costs: ((faarenVehicle?.additional_mileage_cost || 0) / 100),
                    damage_participation: parseFloat(faarenVehicle?.damage_participation?.replace(/(\s|\.|€)+/g, '')?.replace(',', '.')),
                    delivery_option: [faarenVehicle?.delivery_option],
                    delivery_time_after_booking: faarenVehicle?.delivery_time_after_booking || 0,
                    fixed_duration: faarenVehicle?.fixed_duration === 1,
                    immediately_available: faarenVehicle?.immediately_available === 1,
                    min_license_duration: faarenVehicle?.min_license_duration || 0,
                    minimum_age: parseInt(faarenVehicle?.minimum_age?.replace(/[A-z\s]+/g, '') || '0'),
                    pick_up_option: [faarenVehicle?.pick_up_option],
                    plans: faarenPriceList,
                }
                : undefined,
            bilder: {
                '360': car?.bilder?.['360'],
                '360a': car?.bilder?.['360a'],
                '360a_h': car?.bilder?.['360a_h'],
                '360a_w': car?.bilder?.['360a_w'],
                'anz360a': car?.bilder?.anz360a,
                'bilder': car?.bilder?.bilder,
                'ec': car?.bilder?.ec,
                'hd': car?.bilder?.hd,
                'pano': car?.bilder?.pano,
                'url': bilderUrl,
                'urlHD': bilderUrlHD,
                'urlS': bilderUrlS,
            },
            desc: {
                assistenz: car?.desc?.assistenz,
                aussen: car?.desc?.aussen,
                finanzierung: car?.desc?.finanzierung,
                fuss: car?.desc?.fuss,
                fussnoten: car?.desc?.fussnoten,
                garantie: car?.desc?.garantie,
                highlights: car?.desc?.highlights,
                innen: car?.desc?.innen,
                isList: car?.desc?.isList,
                kopf: car?.desc?.kopf,
                multimedia: car?.desc?.multimedia,
                pakete: car?.desc?.pakete,
                preissenkung: car?.desc?.preissenkung,
                raeder: car?.desc?.raeder,
                scheinwerfer: car?.desc?.scheinwerfer,
                sitze: car?.desc?.sitze,
                sonstiges: car?.desc?.sonstiges,
                technik: car?.desc?.technik,
            },
            design: car?.design,
            engine: {
                effizienz: car?.engine?.effizienz !== '(unbekannt)' ? car?.engine?.effizienz : undefined,
                envkv: car?.engine?.envkv,
                hubraum: car?.engine?.hubraum,
                kraftstoff: car?.engine?.kraftstoff,
                kraftstoff_1: car?.engine?.kraftstoff_1,
                leistung: `${(car?.engine?.leistung?.[1] || '-')} PS`,
                leistungKw: `${(car?.engine?.leistung?.[0] || '-')} kW`,
                leistungKwNumbered: car?.engine?.leistung?.[0],
                motor: car?.engine?.motor?.split(' ')?.[0],
                motorTooltip: car?.engine?.motor?.split(' ')?.[1],
                plakette: car?.engine?.plakette,
                schadstoff: car?.engine?.schadstoff,
            },
            ez: !checkUndefinedOrNull(car?.ez) ? parseDate(car?.ez, 'dd.MM.yyyy') : undefined,
            fahrbereit: car?.fahrbereit,
            farbe: { grund: car?.farbe?.[0], name: car?.farbe?.[1] },
            farbe_innen: { grund: car?.farbe_innen?.[0], name: car?.farbe_innen?.[1] },
            finanzierung: settings?.general?.showFinanzierungen === true && !checkUndefinedNullOrEmpty(car?.finanzierung) ? {
                abschlussgebühr: car?.finanzierung?.abschlussgebühr,
                anbieterbank: car?.finanzierung?.anbieterbank,
                anzahlung: !checkUndefinedNullOrEmpty(car?.finanzierung?.anzahlung) ? Math.round(car?.finanzierung?.anzahlung) : null,
                art_sollzinssatzes: car?.finanzierung?.art_sollzinssatzes,
                bruttokreditbetrag: car?.finanzierung?.bruttokreditbetrag,
                effektiver_jahreszins: car?.finanzierung?.effektiver_jahreszins,
                km: !checkUndefinedNullOrEmpty(car?.finanzierung?.km) ? parseFloat(car?.finanzierung?.km) : undefined,
                ksb: car?.finanzierung?.ksb,
                ksb_betrag: !checkUndefinedNullOrEmpty(car?.finanzierung?.ksb_betrag) ? parseFloat(car?.finanzierung?.ksb_betrag) : undefined,
                laufzeit: car?.finanzierung?.laufzeit,
                monatliche_rate: car?.finanzierung?.monatliche_rate,
                monatliche_rate_netto: car?.finanzierung?.monatliche_rate_netto,
                nettrokreditbetrag: !checkUndefinedNullOrEmpty(car?.finanzierung?.nettrokreditbetrag) ? parseFloat(car?.finanzierung?.nettrokreditbetrag) : undefined,
                schlussrate: car?.finanzierung?.schlussrate,
                soll_zinssatz: !checkUndefinedNullOrEmpty(car?.finanzierung?.soll_zinssatz) ? parseFloat(car?.finanzierung?.soll_zinssatz) : undefined,
                text1: car?.finanzierung?.text1,
                text2: car?.finanzierung?.text2,
            } : undefined,
            Firma: car?.Firma,
            firmaAlias: car?.firmaAlias,
            firmaName: car?.firmaName,
            garantie: { id: car?.garantie?.[0], name: car?.garantie?.[1] },
            getriebe: (
                !checkUndefinedNullOrEmpty(car?.getriebe)
                    ? (
                        settings?.general?.language === Lang.deDE
                            ? car?.getriebe?.substring(0, car?.getriebe.indexOf(' ') > 0 ? car?.getriebe.indexOf(' ') : undefined)
                            : car?.getriebe
                    )
                    : undefined
            ),
            herkunft: car?.herkunft,
            hersteller: car?.hersteller,
            hsn: car?.hsn,
            hu: !checkUndefinedOrNull(car?.hu) ? parseDate(car?.hu, 'dd.MM.yyyy') : undefined,
            id: car?.id,
            ident: car?.ident,
            idFirma: car?.idFirma,
            idStandort: car?.idStandort,
            is_eyecatcher: car?.is_eyecatcher,
            is_id_firmenimage: car?.is_id_firmenimage,
            is_id_logo: car?.is_id_logo,
            kategorie: car?.kategorie,
            km: car?.km,
            land: car?.land,
            leasing: settings?.general?.showLeasing === true && !checkUndefinedNullOrEmpty(car?.leasing) ? {
                anbieterbank: car?.leasing?.anbieterbank,
                anzahlung: car?.leasing?.anzahlung,
                DestinationCharges: !checkUndefinedNullOrEmpty(car?.leasing?.DestinationCharges) ? parseFloat(car?.leasing?.DestinationCharges) : undefined,
                effektiver_jahreszins: !checkUndefinedNullOrEmpty(car?.leasing?.effektiver_jahreszins) ? parseFloat(car?.leasing?.effektiver_jahreszins) : undefined,
                ExtraMileageCosts: !checkUndefinedNullOrEmpty(car?.leasing?.ExtraMileageCosts) ? (parseFloat(car?.leasing?.ExtraMileageCosts) / 100) : undefined,
                km: !checkUndefinedNullOrEmpty(car?.leasing?.km) ? parseFloat(car?.leasing?.km) : undefined,
                laufzeit: !checkUndefinedNullOrEmpty(car?.leasing?.laufzeit) ? parseFloat(car?.leasing?.laufzeit) : undefined,
                LowMileageCompensation: !checkUndefinedNullOrEmpty(car?.leasing?.LowMileageCompensation) ? (parseFloat(car?.leasing?.LowMileageCompensation) / 100) : undefined,
                monatliche_rate: !checkUndefinedNullOrEmpty(car?.leasing?.monatliche_rate) ? parseFloat(car?.leasing?.monatliche_rate) : undefined,
                monatliche_rate_netto: (
                    !checkUndefinedNullOrEmpty(car?.leasing?.monatliche_rate_netto)
                        ? parseFloat(car?.leasing?.monatliche_rate_netto)
                        : !checkUndefinedNullOrEmpty(car?.leasing?.monatliche_rate)
                            ? (
                                car?.preis?.mwst_satz > 0
                                    ? (parseFloat(car?.leasing?.monatliche_rate) / ((100 + car?.preis?.mwst_satz) / 100))
                                    : parseFloat(car?.leasing?.monatliche_rate)
                            )
                            : undefined
                ),
                nettrokreditbetrag: !checkUndefinedNullOrEmpty(car?.leasing?.nettrokreditbetrag) ? parseFloat(car?.leasing?.nettrokreditbetrag) : undefined,
                RegistrationFees: !checkUndefinedNullOrEmpty(car?.leasing?.RegistrationFees) ? parseFloat(car?.leasing?.RegistrationFees) : undefined,
                soll_zinssatz: !checkUndefinedNullOrEmpty(car?.leasing?.soll_zinssatz) ? parseFloat(car?.leasing?.soll_zinssatz) : undefined,
                TotalAmount: !checkUndefinedNullOrEmpty(car?.leasing?.TotalAmount) ? parseFloat(car?.leasing?.TotalAmount) : undefined,
                vk: !checkUndefinedNullOrEmpty(car?.leasing?.vk) ? car?.leasing?.vk : undefined,
                zielgruppe: 'PRIVATE',
            } : undefined,
            leasingComm: settings?.general?.showLeasing === true && !checkUndefinedNullOrEmpty(car?.leasingComm) ? {
                anbieterbank: car?.leasingComm?.anbieterbank,
                anzahlung: car?.leasingComm?.anzahlung,
                DestinationCharges: !checkUndefinedNullOrEmpty(car?.leasingComm?.DestinationCharges) ? parseFloat(car?.leasingComm?.DestinationCharges) : undefined,
                effektiver_jahreszins: !checkUndefinedNullOrEmpty(car?.leasingComm?.effektiver_jahreszins) ? parseFloat(car?.leasingComm?.effektiver_jahreszins) : undefined,
                ExtraMileageCosts: !checkUndefinedNullOrEmpty(car?.leasingComm?.ExtraMileageCosts) ? (parseFloat(car?.leasingComm?.ExtraMileageCosts) / 100) : undefined,
                km: !checkUndefinedNullOrEmpty(car?.leasingComm?.km) ? parseFloat(car?.leasingComm?.km) : undefined,
                laufzeit: !checkUndefinedNullOrEmpty(car?.leasingComm?.laufzeit) ? parseFloat(car?.leasingComm?.laufzeit) : undefined,
                LowMileageCompensation: !checkUndefinedNullOrEmpty(car?.leasingComm?.LowMileageCompensation) ? (parseFloat(car?.leasingComm?.LowMileageCompensation) / 100) : undefined,
                monatliche_rate: (
                    checkUndefinedNullOrEmpty(car?.leasingComm?.monatliche_rate_netto)
                        ? (
                            !checkUndefinedNullOrEmpty(car?.leasingComm?.monatliche_rate)
                                ? (
                                    car?.preis?.mwst_satz > 0
                                        ? (parseFloat(car?.leasingComm?.monatliche_rate) * ((100 + car?.preis?.mwst_satz) / 100))
                                        : parseFloat(car?.leasingComm?.monatliche_rate)
                                )
                                : undefined
                        )
                        : parseFloat(car?.leasingComm?.monatliche_rate)
                ),
                monatliche_rate_netto: !checkUndefinedNullOrEmpty(car?.leasingComm?.monatliche_rate_netto) ? parseFloat(car?.leasingComm?.monatliche_rate_netto) : !checkUndefinedNullOrEmpty(car?.leasingComm?.monatliche_rate) ? parseFloat(car?.leasingComm?.monatliche_rate) : undefined,
                nettrokreditbetrag: !checkUndefinedNullOrEmpty(car?.leasingComm?.nettrokreditbetrag) ? parseFloat(car?.leasingComm?.nettrokreditbetrag) : undefined,
                RegistrationFees: !checkUndefinedNullOrEmpty(car?.leasingComm?.RegistrationFees) ? parseFloat(car?.leasingComm?.RegistrationFees) : undefined,
                soll_zinssatz: !checkUndefinedNullOrEmpty(car?.leasingComm?.soll_zinssatz) ? parseFloat(car?.leasingComm?.soll_zinssatz) : undefined,
                TotalAmount: !checkUndefinedNullOrEmpty(car?.leasingComm?.TotalAmount) ? parseFloat(car?.leasingComm?.TotalAmount) : undefined,
                vk: !checkUndefinedNullOrEmpty(car?.leasing?.vk) ? car?.leasing?.vk : undefined,
                zielgruppe: 'COMMERCIAL',
            } : undefined,
            lHighlights: car?.lHighlights,
            lieferzeit: car?.lieferzeit,
            loc: {
                alias: car?.loc?.alias,
                hm: car?.loc?.hm,
                id: car?.loc?.id,
                image: car?.loc?.image,
                mail: car?.loc?.mail,
                name: car?.loc?.name,
                openMo: car?.loc?.openMo,
                openDi: car?.loc?.openDi,
                openMi: car?.loc?.openMi,
                openDo: car?.loc?.openDo,
                openFr: car?.loc?.openFr,
                openSa: car?.loc?.openSa,
                openSo: car?.loc?.openSo,
                openServiceMo: car?.loc?.openServiceMo,
                openServiceDi: car?.loc?.openServiceDi,
                openServiceMi: car?.loc?.openServiceMi,
                openServiceDo: car?.loc?.openServiceDo,
                openServiceFr: car?.loc?.openServiceFr,
                openServiceSa: car?.loc?.openServiceSa,
                openServiceSo: car?.loc?.openServiceSo,
                openWerkstattMo: car?.loc?.openWerkstattMo,
                openWerkstattDi: car?.loc?.openWerkstattDi,
                openWerkstattMi: car?.loc?.openWerkstattMi,
                openWerkstattDo: car?.loc?.openWerkstattDo,
                openWerkstattFr: car?.loc?.openWerkstattFr,
                openWerkstattSa: car?.loc?.openWerkstattSa,
                openWerkstattSo: car?.loc?.openWerkstattSo,
                ort: car?.loc?.ort,
                plz: car?.loc?.plz,
                str: car?.loc?.str,
                tel: car?.loc?.tel,
                tel_hpt: car?.loc?.tel_hpt,
            },
            mod_g: car?.mod_g,
            mod_o: car?.mod_o,
            modelkey: car?.modelkey,
            modelkey6: car?.modelkey6,
            modell: car?.modell,
            modelljahr: !checkUndefinedOrNull(car?.modelljahr) ? parseDate(car?.modelljahr, 'yyyy') : undefined,
            name: car?.name,
            nameSlug: stringToSlug(car?.name),
            nfz: car?.nfz,
            polster: car?.polster,
            preis: {
                mwst: car?.preis?.mwst,
                mwst_satz: car?.preis?.mwst_satz,
                transport: car?.preis?.transport,
                upe: car?.preis?.upe,
                vk: settings?.general?.showUpeWithTransferInsteadOfVk === true
                    ? (
                        !checkUndefinedNullOrEmpty(car?.preis?.upe)
                            ? ((car?.preis?.upe || 0) + (car?.preis?.transport || 0))
                            : undefined
                    )
                    : car?.preis?.vk,
                vkn: settings?.general?.showNettoUpeWithTransferInsteadOfVkn === true
                    ? (
                        !checkUndefinedNullOrEmpty(car?.preis?.upe) && !checkUndefinedNullOrEmpty(car?.preis?.mwst)
                            ? ((car?.preis?.upe || 0) + (car?.preis?.transport || 0) - (((car?.preis?.upe || 0) + (car?.preis?.transport || 0)) * (car?.preis?.mwst_satz / 100)))
                            : undefined
                    )
                    : car?.preis?.vkn,
            },
            scheckheft: car?.scheckheft === 'ja',
            siegel: car?.siegel?.[0],

            simc: await handleCarList({ cars: car?.simc?.filter((similar) => car?.id !== similar?.id) }, module, settings, faarenVehicles),
            sitze: car?.sitze,
            sonderangebot: car?.aktion === '1',
            standort: car?.standort,
            tsn: car?.tsn,
            tueren: car?.tueren,
            typ: car?.typ,
            unfall: car?.unfall,
            user: car?.user,
            users: car?.users,
            usersSelect,
            verbrauch,
            verfuegbarAb: !checkUndefinedNullOrEmpty(car?.verfuegbarAb) ? parse(car?.verfuegbarAb?.toString(), 'T', new Date()) : undefined,
            vin: car?.vin,
            vorbesitzer: car?.vorbesitzer,
        };
    }
}

export async function handleCarList(list: CarList['searchResult'], module: Modules, settings: ConfigObject, faarenVehicles: FaarenListCar[]): Promise<Record<string, Car<false>>> {
    const newList: Record<string, Car<false>> = {};
    const oldList = list?.cars || [];

    if (!checkUndefinedNullOrEmpty(oldList)) {
        for (let i = 0; i < oldList?.length; i++) {
            const car = oldList?.[i];
            newList['car_' + car?.id] = await handleCar({
                ...car,
                bilder: {
                    ...car?.bilder,
                    bilder: car?.bilder?.anzahl,
                },
                engine: {
                    ...car?.engine,
                    envkv: car?.envkv,
                    verbrauch_0: [car?.engine?.verbrauch?.[0], null, null, car?.engine?.verbrauch?.[1]],
                },
                leasing: {
                    ...car?.leasing,
                    anzahlung: parseFloat(car?.leasing?.anzahlung),
                },
                leasingComm: {
                    ...car?.leasingComm,
                    anzahlung: parseFloat(car?.leasingComm?.anzahlung),
                },
                preis: {
                    mwst: car?.mwst > 0 ? 1 : 0,
                    mwst_satz: car?.mwst,
                    transport: car?.transport,
                    upe: car?.upe,
                    vk: car?.preis,
                    vkn: car?.vkn,
                },
            }, module, settings, faarenVehicles, false);
        }
    }

    return newList;
}

type ExtendedFacetsService = FacetsService & {
    ezSelect?: FacetService,
    ezAllSelect?: FacetService,
    kmSelect?: FacetService,
    kmAllSelect?: FacetService,
    leistungSelect?: FacetService,
    leistungAllSelect?: FacetService,
    preisSelect?: FacetService,
    preisAllSelect?: FacetService,
    rateSelect?: FacetService,
    rateSelectAll?: FacetService,
};

export function handleFacets(fac: FacetsService, translate: TFunction, settings: ConfigObject): Facets {
    const noText: (keyof ExtendedFacetsService)[] = ['ez', 'ezAll'];
    const isTags: (keyof ExtendedFacetsService)[] = ['tags', 'tagsAll'];
    const formatNumber: (keyof Facets)[] = ['km', 'kmAll', 'kmSelect', 'kmSelectAll', 'Leistung', 'LeistungAll', 'leistungSelect', 'leistungSelectAll', 'preis', 'preisAll', 'preisSelect', 'preisSelectAll', 'rate', 'rateAll', 'rateSelect', 'rateSelectAll'];
    const sortFacetteById: (keyof Facets)[] = ['km', 'kmAll', 'Leistung', 'LeistungAll', 'preis', 'preisAll', 'rate', 'rateAll'];
    const rateAllFinanzierung = [];

    const facets: ExtendedFacetsService = { ...fac };
    if (!checkUndefinedNullOrEmpty(facets.getriebe)) {
        facets.getriebe.Manuell = facets.getriebe.manuell;
        delete facets.getriebe.manuell;
        facets.getriebeAll.Manuell = facets?.getriebeAll.manuell;
        delete facets.getriebeAll.manuell;
    }
    facets.aufbauten = facets?.aufbauAll || {};
    facets.ezSelect = facets?.ez || {};
    facets.ezAllSelect = facets?.ezAll || {};
    facets.kmSelect = facets?.km || {};
    facets.kmAllSelect = facets?.kmAll || {};
    facets.leistungSelect = facets.Leistung || {};
    facets.leistungAllSelect = facets.LeistungAll || {};
    facets.preisSelect = facets?.preis || {};
    facets.preisAllSelect = facets?.preisAll || {};
    facets.rateSelect = facets?.rate || {};
    facets.rateSelectAll = facets?.rateAll || {};
    const newFacets: Facets = {
        aus: arrAus(translate),
    };

    Object.keys(facets)?.filter((facette) => ['ez', 'km', 'Leistung', 'preis', 'rate', 'ezAll', 'kmAll', 'LeistungAll', 'preisAll', 'rateAll'].includes(facette)).map(function (facette: any) {
        const newFacette: Facet[] = [];
        const facetElementKeys = Object.keys(facets?.[facette] || {})?.filter((key) => (key !== '(unbekannt)' && !checkUndefinedNullOrEmpty(facets?.[facette]?.[key])))?.map((e) => parseInt(e?.replace(/(\.|,)/g, '')));
        const facetElementKeysAll = Object.keys(facets?.[`${facette}All`] || {})?.filter((key) => (key !== '(unbekannt)' && !checkUndefinedNullOrEmpty(facets?.[`${facette}All`]?.[key])))?.map((e) => parseInt(e?.replace(/(\.|,)/g, '')));
        const rawMax: number = Math.max(...(!checkUndefinedNullOrEmpty(facetElementKeys) && facetElementKeys?.length > 1 ? facetElementKeys : facetElementKeysAll));
        const rawMin: number = Math.min(...(!checkUndefinedNullOrEmpty(facetElementKeys) && facetElementKeys?.length > 1 ? facetElementKeys : facetElementKeysAll));
        const max = rawMax >= 0 ? rawMax : 0;
        const min = rawMin >= 0 ? rawMin : 0;
        const step = (facette === 'ez' || facette === 'Leistung') ? 1 : facette === 'km' ? 500 : 100;

        for (let i = min; i <= max; i = i + step) {
            newFacette.push({
                i,
                t: `${formatNumber.includes(facette) ? i?.toLocaleString('de-DE') : i?.toString()}${facette === 'ez' ? '' : ` ${facette === 'km' ? 'km' : (facette === 'Leistung' || facette === 'LeistungAll') ? 'kW' : '€'}`}`,
                tSlug: stringToSlug(i?.toString()),
            });
        }

        if (newFacette?.[0]?.i !== min) {
            newFacette.unshift({
                i: min,
                t: `${formatNumber.includes(facette) ? min?.toLocaleString('de-DE') : min?.toString()}${facette === 'ez' ? '' : ` ${facette === 'km' ? 'km' : (facette === 'Leistung' || facette === 'LeistungAll') ? 'kW' : '€'}`}`,
                tSlug: stringToSlug(min?.toString()),
            });
        }
        if (newFacette?.[newFacette?.length - 1]?.i !== max) {
            newFacette.push({
                i: max,
                t: `${formatNumber.includes(facette) ? max?.toLocaleString('de-DE') : max?.toString()}${facette === 'ez' ? '' : ` ${facette === 'km' ? 'km' : (facette === 'Leistung' || facette === 'LeistungAll') ? 'kW' : '€'}`}`,
                tSlug: stringToSlug(max?.toString()),
            });
        }

        const indexForStepLabels = [0, newFacette?.length - 1];
        newFacets[facette] = newFacette?.map((el, index) => ({ ...el, showLabel: indexForStepLabels.includes(index) }));
    });

    if (!checkUndefinedNullOrEmpty(newFacets.ez)) {
        const date = new Date();
        if (newFacets.ez.length === 0) {
            newFacets.ez.push(...[
                { i: date.getFullYear(), t: date.getFullYear()?.toString(), tSlug: stringToSlug(date.getFullYear()?.toString()) },
                { i: date.getFullYear(), t: date.getFullYear()?.toString(), tSlug: stringToSlug(date.getFullYear()?.toString()) },
            ]);
        } else if (newFacets.ez.length === 1) {
            newFacets.ez.push({ i: date.getFullYear(), t: date.getFullYear()?.toString(), tSlug: stringToSlug(date.getFullYear()?.toString()) });
        }

        if (newFacets.ez?.[0]?.i === date.getFullYear()) {
            newFacets.ez[0].i = -2;
        }
    }

    if (!checkUndefinedNullOrEmpty(facets.rateAll)) {
        rateAllFinanzierung.push({ i: -1, t: translate('common:filter.alle') });
        const maxValue: number = !checkUndefinedNullOrEmpty(facets?.rateAll) && !checkUndefinedNullOrEmpty(facets?.rateAll[Object.keys(facets?.rateAll).length - 1]) && facets?.rateAll[Object.keys(facets?.rateAll).length - 1][0] <= 550 ? facets?.rateAll[Object.keys(facets?.rateAll).length - 1][0] : 550;
        for (let i = 150; i <= (maxValue + 100); i = i + 100) {
            if (i === 150) {
                rateAllFinanzierung.push({ i: [null, i].toString(), iSend: [null, i], t: `${translate('common:filter.to')} ${i} €` });
            } else if (i === (maxValue + 100)) {
                rateAllFinanzierung.push({ i: [maxValue, null].toString(), iSend: [maxValue, null], t: `${translate('common:filter.moreThan')} ${maxValue} €` });
            } else {
                rateAllFinanzierung.push({ i: [i - 100, i].toString(), iSend: [i - 100, i], t: `${(i - 100)} ${translate('common:filter.to')} ${i} €` });
            }
        }
        newFacets.rateAllFinanzierung = rateAllFinanzierung;
    }

    Object.keys(facets)?.filter((facette) => ['firmen', 'standort', 'firmenAll', 'standortAll'].includes(facette)).map(function (facette: any) {
        const newFacette: Facet[] = [];
        const facettenData = settings?.general?.isGruppe ? facets?.firmenData : facets?.standortData;
        Object.keys(facets[facette]).map(function (element) {
            const data = facettenData?.[facets?.[facette]?.[element]?.[0]] || {};
            if (facets[facette][element] && facets[facette][element][0] > -1) {
                newFacette.push({
                    i: isTags.includes(facette) ? element : facets?.[facette]?.[element]?.[0],
                    t: noText.includes(facette) ? undefined : formatNumber.includes(facette) ? facets?.[facette]?.[element]?.[0]?.toLocaleString('de-DE') : element,
                    tSlug: stringToSlug(element),
                    et: '(' + facets[facette][element][1] + ')',
                    sd: !checkUndefinedNullOrEmpty(data) ? { ...data, resultCount: facets?.[facette]?.[element]?.[1] || 0 } : undefined,
                });
            }
        });
        newFacets[facette] = newFacette;
    });

    Object.keys(facets)?.filter((facette) => !['ez', 'firmen', 'Leistung', 'km', 'preis', 'rate', 'standort', 'ezAll', 'firmenAll', 'kmAll', 'LeistungAll', 'preisAll', 'rateAll', 'standortAll'].includes(facette)).map(function (facette: any) {
        const newFacette: Facet[] = [];
        Object.keys(facets[facette]).map(function (element) {
            if (facets[facette][element] && facets[facette][element][0] > -1) {
                newFacette.push({
                    i: isTags.includes(facette) ? element : facets?.[facette]?.[element]?.[0],
                    t: noText.includes(facette)
                        ? undefined
                        : formatNumber.includes(facette)
                            ? facets?.[facette]?.[element]?.[0]?.toLocaleString('de-DE')
                            : element === 'CARAVAN' || element === 'KRAD' || element === 'REISEMOBIL' || element === 'TRANSPORTER'
                                ? translate(`common:filter.kategorie${element}`)
                                : element,
                    tSlug: stringToSlug(element),
                    et: '(' + facets[facette][element][1] + ')',
                });
            }
        });
        newFacets[facette] = newFacette?.sort((a, b) => {
            if (sortFacetteById?.includes(facette) && typeof a?.i === 'number' && typeof b?.i === 'number') {
                return a?.i - b?.i;
            } else {
                return a?.t?.toLowerCase()?.localeCompare(b?.t?.toLowerCase());
            }
        });
    });

    return newFacets;
}

export function getCarList(idFirma: ConfigObject['general']['idFirma'], suche: Filter, signal: AbortController['signal'], currentLang: Language['value'], debug: boolean, module: Modules): { service: CustomAxiosPromise<CarList>, search: Filter } {
    const dataPathString = [];
    const dataPath: any = { firma: idFirma, nfzHersteller: 1, fiza: 1, leas: 1, ...suche };
    const date = new Date();

    if (!checkUndefinedOrNull(dataPath.view)) delete dataPath.view;

    if (dataPath?.aufbauten) {
        dataPath.aufbauten = dataPath.aufbauten.join(',');
    }

    if (dataPath.ia) {
        dataPath.ia = dataPath.ia.join('.');
    }

    if (dataPath.EZ) {
        dataPath.EZVon = !checkUndefinedOrNull(dataPath.EZ[0]) && dataPath.EZ[0] !== date.getFullYear() ? dataPath.EZ[0] : undefined;
        dataPath.EZBis = !checkUndefinedOrNull(dataPath.EZ[1]) && dataPath.EZ[1] !== date.getFullYear() ? dataPath.EZ[1] : undefined;
        delete dataPath.EZ;
    }
    if (dataPath.KM) {
        dataPath.KMVon = !checkUndefinedOrNull(dataPath.KM[0]) ? dataPath.KM[0] : undefined;
        dataPath.KMBis = !checkUndefinedOrNull(dataPath.KM[1]) ? dataPath.KM[1] : undefined;
        delete dataPath.KM;
    }
    if (dataPath.Leistung) {
        dataPath.LeistungVon = !checkUndefinedOrNull(dataPath.Leistung[0]) ? Math.round(dataPath.Leistung[0]) : undefined;
        dataPath.LeistungBis = !checkUndefinedOrNull(dataPath.Leistung[1]) ? Math.round(dataPath.Leistung[1]) : undefined;
        delete dataPath.Leistung;
    }
    if (dataPath.Preis) {
        dataPath.PreisVon = !checkUndefinedOrNull(dataPath.Preis[0]) ? dataPath.Preis[0] : undefined;
        dataPath.PreisBis = !checkUndefinedOrNull(dataPath.Preis[1]) ? dataPath.Preis[1] : undefined;
        delete dataPath.Preis;
    }
    if (dataPath.Rate) {
        dataPath.rateVon = !checkUndefinedOrNull(dataPath.Rate[0]) ? dataPath.Rate[0] : undefined;
        dataPath.rateBis = !checkUndefinedOrNull(dataPath.Rate[1]) ? dataPath.Rate[1] : undefined;
        if (!checkUndefinedOrNull(dataPath.fiz)) {
            dataPath.fiz = 1;
        }
        delete dataPath.Rate;
    }
    if (dataPath.Standort) {
        dataPath.Standort = dataPath.Standort.join(',');
    }
    if (dataPath.StandortQuelle) {
        dataPath.StandortQuelle = dataPath.StandortQuelle.join(',');
    }
    if (dataPath.ignoreFirma) {
        dataPath.ignoreFirma = dataPath.ignoreFirma.join(',');
    }
    if (dataPath.ignoreStandorte) {
        dataPath.ignoreStandorte = dataPath.ignoreStandorte.join(',');
    }
    if (dataPath.tags) {
        dataPath.tags = encodeURIComponent(dataPath.tags);
    }

    dataPath.lang = currentLang?.split('-')?.[0] || 'de';

    if (dataPath.page) {
        dataPath['page.page'] = dataPath.page;
        dataPath['page.size'] = dataPath.pageSize;
        delete dataPath.page;
        delete dataPath.pageSize;
    }

    if (debug) {
        console.log(`${module} - getCarList - dataPath`, dataPath);
    }

    const searchForOcto: Filter = {};

    for (let i = 0; i < Object.keys(dataPath).length; i++) {
        const key = Object.keys(dataPath)[i];
        const value = dataPath[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') {
            dataPathString.push(key + '=' + value);
            searchForOcto[key] = value;
        }
    }

    if (debug) {
        console.log(`${module} - getCarList - searchForOcto`, searchForOcto);
        console.log(`${module} - getCarList - dataPathString`, dataPathString);
    }

    return {
        search: searchForOcto,
        service: axios<CarList>({
            title: 'Abrufen der Fahrzeugliste',
            noLogging: false,
            idFirma,
            method: 'get',
            url: `${urlSRelease}/search/glist?${dataPathString.join('&')}`,
            signal,
        }),
    };
}

export function getAllFacets(idFirma: ConfigObject['general']['idFirma'], currentLang: Language['value']) {
    const dataPathString = [];
    const dataPath: any = { firma: idFirma, nfzHersteller: 1, fiza: 1, leas: 1, lang: (currentLang?.split('-')?.[0] || 'de'), onlyFacetten: 1 };

    for (let i = 0; i < Object.keys(dataPath).length; i++) {
        const key = Object.keys(dataPath)[i];
        const value = dataPath[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') dataPathString.push(key + '=' + value);
    }

    return axios<CarList>({
        title: 'Abrufen der Facetten',
        noLogging: false,
        idFirma,
        method: 'get',
        url: `${urlSRelease}/search/glist?${dataPathString.join('&')}`,
    });
}

export function getCar(idCar: Car<false>['id'], idFirma: ConfigObject['general']['idFirma'], similarCarsFiltersParam: Record<string, any>, signal: AbortController['signal'], currentLang: Language['value']) {
    const sCarsFiltersString = [];
    const similarCarsFilters = { ...similarCarsFiltersParam };

    if (similarCarsFilters.EZ) {
        similarCarsFilters.EZVon = !checkUndefinedOrNull(similarCarsFilters.EZ[0]) ? similarCarsFilters.EZ[0] : undefined;
        similarCarsFilters.EZBis = !checkUndefinedOrNull(similarCarsFilters.EZ[1]) ? similarCarsFilters.EZ[1] : undefined;
        delete similarCarsFilters.EZ;
    }
    if (similarCarsFilters.KM) {
        similarCarsFilters.KMVon = !checkUndefinedOrNull(similarCarsFilters.KM[0]) ? similarCarsFilters.KM[0] : undefined;
        similarCarsFilters.KMBis = !checkUndefinedOrNull(similarCarsFilters.KM[1]) ? similarCarsFilters.KM[1] : undefined;
        delete similarCarsFilters.KM;
    }
    if (similarCarsFilters.Leistung) {
        similarCarsFilters.LeistungVon = !checkUndefinedOrNull(similarCarsFilters.Leistung[0]) ? Math.round(similarCarsFilters.Leistung[0]) : undefined;
        similarCarsFilters.LeistungBis = !checkUndefinedOrNull(similarCarsFilters.Leistung[1]) ? Math.round(similarCarsFilters.Leistung[1]) : undefined;
        delete similarCarsFilters.Leistung;
    }
    if (similarCarsFilters.Preis) {
        similarCarsFilters.PreisVon = !checkUndefinedOrNull(similarCarsFilters.Preis[0]) ? similarCarsFilters.Preis[0] : undefined;
        similarCarsFilters.PreisBis = !checkUndefinedOrNull(similarCarsFilters.Preis[1]) ? similarCarsFilters.Preis[1] : undefined;
        delete similarCarsFilters.Preis;
    }
    if (similarCarsFilters.Rate) {
        similarCarsFilters.rateVon = !checkUndefinedOrNull(similarCarsFilters.Rate[0]) ? similarCarsFilters.Rate[0] : undefined;
        similarCarsFilters.rateBis = !checkUndefinedOrNull(similarCarsFilters.Rate[1]) ? similarCarsFilters.Rate[1] : undefined;
        if (!checkUndefinedOrNull(similarCarsFilters.fiz)) {
            similarCarsFilters.fiz = 1;
        }
        delete similarCarsFilters.Rate;
    }
    if (similarCarsFilters.Standort) {
        similarCarsFilters.Standort = similarCarsFilters.Standort.join(',');
    }
    if (similarCarsFilters.ignoreFirma) {
        similarCarsFilters.ignoreFirma = similarCarsFilters.ignoreFirma.join(',');
    }

    similarCarsFilters.lang = currentLang?.split('-')?.[0] || 'de';

    if (similarCarsFilters.page) {
        similarCarsFilters['page.page'] = similarCarsFilters.page;
        similarCarsFilters['page.size'] = similarCarsFilters.pageSize;
        delete similarCarsFilters.page;
    }

    for (let i = 0; i < Object.keys(similarCarsFilters).length; i++) {
        const key = Object.keys(similarCarsFilters)[i];
        const value = similarCarsFilters[key];
        if (!checkUndefinedNullOrEmpty(value) && value !== -100 && value !== '-100') {
            sCarsFiltersString.push(key + '=' + value);
        }
    }

    return axios<Car<true>>({
        title: 'Abrufen des Fahrzeugs',
        noLogging: false,
        idFirma,
        method: 'get',
        url: `${urlSRelease}/search${idFirma === 54625 ? `/${idFirma}` : ''}/cara_${idCar}?${sCarsFiltersString?.join('&')}`,
        signal,
        validateStatus: (status) => (status === 200),
    });
}
