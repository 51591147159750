import { TFunction } from 'i18next';
import { FacetsService, Filter } from '../_types/Car';

type MapFacet = {
    facetName: (keyof FacetsService) | 'freier_text',
    filterName: string,
    einheit?: string,
};

function mapFacets(translate: TFunction): Record<(keyof Filter) | 'fiz', MapFacet> {
    return {
        'freier_text': { facetName: 'freier_text', filterName: translate('common:filter.volltext') },
        'hersteller0': { facetName: 'herstellerAll', filterName: translate('common:filter.hersteller') },
        'modell0': { facetName: 'modellAll', filterName: translate('common:filter.modell') },
        'akttypList': { facetName: 'bereichAll', filterName: translate('common:filter.typ') },
        'MotorG': { facetName: 'MotorGAll', filterName: translate('common:filter.motor') },
        'motoren': { facetName: 'MotorGAll', filterName: translate('common:filter.motor') },
        'Getriebe': { facetName: 'getriebeAll', filterName: translate('common:filter.getriebe') },
        'idFirma': { facetName: 'firmenAll', filterName: translate('common:filter.standort') },
        'Standort': { facetName: 'standortAll', filterName: translate('common:filter.standort') },
        'aufbau': { facetName: 'aufbauAll', filterName: translate('common:filter.aufbau') },
        'aufbauten': { facetName: 'aufbauten', filterName: translate('common:filter.aufbauten') },
        'siegel': { facetName: 'siegelAll', filterName: translate('common:filter.siegel') },
        'Farben': { facetName: 'farbenAll', filterName: translate('common:filter.farbe') },
        'Preis': { facetName: 'preisAll', filterName: translate('common:filter.preis'), einheit: '€' },
        'PreisVon': { facetName: 'preisAll', filterName: null },
        'PreisBis': { facetName: 'preisAll', filterName: null },
        'Rate': { facetName: 'rateAll', filterName: translate('common:filter.rate'), einheit: '€' },
        'RateVon': { facetName: 'rateAll', filterName: null },
        'RateBis': { facetName: 'rateAll', filterName: null },
        'EZ': { facetName: 'ezAll', filterName: translate('common:filter.ez') },
        'EZVon': { facetName: 'ezAll', filterName: null },
        'EZBis': { facetName: 'ezAll', filterName: null },
        'kategorie': { facetName: 'kategorie', filterName: translate('common:filter.kategorie') },
        'KM': { facetName: 'kmAll', filterName: translate('common:filter.km'), einheit: 'km' },
        'KMVon': { facetName: 'kmAll', filterName: null },
        'KMBis': { facetName: 'kmAll', filterName: null },
        'Leistung': { facetName: 'LeistungAll', filterName: translate('common:filter.leistung'), einheit: 'kW' },
        'LeistungVon': { facetName: 'LeistungAll', filterName: null },
        'LeistungBis': { facetName: 'LeistungAll', filterName: null },
        'sofort': { facetName: null, filterName: translate('common:filter.sofort') },
        'aktion': { facetName: null, filterName: translate('common:filter.aktion') },
        'ia': { facetName: null, filterName: null },
        'fiz': { facetName: null, filterName: null },
        'sort': { facetName: null, filterName: null },
        'order': { facetName: null, filterName: null },
        'page': { facetName: null, filterName: null },
        'pageSize': { facetName: null, filterName: null },
        'ignoreFirma': { facetName: null, filterName: null },
        'ignoreStandort': { facetName: null, filterName: null },
        'StandortQuelle': { facetName: null, filterName: null },
        'nfzHersteller': { facetName: null, filterName: null },
        'NFZ': { facetName: null, filterName: null },
        'tags': { facetName: 'tagsAll', filterName: translate('common:filter.tags') },
        'fd': { facetName: 'fdAll', filterName: translate('common:filter.fd') },
    };
}

export default mapFacets;
