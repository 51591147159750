import { InputAdornment, ListSubheader, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectOption } from '../../_types/Car';
import { checkUndefinedNullOrEmpty, checkUndefinedOrNull } from '../../utils/tools';
import Iconify from '../iconify/component';
import { IconifyProps } from '../iconify/types';

export type RHFSelectProps = FormControlProps & {
    disabled?: boolean,
    helperText?: React.ReactNode;
    icon?: IconifyProps,
    label?: string;
    manipulateValueBeforeSend?: (origVal: any, newValueOrig: any, options: SelectOption[]) => any,
    multiple?: boolean,
    name: string;
    options: SelectOption[];
    PaperPropsSx?: SxProps<Theme>;
    placeholder?: string;
    valueField?: string;
};

export function RHFSelect({
    helperText,
    icon,
    label,
    manipulateValueBeforeSend,
    multiple,
    name,
    options,
    placeholder,
    valueField,
    ...other
}: RHFSelectProps) {
    const { control } = useFormContext();

    const resortOptionsByGroup = (opts: SelectOption[]) => {
        const newOptions = { noCategory: [] };
        if (!checkUndefinedNullOrEmpty(opts)) {
            opts.map((option) => {
                if (!checkUndefinedNullOrEmpty(option.group)) {
                    if (checkUndefinedOrNull(newOptions[option.group])) {
                        newOptions[option.group] = [];
                    }
                    newOptions[option.group].push(option);
                } else {
                    if (checkUndefinedOrNull(newOptions.noCategory)) {
                        newOptions.noCategory = [];
                    }
                    newOptions.noCategory.push(option);
                }
            });
        }
        return newOptions;
    };

    const newOptions = useMemo(() => resortOptionsByGroup(options), [options]);

    const renderValues = (rawSelectedIds: (string | number)[]) => {
        const selectedIds = Array.isArray(rawSelectedIds) ? rawSelectedIds : [rawSelectedIds];
        const selectedItems = options?.filter((item) => selectedIds?.includes(item?.i));

        if (!selectedItems?.length && placeholder) {
            return <Chip
                color="primary"
                data-testid={`RHFSelect_${name}_selected_placeholder`}
                key={`RHFSelect_${name}_selected_placeholder`}
                label={placeholder}
                size="small"
                variant="soft"
            />;
        }

        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selectedItems?.map((item) => (
                    <Chip
                        key={`RHFSelect_${name}_selected_${item.i}`}
                        color="primary"
                        data-testid={`RHFSelect_${name}_selected_${item.i}`}
                        /* onDelete={() => {
                            const newValue = selectedIds?.filter((id) => id !== item.i);
                            setValue(name, newValue, { shouldValidate: true });
                        }} */
                        label={item.t}
                        size="small"
                        variant="soft"
                    />
                ))}
            </Box>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} fullWidth {...other}>
                    {label && <InputLabel
                        id={name}
                        shrink
                    >
                        {label}
                    </InputLabel>}
                    <Select
                        {...field}
                        fullWidth
                        multiple={multiple}
                        displayEmpty={!!placeholder}
                        id={`RHFSelect_${name}`}
                        startAdornment={!checkUndefinedNullOrEmpty(icon) ? <InputAdornment position="start"><Iconify icon={icon} /></InputAdornment> : undefined}
                        labelId={name}
                        label={label}
                        renderValue={renderValues}
                        value={multiple
                            ? (!checkUndefinedNullOrEmpty(field?.value) ? field?.value : [])
                            : (!checkUndefinedNullOrEmpty(field?.value) ? (valueField ? field?.value[valueField] : field?.value) : '')
                        }
                        onChange={(e, c) => {
                            const newValue = !checkUndefinedOrNull(manipulateValueBeforeSend) ? manipulateValueBeforeSend(field?.value, c, options) : e?.target?.value;
                            field?.onChange({ target: { value: newValue } });
                        }}
                    >
                        {Object.keys(newOptions).map((key) => {
                            if (key !== 'noCategory' && !checkUndefinedNullOrEmpty(newOptions[key])) {
                                return [
                                    <ListSubheader key={`RHFSelect_${name}_optionGroup_${key}`}>
                                        {key}
                                    </ListSubheader>,
                                    ...newOptions[key].map((o) => {
                                        const selected = multiple ? field?.value?.includes(o?.i) : field?.value === o?.i;
                                        return (<MenuItem key={`RHFSelect_${name}_option__${key}_${o?.i}`} value={o?.i}>
                                            {multiple && <Checkbox size="small" disableRipple checked={selected} />}
                                            {o?.t} {o?.et || ''}
                                        </MenuItem>);
                                    }),
                                ];
                            } else {
                                return newOptions[key].map((o) => {
                                    const selected = multiple ? field?.value?.includes(o?.i) : field?.value === o?.i;
                                    return (<MenuItem key={`RHFSelect_${name}_option_${key}_${o?.i}`} value={o?.i}>
                                        {multiple && <Checkbox size="small" disableRipple checked={selected} />}
                                        {o?.t} {o?.et || ''}
                                    </MenuItem>);
                                });
                            }
                        })}
                    </Select>

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}
