/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configSpranger: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10210,
        enable: true,
        projectName: 'Autohaus Spranger',
        searchPath: '/fahrzeuge.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 11,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: 'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'svg': {
                        minHeight: '16px !important',
                        minWidth: '16px !important',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"] p.MuiTypography-body1': { // Car details grid text
                                fontWeight: 500,
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': { // Car details grid text
                                fontWeight: 500,
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="AusstattungLocationInfo_Card"] div.MuiAccordionSummary-expandIconWrapper > svg': {
                                background: 'transparent !important',
                                padding: '0 !important',
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 500,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 500,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 500,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 500,
                },
            },
        }),
        primaryColor: '#055eae',
    },
};

export default configSpranger;
