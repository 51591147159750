/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configKuever: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54793,
        enable: true,
        metaTitleSeperator: ' - ',
        metaTitleSuffix: 'Autohaus Küver GmbH',
        projectName: 'Autohaus Küver',
        searchPath: '/fahrzeuge',
        useOldRouter: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetX: 7,
            offsetY: 18,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            ...configDefault.fahrzeugsuche.filter,
            sidebar: ['tags', 'sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags', '[SM]hersteller', '[LG]modell', '[LG]typ', 'sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '-apple-system, "SF Pro Text", system-ui, "helvetica neue", "calibri light", Roboto, sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '100px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '100px',
                            'padding': '7.65px 30.6px',
                            'transition': 'box-shadow .4s,background-color .3s',
                            '&:hover': {
                                boxShadow: '0 0 0 4px rgba(11, 113, 210, 0.3), 0 0 0 16px rgba(11, 113, 210, 0)',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'borderRadius': '100px',
                            'padding': '7.65px 30.6px',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '100px',
                            'padding': '7.65px 30.6px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'padding': '6px 8px',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#ebf4fc',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'transition': 'box-shadow .4s,background-color .3s',
                            '&:hover': {
                                boxShadow: '0 0 0 4px rgba(11, 113, 210, 0.3), 0 0 0 16px rgba(11, 113, 210, 0)',
                            },
                        },
                    },
                },
            },
        }),
        primaryColor: '#0b71d2',
    },
};

export default configKuever;
