/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configEichsfelder: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54170,
        enable: true,
        projectName: 'Eichsfelder Autohaus',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        tradeInLink: 'https://eichsfelder-autohaus.de/gib-uns-deinen-gebrauchten',
    },
    style: {
        ...configDefault.style,
        fontFamily: '"vwtextweb-regular"',
        muiComponents: () => ({
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1.03em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#2CCCE4',
    },
};

export default configEichsfelder;
