import { Theme } from "@mui/material";
import { ConfigObject } from "../../_configs/types";
import checkUndefinedNullOrEmpty from "./checkUndefinedNullOrEmpty";

export default function replaceStyleTexts<T>(obj: T, variables: {
    colorPrimary: ConfigObject['style']['primaryColor'],
    colorSecondary: ConfigObject['style']['secondaryColor'],
    fontFamily: ConfigObject['style']['fontFamily'],
    fontSize: ConfigObject['style']['fontSize'],
    textPrimary: Theme['palette']['text']['primary'],
}): T {
    if (checkUndefinedNullOrEmpty(obj)) {
        return obj;
    }

    const newObj = Object.assign({}, obj);
    const keys = Object.keys(newObj);
    keys.map(function (key) {
        const value = newObj[key];
        if (typeof value === 'object') {
            newObj[key] = Object.assign({}, replaceStyleTexts(value, variables));
        } else if (typeof value === 'string') {
            if (value.includes('<fontSize>')) {
                newObj[key] = variables.fontSize;
            }
            newObj[key] = value
                .replace('<colorPrimary>', variables.colorPrimary)
                .replace('<colorSecondary>', variables.colorSecondary)
                .replace('<textPrimary>', variables.textPrimary)
                .replace('<fontFamily>', variables.fontFamily);
        } else {
            newObj[key] = value;
        }
    });

    return (newObj as T);
}
