/**
 * @file Handles atyle configuration for the showroooom-projects.
 * @author Phillip Kühn
 */

import { DeepPartial } from 'react-hook-form';
import { breakpoints } from '../theme/breakpoints';
import { ConfigObject } from './types';

const configShowRoooom: DeepPartial<ConfigObject> = {
    module: {
        aktionsslider: true,
        erweiterteSchnellsuche: true,
        fahrzeugliste: true,
        fahrzeugslider: true,
        fahrzeugsuche: true,
        finanzierungsansicht: true,
        schnellsuche: true,
        standorte: true,
    },
    contact: {
        linkDisclaimer: '/datenschutz',
    },
    fahrzeugsuche: {
        hideFooterAutohausDigitalLink: true,
        hideHeading: true,
    },
    schnellsuche: {
        noBorderPadding: true,
    },
    style: {
        borderRadius: 2,
        fontSize: 15,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'button#cookiebanner_button_speedDial': {
                            bottom: '64px !important',
                        },
                    },
                },
            },
        }),
    },
};

export default configShowRoooom;
