/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { FilterOrder, FilterSort } from '../../_types/Car';

const configNextCamperMietenKaufen: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 10200,
        enable: true,
        metaTitleSuffix: 'Mieten Kaufen Reisen',
        projectName: 'Mieten Kaufen Reisen',
        searchPath: '/kaufen.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    cartile: {
        ...configDefault.cartile,
        badges: ['availableAt'],
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: 'https://www.vw-dresden-reick.de/de/rechtliches/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.ASC, sort: FilterSort.VerfugbarAb },
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            kategorie: 51,
        },
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 3,
        fontFamily: '"VWTextRegular", "Open Sans", "Arial", sans-serif',
        fontSize: 15,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    containedPrimary: {
                        [getStyleQuery()]: {
                            color: '#fff !important',
                        },
                    },
                    textPrimary: {
                        [getStyleQuery()]: {
                            color: '<colorPrimary>' + ' !important',
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            boxShadow: 'none !important',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    letterSpacing: '0.02857em !important',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    letterSpacing: '0.03333em !important',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"VWHeadBold", "Open Sans", "Arial", sans-serif',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"VWHeadBold", "Open Sans", "Arial", sans-serif',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"VWHeadBold", "Open Sans", "Arial", sans-serif',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"VWHeadBold", "Open Sans", "Arial", sans-serif',
                },
            },
            overline: {
                [getStyleQuery()]: {
                    letterSpacing: '0.08333em !important',
                },
            },
        }),
        primaryColor: '#0099da',
    },
};

export default configNextCamperMietenKaufen;
