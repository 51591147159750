import { TranslationsCustomer } from './translationsCustomer';

const frFR: TranslationsCustomer = {
    54440: {
        additionalInfo: {
            0: 'Min. 1 an de garantie valable dans le réseau constructeur en Europe',
            1: '14 jours garantie satisfait ou remboursé',
            2: 'Zéro frais d’entretien pendant min. 1 ou 10.000 km',
        },
    },
};

export default frFR;
