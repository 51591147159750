/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configFeicht: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 50860,
        enable: true,
        projectName: 'Autohaus Feicht',
        searchPath: '/neu-und-gebrauchtwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
        tradeInLink: 'https://www.feicht.de/leistungen',
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Branding-Semilight", "Arial", sans-serif',
        fontSize: 15,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'html, body': {
                        boxSizing: 'unset !important',
                        scrollBehavior: 'smooth',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            maxWidth: 'fit-content',
                            padding: 0,
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            border: '2px solid <colorPrimary>',
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'border': '2px solid rgba(2, 40, 57, 0.5)',
                            '&:hover': {
                                'border': '2px solid 1px solid <colorPrimary>',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': '#fff',
                            'border': '2px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            border: '2px solid #fff',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        [getStyleQuery()]: {
                            '& > legend > span': {
                                fontSize: '1em !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"Branding-Bold", "Arial", sans-serif',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Branding-Bold", "Arial", sans-serif',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Branding-Bold", "Arial", sans-serif',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Branding-Bold", "Arial", sans-serif',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Branding-Bold", "Arial", sans-serif',
                    opacity: 1,
                },
            },
        }),
        primaryColor: '#022839',
    },
};

export default configFeicht;
