/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configRange: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54360,
        enable: true,
        metaTitleSuffix: 'Autohaus Range',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Range',
        searchPath: '/fahrzeuge',
        useOldRouter: true, // Angular problem
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        topFilter: true,
        tradeInLink: 'https://www.autohaus-range.de/inzahlungnahme-ankauf',
        useEyecatcher: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        scrollOffset: 150,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary>',
                            'color': '#fff',
                            'fontSize': '16px !important',
                            'fontWeight': 600,
                            'lineHeight': '1.5',
                            '&:hover': {
                                background: '#3e65af',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '16px',
                            'lineHeight': '1.5',
                            'padding': '.5rem 1.125rem !important',
                            '&:hover': {
                                backgroundColor: '#3e65af !important',
                            },
                            '&[id^="useContact_locationUserButton_"]': { // "Ansprechpartner" contact buttons need to fit parent card
                                borderRadius: 1,
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'borderRadius': '16px',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '16px',
                            'lineHeight': '1.5',
                            'padding': '.5rem 1.125rem !important',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'fontSize': '14px !important',
                                'padding': '6px 16px !important',
                            },
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    [getStyleQuery()]: {
                        '.UseContact_ContactTextButtonContainer': {
                            'borderRadius': '16px !important',
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderBottomWidth: '1px !important',
                            borderBottomColor: 'rgba(145, 158, 171, 0.7) !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '#3e65af !important',
                            },
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    adornedEnd: {
                        [getStyleQuery()]: {
                            backgroundColor: '#fff',
                        },
                    },
                    adornedStart: {
                        [getStyleQuery()]: {
                            backgroundColor: '#fff',
                        },
                    },
                    input: {
                        [getStyleQuery()]: {
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '#fff !important', // important necessary, otherwise turn white on hover
                            '&[data-testid="CalcFdSummary_Card"]': {
                                backgroundColor: '#5d79ae !important',
                            },
                            '& .MuiTypography-h4': { // Preis & Finanzierung
                                color: '<colorPrimary> !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#333333',
                    lineHeight: '1.4rem',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontWeight: 600,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    background: 'transparent',
                    color: '#333333',
                    fontWeight: 600,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    background: 'transparent',
                    color: '#333333',
                    fontWeight: 600,
                    padding: 0,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#333333',
                    fontWeight: 600,
                },
            },
        }),
        primaryColor: '#35589a',
    },
};

export default configRange;
