import axiosInstance from '.';
import { Car } from '../../_types/Car';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { FaarenCarPricePlan, FaarenCarPricePlanList, FaarenListCar } from '../types/faaren';
import { faarenPriceListMock } from './faarenPriceList';

export const faarenVehicleListMock: (Omit<FaarenListCar, 'price_plans'> & { price_plans: FaarenCarPricePlan[] })[] = [
    {
        uuid: 'vehic_trJ5QZzJvXL7klD5AIVDaSAJQ9',
        id: 54894,
        external_id: '114',
        description: 'L1e 45 km/h',
        title: 'DREEMS Amalfi L1e 45 km/h',
        slug: 'dreems-amalfi-l1e-45-kmh-54894',
        listing_price: '3290.00',
        power_kw: 3,
        brand: 'DREEMS',
        model: 'Amalfi',
        fuel: 'Strom / Elektro',
        category: 'Roller/Scooter',
        gearshift: 'Automatik',
        address: {
            id: 1170,
            title: null,
            name: 'Autohaus Thomas Rudolph GmbH',
            contact_person: null,
            company: null,
            user_id: null,
            producer_id: 41,
            subsidiary_id: 49,
            branch_email: 'vermietung@vw-rudolph.com',
            street: 'Turleyring',
            house_number: '6',
            additional: null,
            city: 'Oelsnitz/Erzgebirge',
            postal_code: '09376',
            country: 'de',
            longitude: 12.719556,
            latitude: 50.722951,
            created_at: new Date('2020-11-25 17:12:45'),
            updated_at: new Date('2022-04-08 08:48:31'),
            is_mainbranch: null,
            deleted_at: null,
            region: null,
            anonymized_at: null,
            uuid: 'addre_mEazrxgsskVxBdsO8hYLJno0Wl',
            user_uuid: null,
            company_uuid: null,
        },
        euro_norm: 'UNBEKANNT',
        co2_emission: 0,
        consumption: 0,
        efficiency_class: null,
        title_image: {
            id: 774913,
            url: 'https://faaren.com/image-proxy/core/vehicles/production/49/54894/774913/114_1.png',
            title: '114_1.png',
            mime_type: 'image/png',
            size: 162285,
        },
        additional_mileage_cost: 75,
        minimum_age: '15 Jahre',
        min_license_duration: 0,
        damage_participation: '100,00 €',
        vehicle_identification_number: null,
        current_miles: '0 Km',
        first_registration: null,
        vehicle_condition: 'vehicle_new',
        exterior_color: 'Rot (red & white )',
        immediately_available: 1,
        fixed_duration: 0,
        status: 'VEHICLE_ACTIVE',
        price_plans: faarenPriceListMock,
        delivery_time_after_booking: 2,
        delivery_option: 'partner_delivery',
        pick_up_option: 'partner_delivery',
        whitelabel_link: 'dreems-amalfi-l1e-45-kmh-54894/vehic_trJ5QZzJvXL7klD5AIVDaSAJQ9?SID=subsi_Uyt2G6KxuP0RNAScyYOX4vQpIy&VID=54894',
    },
];

const faarenBaseUrl = 'https://faaren.com/services/api';

export const getFaarenPriceList = async (faarenPricePlansUrl: FaarenListCar['uuid'], faarenApiKey: string, signal?: AbortController['signal']): Promise<FaarenCarPricePlanList> => {
    const vehiclePricePlans = (await axiosInstance<{ data: FaarenCarPricePlanList[] }>({
        method: 'get',
        url: faarenPricePlansUrl,
        headers: { Authorization: `Bearer ${faarenApiKey}` },
        signal,
    }))?.data?.data?.[0];

    return vehiclePricePlans;
};

export const getFaarenVehicles = async (faarenApiKey: string, carIdent?: Car<false>['ident']): Promise<FaarenListCar[]> => {
    const vehicleList = (await axiosInstance<{ data: FaarenListCar[] }>({
        method: 'get',
        url: `${faarenBaseUrl}/vehicles?status=VEHICLE_ACTIVE&per_page=100000${!checkUndefinedNullOrEmpty(carIdent) ? `&external_id=${carIdent}` : ''}`,
        headers: { Authorization: `Bearer ${faarenApiKey}` },
    }))?.data?.data;

    return vehicleList;
};
