import { NavigateFunction } from "react-router-dom";
import checkUndefinedOrNull from "./checkUndefinedOrNull";

export default function enhancedNavigate(url: string, navigate?: NavigateFunction): void {
    if (!checkUndefinedOrNull(navigate)) {
        navigate(url, { preventScrollReset: true });
        if (typeof window?._paq !== 'undefined') {
            window?._paq?.push(['setCustomUrl', url]);
            window?._paq?.push(['trackPageView']);
        }
    } else {
        location.href = url;
    }
}
