export enum DBLogLevel {
    Error = 3,
    Info = 0,
    Success = 1,
    Warning = 2,
}

export enum DBLogSource {
    HPT = 2,
    SHOWROOOOM = 1,
    UNKNOWN = 0,
}

export type DBLog = {
    /**
     * @name Created At.
     * @description Describes the creation date.
     * @example 2023-07-21T09:08:27.636Z
    */
    createdAt: Date;
    /**
     * @name ID.
     * @description Describes the ID.
     * @example 1
    */
    id: number;
    /**
     * @name Project ID.
     * @description Describes the assigned project IDs.
     * @example 13
    */
    idProject: number;
    /**
     * @name Level.
     * @description Describes the severity and design of the message.
     * @example "info"
    */
    level: DBLogLevel;
    /**
     * @name Message.
     * @description Contains the message of the global message.
     * @example "Hello World!"
    */
    message: string;
    /**
     * @name Source.
     * @description Describes the origin.
     * @example 'sr'
    */
    origin: DBLogSource;
    /**
     * @name RawData.
     * @description Describes the raw data.
     * @example {}
    */
    raw: unknown;
    /**
     * @name Title.
     * @description Contains the title of the global message.
     * @example "Hello World!"
    */
    title: string;
    /**
     * @name Updated At.
     * @description Describes the last update date.
     * @example 2023-07-21T09:08:27.636Z
    */
    updatedAt: Date;
};

export type DBLogCreate = Omit<DBLog, 'createdAt' | 'id' | 'updatedAt'>;
