/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configWinter: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 53810,
        enable: true,
        projectName: 'Autohaus Winter',
        searchPath: '/neu-und-gebrauchtwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['tags', 'sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]tags', '[SM]hersteller', '[LG]modell', '[LG]typ', 'sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"FordAntenna", "Arial", sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '30px !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            borderRadius: '30px',
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '30px',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            borderRadius: '30px',
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            borderRadius: '30px',
                        },
                    },
                },
            },
        }),
        primaryColor: '#ca0033',
        secondaryColor: '#697985',
    },
};
export default configWinter;
