/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configZeilinger: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        enable: true,
        idFirma: 54625,
        projectName: 'Auto Zeilinger',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'ez', 'aus'],
        },
        tradeInLink: 'https://auto-zeilinger.de/fahrzeugankauf',
    },
    install: {
        ...configDefault.install,
        showMagicIdSetup: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 2,
        fontFamily: '"vwtextweb-regular"',
        fontSize: 16,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#992822',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h1: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h2: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vwheadweb-bold"',
                    fontWeight: 900,
                },
            },
        }),
        primaryColor: '#ab2d26',
    },
};

export default configZeilinger;
