/**
 * @file Handles all configurations for the homepage tools.
 * @author Daniel Rittrich
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configThomasHeavyMetal: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30177,
        isGruppe: true,
        enable: true,
        metaTitlePrefix: 'Harley-Davidson Sachsen',
        projectName: 'Harley-Davidson Sachsen',
        searchPath: '/unserebikes',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/privacy-policy',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'aufbau', 'standort', 'typ', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]typ', '[LG]sofort', '[LG]motor', 'getriebe', 'aufbau', 'standort', 'preis', 'rate', 'ez', 'aus'],
        },
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            ignoreFirma: [53410],
        },
        titleWithDesign: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Franklin Gothic ATF", "NotoSans-Black", arial, sans-serif',
        fontSize: 15,
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 700,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '<fontFamily>',
                    fontWeight: 400,
                },
            },
        }),
        primaryColor: '#e95d0f',
    },
};

export default configThomasHeavyMetal;
