export enum Modules {
    Aktionsslider = 'aktionsslider',
    ConfigListing = 'configListing',
    Contact = 'contact',
    ErweiterteSchnellsuche = 'erweiterteSchnellsuche',
    Fahrzeugliste = 'fahrzeugliste',
    Fahrzeugslider = 'fahrzeugslider',
    Fahrzeugsuche = 'fahrzeugsuche',
    Finanzierungsansicht = 'finanzierungsansicht',
    Install = 'install',
    Menu = 'menu',
    Print = 'print',
    Schnellsuche = 'schnellsuche',
    Standorte = 'standorte',
}

export enum SourceList {
    Aktionsslider = 2,
    Contact = 8,
    ErweiterteSchnellsuche = 3,
    Fahrzeugliste = 7,
    Fahrzeugslider = 4,
    Fahrzeugsuche = 0,
    Finanzierungsansicht = 5,
    Schnellsuche = 6,
    SimilarList = 1,
    Standorte = 9,
}

const modules = [
    { i: Modules.Aktionsslider, t: 'ahdAktion', path: '/aktionsslider', title: 'Aktionsslider', vehicleList: SourceList?.Aktionsslider, noInstall: false },
    { i: Modules.ErweiterteSchnellsuche, t: 'ahdErweiterteSchnellsuche', path: '/erweiterteSchnellsuche', title: 'Erweiterte Schnellsuche', vehicleList: SourceList?.ErweiterteSchnellsuche, noInstall: false },
    { i: Modules.Fahrzeugliste, t: 'ahdFahrzeugliste', path: '/fahrzeugliste', title: 'Fahrzeugliste', vehicleList: SourceList?.Fahrzeugliste, noInstall: false },
    { i: Modules.Fahrzeugslider, t: 'ahdFahrzeugslider', path: '/fahrzeugslider', title: 'Fahrzeugslider', vehicleList: SourceList?.Fahrzeugslider, noInstall: false },
    { i: Modules.Fahrzeugsuche, t: 'ahdFahrzeugsuche', path: '/fahrzeugsuche', title: 'Fahrzeugsuche', vehicleList: SourceList?.Fahrzeugsuche, noInstall: false },
    { i: Modules.Finanzierungsansicht, t: 'ahdFinanzierung', path: '/finanzierungsansicht', title: 'Finanzierung', vehicleList: SourceList?.Finanzierungsansicht, noInstall: false },
    { i: Modules.Schnellsuche, t: 'ahdSchnellsuche', path: '/schnellsuche', title: 'Schnellsuche', vehicleList: SourceList?.Schnellsuche, noInstall: false },
    { i: Modules.Standorte, t: 'ahdStandorte', path: '/standorte', title: 'Standorte', vehicleList: SourceList?.Standorte, noInstall: false },

    // BACKGROUND STUFF
    { i: Modules.ConfigListing, t: 'ahdConfigListing', noInstall: true },
    { i: Modules.Contact, t: 'ahdcontact', title: 'Kontaktformular', vehicleList: SourceList?.Contact, noInstall: true },
    { i: Modules.Install, t: 'ahdInstallDocumentationSite', noInstall: true },
    { i: Modules.Menu, t: 'ahdMenu', noInstall: true },
    { i: Modules.Print, t: 'ahdPrint', noInstall: true },
];

export default modules;
