import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box, Skeleton, SxProps, Theme, useTheme } from '@mui/material';
import React, { CSSProperties, MouseEventHandler, useEffect, useRef, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { availableImageSizes, checkUndefinedNullOrEmpty, checkUndefinedOrNull, imageLoader } from '../../utils/tools';

export type ImageProps = {
    alt?: string;
    className?: string;
    contain?: boolean;
    disableLazyload?: boolean;
    disablePlaceholder?: boolean;
    externalImage?: boolean;
    fullscreenImage?: boolean;
    height?: string;
    loading?: boolean,
    objectPosition?: CSSProperties['objectPosition'];
    onClick?: MouseEventHandler;
    onMouseDown?: MouseEventHandler;
    onMouseUp?: MouseEventHandler;
    ratio?: [number, number];
    src?: string;
    sx?: SxProps<Theme>;
    testid?: string;
};

function ImageElement(props: ImageProps) {
    const { alt, className, contain, disableLazyload, disablePlaceholder, externalImage, fullscreenImage, height, loading, objectPosition, onClick, onMouseDown, onMouseUp, ratio, src, sx, testid } = props;
    const theme = useTheme();
    const [widthToUse, setWidthToUse] = useState<number>(undefined);
    const container = useRef<HTMLImageElement>(null);

    useEffect(() => {
        setWidthToUse(container?.current?.getBoundingClientRect()?.width * (typeof window !== 'undefined' ? window.devicePixelRatio : 1));
    }, [container]);

    if (!checkUndefinedNullOrEmpty(ratio) && !fullscreenImage) {
        return (
            <Box
                ref={container}
                className={className}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                component="span"
                sx={{
                    'background': disablePlaceholder ? 'transparent' : theme.palette.background.default,
                    'width': '100%',
                    'lineHeight': 0,
                    'display': 'block',
                    'overflow': 'hidden',
                    'position': 'relative',
                    'pt': height || 'calc(100% / ' + ratio[0] + ' * ' + ratio[1] + ')',
                    '& .ahdImageWrapper': {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        lineHeight: 0,
                        position: 'absolute',
                        backgroundSize: 'cover !important',
                        display: 'flex !important',
                    },
                    '& .ahdImageWrapper.lazy-load-image-background.blur.lazy-load-image-loaded.down': {
                        paddingTop: 0,
                    },
                    'cursor': !checkUndefinedOrNull(onClick) || (!checkUndefinedOrNull(onMouseDown) && !checkUndefinedOrNull(onMouseUp)) ? 'pointer' : undefined,
                    'borderRadius': 1,
                    ...sx,
                }}
            >
                {loading
                    ? <Box className="ahdImageWrapper" sx={{
                        width: '100% !important',
                        height: height || '100% !important',
                        objectFit: contain ? 'contain' : 'cover',
                        objectPosition: objectPosition,
                    }}>
                        <Skeleton sx={{ width: '100%', height: '100%' }} />
                    </Box>
                    : (fullscreenImage || !checkUndefinedNullOrEmpty(widthToUse)) && <Box
                        {...(disableLazyload ? {
                            className: 'ahdImageWrapper',
                            component: 'img',
                        } : {
                            component: LazyLoadImage,
                            effect: 'blur',
                            placeholderSrc: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                            wrapperClassName: 'ahdImageWrapper',
                        })}
                        data-testid={testid}
                        {...(externalImage ? {
                            alt: alt,
                            src: src,
                        } : {
                            alt: alt,
                            sizes: fullscreenImage ? '100vw' : (widthToUse + 'px'),
                            src: imageLoader({ src }),
                            srcSet: availableImageSizes.map((width) => (imageLoader({ src, width }) + ' ' + width + 'w')).join(', '),
                        })}
                        sx={{
                            width: '100% !important',
                            height: height || '100% !important',
                            objectFit: contain ? 'contain' : 'cover',
                            objectPosition: objectPosition,
                        }}
                    />}
            </Box>
        );
    }

    return (
        <Box
            ref={container}
            className={className}
            component="span"
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            sx={{
                'backgroundColor': !disablePlaceholder && !disableLazyload ? 'background.paper' : undefined,
                'lineHeight': 'auto',
                'display': 'block',
                'overflow': 'hidden',
                '& .ahdImageWrapper': {
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover !important',
                    display: 'flex !important',
                },
                'cursor': !checkUndefinedOrNull(onClick) || (!checkUndefinedOrNull(onMouseDown) && !checkUndefinedOrNull(onMouseUp)) ? 'pointer' : undefined,
                'borderRadius': 1,
                ...sx,
            }}
        >
            {loading
                ? <Box className="ahdImageWrapper" sx={{
                    width: '100% !important',
                    height: height || '100% !important',
                    objectFit: contain ? 'contain' : 'cover',
                    objectPosition: objectPosition,
                }}>
                    <Skeleton sx={{ width: '100%', height: '100%' }} />
                </Box>
                : (fullscreenImage || !checkUndefinedNullOrEmpty(widthToUse)) && <Box
                    {...(disableLazyload ? {
                        className: 'ahdImageWrapper',
                        component: 'img',
                    } : {
                        component: LazyLoadImage,
                        effect: 'blur',
                        placeholderSrc: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                        wrapperClassName: 'ahdImageWrapper',
                    })}
                    data-testid={testid}
                    {...(externalImage ? {
                        alt: alt,
                        src: src,
                    } : {
                        alt: alt,
                        sizes: fullscreenImage ? '100vw' : (widthToUse + 'px'),
                        src: imageLoader({ src }),
                        srcSet: availableImageSizes.map((width) => (imageLoader({ src, width }) + ' ' + width + 'w')).join(', '),
                    })}
                    sx={{
                        width: '100% !important',
                        height: height || '100% !important',
                        objectFit: contain ? 'contain' : 'cover',
                        objectPosition: objectPosition,
                    }}
                />}
        </Box>
    );
}

export default React.memo(ImageElement);
