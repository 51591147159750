export const breakpoints = {
    xs: 0,
    sm: 600, // 640, // 600,
    md: 835, // 955, // 960,
    lg: 1100, // 1260, // 1280,
    xl: 1520,
};

export const device = {
    xs: `(min-width: ${breakpoints.xs}px)`,
    sm: `(min-width: ${breakpoints.sm}px)`,
    md: `(min-width: ${breakpoints.md}px)`,
    lg: `(min-width: ${breakpoints.lg}px)`,
    xl: `(min-width: ${breakpoints.xl}px)`,
};
