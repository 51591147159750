/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configGroeditzer: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 13540,
        enable: true,
        metaTitleSuffix: 'Gröditzer Autohaus KG',
        projectName: 'Gröditzer Autohaus',
        searchPath: '/unsere-fahrzeuge',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 6,
            offsetY: 16,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Poppins", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            'padding': '10px 20px',
                            '&:focus': {
                                outline: 'none !important',
                            },
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            padding: '10px 20px',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'padding': '10px 20px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                padding: '6px 8px',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > span.MuiTypography-body2': {
                                fontWeight: 'bold',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"VW", sans-serif',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"VW", sans-serif',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"VW", sans-serif',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"VW", sans-serif',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"VW", sans-serif',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                },
            },
        }),
        primaryColor: '#3186c6',
    },
};

export default configGroeditzer;
