/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { breakpoints } from '../../theme/breakpoints';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configLeoBelting: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 46950,
        enable: true,
        projectName: 'Leo Belting',
        searchPath: '/fahrzeugsuche.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'n2dssyCNAR',
            origin: 'leo-belting.de',
            priceRating: true,
            sourceName: 'Leo-belting.de',
            tradeIn: true,
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Roboto Condensed", "Helvetica Neue", "Arial", sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div[data-testid="BilderSlider_thumbnailContainer"] div.slick-slide': { // fix slick slider thumbnails oversized
                        width: '72px !important',
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                        'div.auws-engagement-widget.auws-left-align': { // Inzahlungnahme widget for mobile view
                            '--bottom-offset': '56px !important',
                        },
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    secondary: {
                        [getStyleQuery()]: {
                            '&[data-testid$="_Title_Secondary"]': { // Car Name
                                fontWeight: 600,
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5, &[data-testid="CarPriceGrid_Container"] p.MuiTypography-h6': {
                                fontWeight: 600,
                            },
                        },
                    },
                },
            },
        }),
        primaryColor: '#e6003b',
    },
};

export default configLeoBelting;
