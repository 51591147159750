/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configWeber: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 49612,
        enable: true,
        metaTitleSuffix: 'Autohaus Weber GmbH',
        projectName: 'Autohaus Weber',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            offsetY: 8,
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutzerklaerung',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"vw-text", "Open Sans", "Arial", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'h2::before': {
                        content: 'none',
                    },
                    'div.UseContact_ContactTextButtonContainer': { // anti-transparency box around contact buttons
                        borderRadius: '5px !important',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'gap': '2px',
                            'marginTop': '2px',
                            '& > svg': {
                                height: '21px',
                                width: '21px',
                            },
                        },
                    },
                    label: {
                        fontSize: '12px',
                        height: '11px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2px',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'borderRadius': '5px',
                            'color': '#fff',
                            'fontWeight': 400,
                            'padding': '12px 30px',
                            '&:hover': {
                                color: '#fff !important',
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            borderRadius: '5px',
                            fontWeight: 400,
                            padding: '12px 30px',
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '5px',
                            'fontWeight': 400,
                            'padding': '12px 30px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                padding: '6px 8px',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            borderRadius: '5px',
                            fontWeight: 'bold !important',
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& > svg': {
                                width: '21px !important',
                                height: '21px !important',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationInfo"] a.MuiLink-root.MuiLink-underlineHover.MuiButton-text.MuiButton-textPrimary:hover': {
                                color: '<colorPrimary> !important',
                                fontWeight: '700 !important',
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                                color: '#212b36 !important',
                                fontWeight: '700 !important',
                            },
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"]:hover': { // fix colored icons on hover
                                color: 'rgb(33, 43, 54) !important',
                            },
                            '&[data-testid="LocationUserCard"] p.MuiTypography-h6': {
                                color: '212b36',
                                fontWeight: 'bold',
                            },
                            '&[data-testid="LocationUserCard"] a.MuiLink-underlineHover:hover': {
                                color: '<colorPrimary> !important',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] p.MuiTypography-h5, &[data-testid="CarPriceGrid_Container"] p.MuiTypography-h6': {
                                color: '#212b36',
                                fontWeight: '700 !important',
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                textDecoration: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body: {
                [getStyleQuery()]: {
                    color: '#565656',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"vw-head", ' + '<fontFamily>',
                    textAlign: 'left',
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"vw-head", ' + '<fontFamily>',
                    textAlign: 'left',
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"vw-head", ' + '<fontFamily>',
                    textAlign: 'left',
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"vw-head", ' + '<fontFamily>',
                    textAlign: 'left',
                    textTransform: 'uppercase',
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#a90000',
        secondaryColor: '#5e5e5e',
    },
};

export default configWeber;
