/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configNiggemeier: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54778,
        enable: true,
        metaTitleSuffix: 'Autohaus Niggemeier',
        metaTitleSeperator: ' • ',
        projectName: 'Autohaus Niggemeier',
        searchPath: '/fahrzeug-angebote',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 2,
            offsetY: 10,
            position: 'right',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        fahrzeugslider: true,
        finanzierungsansicht: true,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        background: '#f5f5f5',
        borderRadius: 2,
        fontFamily: '"Uploaded Font"',
        fontSize: 16,
        scrollOffset: 150,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    // view & sort text buttons on fahrzeugsuche & finanzierung
                    'div.MuiStack-root[data-testid="FahrzeugSucheFilterChips_results"] > h6.MuiTypography-subtitle1, div.MuiStack-root.FahrzeugSucheFilterSort_Container > button.MuiButton-text.MuiButton-textInherit, div#ahdFinanzierung button.MuiButton-text.MuiButton-textInherit': {
                        color: '#333333',
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary>',
                            'fontWeight': 700,
                            '&:hover': {
                                background: '#999',
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            textTransform: 'uppercase',
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            'border': '2px solid <colorPrimary> !important',
                            // 'padding': '0.8em 1.8em !important',
                            'transition': 'background 0.5s, border 0.5s',
                            '&:hover': {
                                background: '#999',
                                border: '2px solid #999 !important',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'border': '2px solid <colorPrimary>',
                            'backgroundColor': '#d8dfe7 !important',
                            'color': '<colorPrimary> !important',
                            // 'padding': '0.8em 1.8em !important',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'border': 'none !important',
                                'fontSize': '14px !important',
                                'height': '30px !important',
                                // 'padding': '13px 8px !important',
                                'textTransform': 'none !important',
                                '& > span': {
                                    height: '30px !important',
                                },
                                '&:hover': {
                                    border: 'none !important',
                                },
                            },
                            '&:hover': {
                                background: '#b2bfcf !important',
                                border: '2px solid <colorPrimary> !important',
                                boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px !important',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            textTransform: 'none',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#333333',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: { // for "Anfrage senden" modal
                        [getStyleQuery()]: {
                            background: 'transparent',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    lineHeight: 1.2,
                },
            },
            caption: {
                [getStyleQuery()]: {
                    color: '#333333',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#333333',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#333333',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    paddingTop: 0,
                },
            },
        }),
        primaryColor: '#002c60',
        secondaryColor: '#1a1a1a',
    },
};
export default configNiggemeier;
