import { ConfigFilter, ConfigObject } from '../_configs/types';
import { standardFilter } from '../_flux/defaults';
import type { Modules } from '../_json/modules';
import { Facets, Filter, NavigateCar } from '../_types/Car';
import { sStrFilter } from '../modules/fahrzeugsuche/filter/filterList';
import { LOCALSERVER } from '../vars';
import { stringToSlug } from './string-to-slug';
import { checkUndefinedNullOrEmpty } from './tools';

type GenerateLinkProps = {
    module: Modules,
    settings: ConfigObject,
} & ({
    car: NavigateCar,
    pathOnly: boolean,
    type: 'car',
} | {
    allFacets: Facets,
    filter: Filter,
    pathOnly: boolean,
    type: 'filter' | 'landingpage',
} | {
    type: 'host',
} | {
    host: string,
    magicId: string,
    pathOnly: boolean,
    type: 'magicId',
});

export function generateLink(props: GenerateLinkProps): string {
    const { module, settings, type } = props;
    if (settings?.general?.debug) {
        console.log(`${module} - generateLink - type`, type);
    }
    const host = `https://${LOCALSERVER && !checkUndefinedNullOrEmpty(settings?.api?.autoUncle?.origin) ? settings?.api?.autoUncle?.origin : location.host}`;
    const searchPath = `${LOCALSERVER && settings?.style?.darkMode === true && !checkUndefinedNullOrEmpty(settings?.general?.searchPathDark) ? settings?.general?.searchPathDark : settings?.general?.searchPath}`;
    const searchPathSearch = `${!checkUndefinedNullOrEmpty(settings?.general?.searchPathSearch) ? `?${settings?.general?.searchPathSearch}&` : ''}`;
    if (settings?.general?.debug) {
        console.log(`${module} - generateLink - host`, host);
        console.log(`${module} - generateLink - searchPath`, searchPath);
        console.log(`${module} - generateLink - searchPathSearch`, searchPathSearch);
    }

    if (type === 'host') {
        return host;
    } else if (type === 'car') {
        const { car, pathOnly } = props;
        if (settings?.general?.debug) {
            console.log(`${module} - generateLink - car`, car);
            console.log(`${module} - generateLink - pathOnly`, pathOnly);
        }

        if (settings?.general?.useOldRouter === true) {
            return `${pathOnly ? '' : host}${searchPath}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : '?'}${[car?.nameSlug || '', `carId${car?.id || ''}`]?.join('_')}`;
        } else {
            return `${pathOnly ? '' : host}${searchPath === '/' ? '' : searchPath}/c/${stringToSlug((car?.name || '-')?.replace(/\//g, '') || '-')}/${car?.id}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : ''}`;
        }
    } else if (type === 'magicId') {
        const { host: hostProp, magicId, pathOnly } = props;
        if (settings?.general?.debug) {
            console.log(`${module} - generateLink - hostProp`, hostProp);
            console.log(`${module} - generateLink - magicId`, magicId);
            console.log(`${module} - generateLink - pathOnly`, pathOnly);
        }

        if (settings?.general?.useOldRouter === true) {
            return `${pathOnly ? '' : `https://${hostProp}`}${searchPath}/magicId/${magicId}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : '?'}`;
        } else {
            return `${pathOnly ? '' : `https://${hostProp}`}${searchPath === '/' ? '' : searchPath}/magicId/${magicId}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : ''}`;
        }
    } else if (type === 'filter' || type === 'landingpage') {
        const { allFacets, filter: selectedFilters, pathOnly } = props;
        if (settings?.general?.debug) {
            console.log(`${module} - generateLink - allFacets`, allFacets);
            console.log(`${module} - generateLink - selectedFilters`, selectedFilters);
            console.log(`${module} - generateLink - pathOnly`, pathOnly);
        }

        const newFilter: Partial<Record<ConfigFilter, string>> = {};
        const filters = sStrFilter(settings?.general?.isGruppe);

        const selectedFiltersKeys = Object.keys(selectedFilters || {}) as (keyof Filter)[];
        const filtersKeys = Object.keys(filters || {}) as ConfigFilter[];

        selectedFiltersKeys?.filter((key) => {
            return (
                (key === 'page' && selectedFilters?.[key] > standardFilter?.page)
                || (key === 'pageSize' && selectedFilters?.[key] > standardFilter?.pageSize)
                || (
                    !['ignoreFirma', 'ignoreStandort', 'nfzHersteller', 'NFZ', 'order', 'page', 'pageSize', 'page.page', 'page.size', 'sort', 'StandortQuelle']?.includes(key)
                    && !checkUndefinedNullOrEmpty(selectedFilters?.[key])
                    && selectedFilters?.[key] !== '-100'
                    && selectedFilters?.[key] !== -100
                    && selectedFilters?.[key] !== false
                )
            );
        })?.map((key) => {
            const filterKey = filtersKeys?.find((val) => {
                return filters?.[val]?.filterKey === key;
            });
            const keyToUse = settings?.general?.useOldRouter === true ? key : filterKey;
            const filter = filters[filterKey];
            const value = selectedFilters?.[key];

            if (filter?.type === 'check') {
                newFilter[keyToUse] = value === true ? 'Ja' : undefined;
            } else if (filter?.type === 'multiselect') {
                const facet = allFacets?.[filter?.facetKey];
                newFilter[keyToUse] = facet?.filter((val) => (Array.isArray(value) ? value : [value])?.includes(val?.i))?.map((val) => val?.tSlug)?.join(',');
            } else if (filter?.type === 'range') {
                newFilter[keyToUse] = (value as number[])?.join(',');
                if (newFilter[keyToUse] === ',') {
                    delete newFilter[keyToUse];
                }
            } else if (filter?.type === 'singleselect') {
                const facet = allFacets?.[filter?.facetKey];
                newFilter[keyToUse] = facet?.find((val) => value === val?.i)?.tSlug;
            } else if (filter?.type === 'number') {
                newFilter[keyToUse] = value;
            } else if (filter?.type === 'text') {
                newFilter[keyToUse] = value;
            }
        });
        if (settings?.general?.debug) {
            console.log(`${module} - generateLink - newFilter`, newFilter);
        }

        if (type === 'filter' && settings?.general?.useOldRouter === true) {
            const filterQuery = Object.keys(newFilter)?.reduce((prev, curr) => [...prev, `${curr}=${newFilter[curr]}`], [])?.join('&');
            return `${pathOnly ? '' : host}${searchPath}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : !checkUndefinedNullOrEmpty(filterQuery) ? '?' : ''}${filterQuery}`;
        } else {
            let pathnameArray = [newFilter?.hersteller || '-', newFilter?.modell || '-', newFilter?.typ || '-', newFilter?.standort || '-'];
            while (pathnameArray[pathnameArray.length - 1] === '-') {
                pathnameArray = pathnameArray.slice(0, pathnameArray.length - 1);
            }
            const filterQuery = Object.keys(newFilter)?.filter((key) => !['hersteller', 'modell', 'typ', 'standort']?.includes(key))?.reduce((prev, curr) => [...prev, `${curr}=${newFilter[curr]}`], [])?.join('&');
            return `${pathOnly ? '' : host}${type === 'filter' ? (searchPath === '/' ? '' : searchPath) : ''}/${pathnameArray?.join('/')}${!checkUndefinedNullOrEmpty(searchPathSearch) ? searchPathSearch : !checkUndefinedNullOrEmpty(filterQuery) ? '?' : ''}${filterQuery}`;
        }
    } else {
        console.error(`Type ${type} unknown`);
        return undefined;
    }
}
