/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configSchuler: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30046,
        enable: true,
        isGruppe: true,
        projectName: 'Autowelt Schuler',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            offsetX: 2,
            offsetY: 2,
            position: 'left',
        },
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        useEyecatcher: true,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'siegel', 'aktion', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'motor', 'getriebe', 'aufbau', 'standort', 'km', 'preis', 'rate', 'ez', 'siegel', 'aktion', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
        tradeInLink: 'https://gib-uns-deinen-gebrauchten.de/schuler/26321?utm_source=autohaus_schuler&utm_medium=banner&utm_campaign=20210929_epartner_teaser',
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        filter: ['hersteller', 'modell', 'motor', 'typ'],
        noAnimations: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
        fontSize: 14,
        scrollOffset: 100,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                'backgroundColor': '#fff',
                                'color': '<colorPrimary>',
                            },
                            '> svg': {
                                width: '20.8px',
                                height: '20.8px',
                            },
                        },
                    },
                    label: {
                        [getStyleQuery()]: {
                            fontWeight: 'bold',
                            textTransform: 'none',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'backgroundColor': '<colorPrimary>',
                            'border': '1px solid <colorPrimary>',
                            'color': '#fff',
                            '&:hover': {
                                backgroundColor: '#fff',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                border: '1px solid <colorPrimary>',
                                color: '#fff',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                backgroundColor: 'transparent',
                                borderColor: 'rgba(145, 158, 171, 0.24)',
                                color: 'rgba(145, 158, 171, 0.8)',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'backgroundColor': 'transparent',
                            'border': '1px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            'height': 36,
                            '&#detailview2_button_exposee': {
                                height: 42,
                            },
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                border: '1px solid <colorPrimary>',
                                color: '#fff',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '> svg': {
                                width: '21.6px !important',
                                height: '21.6px !important',
                            },
                            '&:hover': {
                                color: '#fff',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid^="carTile_"] hr.MuiDivider-root': {
                                borderBottom: '1px dashed black',
                                maxWidth: '120%',
                            },
                            '&[data-testid="LocationUserCard"] hr.MuiDivider-root': {
                                borderBottom: '1px dashed black',
                                maxWidth: '100%',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarPriceGrid_Container"] > hr.MuiDivider-vertical': {
                                borderRightColor: '#000 !important',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            caption: {
                [getStyleQuery()]: {
                    fontSize: 14,
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#e63137',
    },
};

export default configSchuler;
