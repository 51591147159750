import checkUndefinedNullOrEmpty from "./checkUndefinedNullOrEmpty";

export const availableImageSizes = [100, 134, 320, 360, 480, 520, 640, 1024, 1280, 1600, 1920, 2048];

export default function imageLoader({ src: origSrc, width }: { src: string, width?: number }): string {
    const srcSplit = origSrc?.split('/') || [];
    const possibleWidth = parseInt(srcSplit[srcSplit.length - 1]);
    const hasWidthNumber = !isNaN(possibleWidth) && possibleWidth > 0;

    if (hasWidthNumber && !checkUndefinedNullOrEmpty(width)) {
        if (!checkUndefinedNullOrEmpty(origSrc) && !origSrc.endsWith('/placeholder.svg')) {
            const cuttedSrc = origSrc.substring(0, origSrc.lastIndexOf('/'));
            return cuttedSrc + '/' + width;
        } else {
            return '/media/placeholder.svg';
        }
    }

    return origSrc;
}
