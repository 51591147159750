import { TranslationsCustomer } from './translationsCustomer';

const enUS: TranslationsCustomer = {
    54440: {
        additionalInfo: {
            0: 'Min. 1 year warranty valid in the manufacturer network in Europe',
            1: '14 day money back guarantee',
            2: 'Zero maintenance costs for min. 1 or 10.000 km',
        },
    },
};

export default enUS;
