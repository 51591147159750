/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configMeinhold: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 11970,
        enable: true,
        projectName: 'Autohaus Meinhold',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        buttons: {
            ...configDefault.contact.buttons,
            position: 'left',
        },
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Source Sans Pro", Helvetica, Arial, "Lucida Grande", Lucida, sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    [getStyleQuery()]: {
                        'div.UseContact_ContactTextButtonContainer': {
                            'borderRadius': '1.75rem',
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        border: 'none !important',
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.MuiIconButton-root': {
                                boxShadow: 'none',
                                color: '#637381',
                            },
                            '&.MuiTab-root': {
                                'backgroundColor': 'transparent',
                                'color': '#637381',
                                'boxShadow': 'none',
                                '&[aria-selected="true"]': {
                                    color: 'inherit',
                                },
                            },
                            '& > input.PrivateSwitchBase-input': {
                                position: 'absolute !important',
                            },
                            '&.MuiButton-text.MuiButton-colorInherit.MuiButton-textInherit': {
                                '&:hover': {
                                    color: 'inherit',
                                },
                            },
                            '&.MuiPickersDay-root.MuiPickersDay-dayWithMargin': {
                                'color': '#637381',
                                'boxShadow': 'none',
                                '&.Mui-selected': {
                                    color: '#fff',
                                },
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'backgroundColor': '#2d4251',
                            'borderRadius': '1.75rem',
                            'color': '#fff',
                            'fontSize': '14px',
                            'padding': '7px 24px',
                            '&:hover': {
                                backgroundColor: '<colorSecondary>',
                                boxShadow: 'none',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'borderRadius': '1.75rem',
                            'fontSize': '14px',
                            'padding': '7px 24px',
                            // contact buttons next to fabs
                            '&:hover': {
                                backgroundColor: '<colorPrimary>',
                                borderColor: '<colorPrimary>',
                                color: '#fff',
                            },
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                backgroundColor: 'transparent',
                                borderColor: 'rgba(145, 158, 171, 0.24)',
                                color: 'rgba(145, 158, 171, 0.8)',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'borderRadius': '1.75rem',
                            'fontSize': '14px',
                            'padding': '7px 24px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                'backgroundColor': '#2d4251',
                                'color': '#fff',
                                'padding': '6px 8px',
                                '&:hover': {
                                    'backgroundColor': '<colorSecondary>',
                                },
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    textError: {
                        [getStyleQuery()]: {
                            'color': '#ff5630',
                            '&:hover': {
                                color: '#ff5630',
                                background: '#fff2ee',
                            },
                        },
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '& a.MuiLink-root.MuiLink-underlineHover': {
                                textDecoration: 'underline !important',
                            },
                        },
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    // @ts-ignore
                    labelPlacementEnd: {
                        [getStyleQuery()]: {
                            marginBottom: 0,
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CalcFdSummary_Card"]': {
                                background: '<colorPrimary>',
                            },
                            '> div.MuiPickersLayout-root > div.MuiPickersLayout-contentWrapper > div > div.MuiDateCalendar-root > div.MuiPickersFadeTransitionGroup-root > div > div.MuiYearCalendar-root > div.MuiPickersYear-root > button.MuiPickersYear-yearButton': {
                                'color': '#637381',
                                'boxShadow': 'none',
                                '&.Mui-selected': {
                                    color: '#fff',
                                },
                            },
                            '&.LocationUserCard a.MuiLink-root.MuiLink-underlineHover:hover': {
                                color: '<colorSecondary>',
                            },
                            '&.LocationUserCard p.MuiTypography-body1:hover': {
                                color: '<colorSecondary>',
                            },
                            '&.LocationUserCard > button.MuiButton-contained.MuiButton-containedSizeLarge.MuiButton-colorPrimary': {
                                borderRadius: 0,
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            caption: {
                [getStyleQuery()]: {
                    '&.MuiLink-root.MuiLink-underlineHover': {
                        textDecoration: 'underline',
                    },
                    '& a.MuiLink-root.MuiLink-underlineHover': {
                        textDecoration: 'underline',
                    },
                },
            },
            h1: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#092233',
        secondaryColor: '#dd171b',
    },
};

export default configMeinhold;
