/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configSchaeferAutomobile: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30176,
        isGruppe: true,
        enable: true,
        metaTitlePrefix: 'Schäfer Automobile:',
        metaTitleSeperator: ' ',
        projectName: 'Schäfer Automobile',
        searchPath: '/weilburg/gebrauchtwagen',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    api: {
        ...configDefault.api,
        autoUncle: {
            ...configDefault.api.autoUncle,
            id: 'vASTIZBOnh',
            origin: 'schaeferautomobile.de',
            tradeIn: true,
        },
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'standort', 'typ', 'preis', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'standort', 'preis', 'ez', 'aus'],
        },
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '"sourcesans_", "Open Sans", "Arial", sans-serif',
        fontSize: 18,
        muiComponents: () => ({
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        'marginBottom': '0 !imporant',
                        'marginTop': '0 !imporant',
                        '& > svg': {
                            height: '21px',
                            width: '21px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'color': '#fff',
                            'padding': '6px 12px',
                            '&#schnellsucheRootPaperAnimationLoadingButton': {
                                padding: '8px 35px',
                            },
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            'padding': '6px 12px',
                            '&.Mui-disabled, &.Mui-disabled:hover': {
                                border: '1px solid #e5e8eb',
                                color: '#e5e8eb',
                            },
                            '&:hover': {
                                border: '1px solid <colorPrimary>',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                    soft: {
                        [getStyleQuery()]: {
                            'padding': '6px 12px',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                padding: '6px 8px',
                            },
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            float: 'none !important',
                            fontFamily: '<fontFamily> !important',
                            fontWeight: 600,
                        },
                    },
                },
            },
            MuiFab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:hover': {
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="LocationInfo"] button.MuiButton-text.MuiButton-textPrimary': {
                                'color': '<colorPrimary> !important',
                                '&:hover': {
                                    color: '<colorPrimary> !important',
                                },
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': {
                                'color': 'rgb(33, 43, 54)',
                                '&:hover': {
                                    background: '#f3dddc',
                                },
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.Mui-selected:hover': {
                                color: 'rgb(33, 43, 54)',
                                textDecoration: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    color: '#333333',
                },
            },
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"oswald", ' + '<fontFamily>',
                    fontWeight: 300,
                    textTransform: 'none',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    fontFamily: '"oswald", ' + '<fontFamily>',
                    fontWeight: 500,
                    textAlign: 'left',
                    textTransform: 'uppercase',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorSecondary>',
                    fontFamily: '"oswald", ' + '<fontFamily>',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"oswald", ' + '<fontFamily>',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"oswald", ' + '<fontFamily>',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                },
            },
        }),
        primaryColor: '#b52e28',
        secondaryColor: '#a87619',
    },
};

export default configSchaeferAutomobile;
