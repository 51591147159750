/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configCampingSchuler: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30046,
        enable: true,
        isGruppe: true,
        projectName: 'Campingwelt Schuler',
        searchPath: '/fahrzeugsuche',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
        linkDisclaimer: '/datenschutz',
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
    },
    aktionsslider: {
        ...configDefault.aktionsslider,
        adjustColNumberByListLength: true,
        forcedFilter: {
            ...configDefault.aktionsslider.forcedFilter,
            aktion: undefined,
            aufbau: 5101,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        forcedFilter: {
            ...configDefault.fahrzeugsuche.forcedFilter,
            aufbau: 5101,
        },
        useEyecatcher: true,
    },
    schnellsuche: {
        ...configDefault.schnellsuche,
        filter: ['hersteller', 'modell', 'motor', 'typ'],
    },
    style: {
        ...configDefault.style,
        fontSize: 16,
        fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"Roboto Slab", ' + '<fontFamily>',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"Roboto Slab", ' + '<fontFamily>',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Roboto Slab", ' + '<fontFamily>',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Roboto Slab", ' + '<fontFamily>',
                },
            },
        }),
        primaryColor: '#e63137',
    },
};

export default configCampingSchuler;
