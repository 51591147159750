/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { FilterOrder, FilterSort } from '../../_types/Car';
import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configDabag: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 30174,
        isGruppe: true,
        enable: true,
        projectName: 'dabag',
        searchPath: '/fahrzeuge',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz',
    },
    api: {
        ...configDefault.api,
        webcalc: {
            ...configDefault.api.webcalc,
            enabled: false,
        },
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        defaultSort: { order: FilterOrder.ASC, sort: FilterSort.Hersteller },
        filter: {
            sidebar: ['hersteller', 'modell', 'aufbau', 'standort', 'preis', 'ez', 'motor', 'getriebe', 'farbe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]motor', '[LG]getriebe', 'aufbau', 'standort', 'preis', 'ez', 'farbe', 'aus'],
        },
        hideHeading: false,
        shareButtons: ['telegram', 'whatsapp'],
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1.25,
        fontFamily: '"proxima", sans-serif',
        fontSize: 16,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'div.MuiStack-root[data-testid="Pager_Container"]': {
                        marginBottom: 2,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            'background': '<colorPrimary>',
                            'border': '1px solid <colorPrimary>',
                            'color': '#fff',
                            '&:hover': {
                                background: '#fff',
                                color: '<colorPrimary>',
                            },
                        },
                    },
                },
            },
            MuiGrid2: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&[data-testid="CarDetailsGrid_Container"] p.MuiTypography-body1': {
                                hyphens: 'auto',
                                wordBreak: 'break-word',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            button: {
                [getStyleQuery()]: {
                    fontFamily: '"proxima", sans-serif',
                    fontWeight: 400,
                    fontSize: '16px',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    fontFamily: '"proximacd", sans-serif',
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontFamily: '"proximacd", sans-serif',
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"proximacd", sans-serif',
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"proximacd", sans-serif',
                    fontWeight: 700,
                },
            },
        }),
        primaryColor: '#000',
    },
};

export default configDabag;
