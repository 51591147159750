import { Box, BoxProps, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';

type IProps = BoxProps;

interface Props extends IProps {
    height: number;
    src: string;
    step: number;
    sx?: SxProps<Theme>;
    width: number;
}

function Image360aElement(props: Props) {
    const { height = 480, src, step = 24, sx, width = 640 } = props;

    function getMouseXY(mouseEvent, spritesheet) {
        const obj = document.querySelector('.react-responsive-spritesheet');
        const objRect = obj.getBoundingClientRect();
        const objWidth = objRect.width;

        let xposRelative = 0;
        if (mouseEvent) xposRelative = mouseEvent.clientX - objRect.left;

        const frames = spritesheet.getInfo('steps');
        let newIndex = Math.ceil(frames * (xposRelative / objWidth));
        if (newIndex < 0) newIndex = 0;
        else if (newIndex > frames - 1) newIndex = frames - 1;
        spritesheet.goToAndPause(newIndex);
    }

    return (
        <Box
            component="span"
            sx={{
                'width': '100%',
                'lineHeight': 0,
                'display': 'block',
                'overflow': 'hidden',
                'position': 'relative',
                'borderRadius': 2,
                '& .ahdImageWrapper': {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    lineHeight: 0,
                    position: 'absolute',
                    backgroundSize: 'cover !important',
                },
                ...sx,
            }}
        >
            <div>
                <Spritesheet
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                    autoplay
                    timeout={500}
                    loop
                    image={src}
                    widthFrame={width}
                    heightFrame={height}
                    steps={step}
                    fps={2}
                    onMouseDown={(spritesheet) => {
                        spritesheet.pause();
                        const e = document.querySelector('.react-responsive-spritesheet');
                        // @ts-ignore
                        e.onmousemove = (eMouseMove) => getMouseXY(eMouseMove, spritesheet);
                    }} onMouseUp={(spritesheet) => {
                        spritesheet.play();
                        const e = document.querySelector('.react-responsive-spritesheet');
                        // @ts-ignore
                        e.onmousemove = null;
                    }} onMouseLeave={(spritesheet) => {
                        spritesheet.play();
                        const e = document.querySelector('.react-responsive-spritesheet');
                        // @ts-ignore
                        e.onmousemove = null;
                    }} />
            </div>
        </Box>
    );
}

export default React.memo(Image360aElement);
