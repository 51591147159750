/**
 * @file Handles all configurations for the homepage tools.
 * @author Arne Rief
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';
import { getStyleQuery } from '../../utils/tools';

const configKießling: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 36883,
        enable: true,
        metaTitlePrefix: 'Autohaus Kiessling',
        metaTitleSeperator: ' - ',
        projectName: 'Autohaus Kießling',
        searchPath: '/gebrauchtwagen-top-angebote.php',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.php',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        filter: {
            sidebar: ['sofort', 'hersteller', 'modell', 'aufbau', 'typ', 'km', 'preis', 'rate', 'ez', 'motor', 'getriebe', 'aus'],
            top: ['[SM]hersteller', '[SM]modell', '[LG]typ', '[LG]sofort', 'aufbau', 'motor', 'getriebe', 'km', 'preis', 'rate', 'ez', 'aus'],
        },
        hideHeading: false,
        topFilter: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"VWHeadWeb-Regular", "Helvetica", sans-serif',
        fontSize: 16,
        muiComponents: () => ({
            MuiCssBaseline: {
                styleOverrides: {
                    'li::before': {
                        content: 'none !important',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        [getStyleQuery()]: {
                            padding: '12px 20px',
                            background: 'linear-gradient(to bottom,rgba(2,107,180,.7) 0,rgba(2,107,180,1) 100%) !important',
                            color: '#f4f5f7',
                        },
                    },
                    outlined: {
                        [getStyleQuery()]: {
                            padding: '12px 20px',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            padding: '12px 20px',
                        },
                    },
                },
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            textAlign: 'left',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            body1: {
                [getStyleQuery()]: {
                    textAlign: 'left',
                },
            },
            caption: {
                [getStyleQuery()]: {
                    textAlign: 'left',
                },
            },
            h3: {
                [getStyleQuery()]: {
                    color: '#2C2F31',
                    textAlign: 'left',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '#2C2F31',
                    textAlign: 'left',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    color: '#2C2F31',
                    textAlign: 'left',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    color: '#2C2F31',
                    textAlign: 'left',
                },
            },
        }),
        primaryColor: '#006AB3',
    },
};

export default configKießling;
