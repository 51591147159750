import { SelectOption } from '../../_types/Car';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';
import { EffizienzClass } from './types';

export const effizienzSteps: (SelectOption & { color: string })[] = [
    { i: EffizienzClass.N_A, iSend: 'None', t: 'N/A', color: '#232323', nested: 1, effizienz: true },
    { i: EffizienzClass.A, t: 'A', color: '#00A650', nested: 1, effizienz: true },
    { i: EffizienzClass.B, t: 'B', color: '#4FBB48', nested: 2, effizienz: true },
    { i: EffizienzClass.C, t: 'C', color: '#BED730', nested: 3, effizienz: true },
    { i: EffizienzClass.D, t: 'D', color: '#FFF200', nested: 4, effizienz: true },
    { i: EffizienzClass.E, t: 'E', color: '#FCB217', nested: 5, effizienz: true },
    { i: EffizienzClass.F, t: 'F', color: '#F36F21', nested: 6, effizienz: true },
    { i: EffizienzClass.G, t: 'G', color: '#ED1C24', nested: 7, effizienz: true },
]?.filter((e) => !checkUndefinedNullOrEmpty(e));
