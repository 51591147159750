/**
 * @file Handles all configurations for the homepage tools.
 * @author Phillip Kühn
 */

import { getStyleQuery } from '../../utils/tools';
import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configFranke: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 49252,
        enable: true,
        projectName: 'Autohaus Franke',
        searchPath: '/fahrzeugsuche.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    module: {
        ...configDefault.module,
        aktionsslider: true,
        schnellsuche: true,
    },
    style: {
        ...configDefault.style,
        borderRadius: 1,
        fontFamily: '"Lato", "Open Sans", "Arial", sans-serif',
        fontSize: 16,
        scrollOffset: 90,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        [getStyleQuery()]: {
                            'background': 'transparent',
                            'backgroundColor': '#fff !important',
                            'color': '<colorPrimary> !important',
                            'textShadow': 'none',
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                            },
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            background: 'transparent',
                            backgroundColor: '<colorSecondary> !important',
                            color: '<colorPrimary> !important',
                            textShadow: 'none',
                        },
                    },
                    text: {
                        [getStyleQuery()]: {
                            'background': 'transparent',
                            'backgroundColor': 'transparent !important',
                            'color': '<colorPrimary> !important',
                            'textShadow': 'none',
                            '&:hover': {
                                backgroundColor: '<colorSecondary> !important',
                            },
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    sizeMedium: {
                        [getStyleQuery()]: {
                            'background': 'transparent',
                            'boxShadow': 'none',
                            '&:hover': {
                                background: 'rgba(99, 115, 129, 0.08)',
                            },
                        },
                    },
                },
            },
            MuiStack: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&.FahrzeugSucheFilterSort_Container > button.MuiButton-text': {
                                'background': 'transparent !important',
                                'color': '#212b36 !important',
                                '&:hover': {
                                    backgroundColor: '<colorSecondary> !important',
                                    color: '<colorPrimary> !important',
                                },
                            },
                            '&[data-testid="CalcFdTypeOptions_CalcFd"] label.MuiFormControlLabel-labelPlacementEnd': {
                                display: 'flex',
                                marginBottom: 0,
                            },
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            '&:focus': {
                                outline: 'none',
                                textDecoration: 'none',
                            },
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h4: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontWeight: 700,
                },
            },
            subtitle1: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#2765b0',
        secondaryColor: '#dce6f2',
    },
};

export default configFranke;
