/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from '../types';
import configDefault from '../_defaultValues';

const configTiemeyer: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54587,
        enable: true,
        projectName: 'Tiemeyer',
        searchPath: '/fahrzeugsuche',
        showroooom: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: false,
    },
    style: {
        ...configDefault.style,
        fontFamily: '"Nunito Sans", "Open Sans", "Arial", sans-serif',
        primaryColor: '#1d6493',
    },
};

export default configTiemeyer;
