import checkUndefinedNullOrEmpty from "./checkUndefinedNullOrEmpty";

export default function decodeHTMLEntities(text: string): string {
    if (checkUndefinedNullOrEmpty(text)) {
        return text;
    }

    const list = [
        ['amp', '&'],
        ['#x26', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#47', '/'],
        ['lt', '<'],
        ['#x3C', '<'],
        ['gt', '>'],
        ['#x3E', '>'],
        ['nbsp', ' '],
        ['#xA0', ' '],
        ['#13', ''],
        ['quot', '"'],
        ['#x22', '"'],
        ['Auml', 'Ä'],
        ['#xC4', 'Ä'],
        ['#xE4', 'ä'],
        ['auml', 'ä'],
        ['#xD6', 'Ö'],
        ['Ouml', 'Ö'],
        ['#xF6', 'ö'],
        ['ouml', 'ö'],
        ['#xDC', 'Ü'],
        ['Uuml', 'Ü'],
        ['#xFC', 'ü'],
        ['uuml', 'ü'],
        ['#xDF', 'ß'],
        ['szlig', 'ß'],
    ];
    for (let i = 0; i < list?.length; i++) {
        text = text?.replace(new RegExp('&' + list[i][0] + ';', 'g'), list[i][1]);
    }
    return text;
}
