import modules from "../../_json/modules";

export default function getStyleQuery(additionalQuery?: string, noAmpersand?: boolean): string {
    const elements = ['div[role="presentation"]', 'div[role="gridcell"]', 'div[role="dialog"]', 'div[role="tooltip"]', 'div[role="button"]'];
    modules.map(function (a) { elements.push('div.ahdContainer#' + a.t); });

    const returnString: string[] = [];
    elements.map(function (el) {
        const strArr = ['html > body', el, (additionalQuery || '')];
        if (!noAmpersand) {
            strArr.push('&');
        }
        returnString.push(strArr.join(' '));
    });

    return returnString.join(', ');
}
