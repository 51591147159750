/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Arne Rief
 */

import { getStyleQuery } from '../../utils/tools';
import configDefault from '../_defaultValues';
import { ConfigObject } from '../types';

const configJosefGeers: ConfigObject = {
    ...configDefault,
    general: {
        ...configDefault.general,
        idFirma: 54565,
        enable: true,
        projectName: 'Autohaus Josef Geers',
        searchPath: '/fahrzeugangebot.html',
        useOldRouter: true,
        useOldVersion: true,
    },
    contact: {
        ...configDefault.contact,
        isLeadSystem: true,
        linkDisclaimer: '/datenschutz.html',
    },
    module: {
        ...configDefault.module,
        schnellsuche: true,
    },
    fahrzeugsuche: {
        ...configDefault.fahrzeugsuche,
        hideHeading: false,
    },
    style: {
        ...configDefault.style,
        borderRadius: 0,
        fontFamily: '"Open Sans", "Arial", sans-serif',
        fontSize: 14,
        muiComponents: () => ({
            MuiButton: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            fontFamily: '"Alata", sans-serif',
                        },
                    },
                    contained: {
                        [getStyleQuery()]: {
                            border: '1px solid <colorPrimary>',
                        },
                    },
                    // @ts-ignore
                    soft: {
                        [getStyleQuery()]: {
                            'border': '1px solid <colorPrimary>',
                            'color': '<colorPrimary>',
                            // contact buttons next to fabs
                            '&[data-testid^="UseContact_ContactTextButton_"]': {
                                fontSize: '13px',
                                padding: '6px 8px',
                            },
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        [getStyleQuery()]: {
                            color: '#3c5456',
                        },
                    },
                },
            },
        }),
        muiTypography: () => ({
            h3: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Alata", sans-serif',
                },
            },
            h4: {
                [getStyleQuery()]: {
                    color: '<colorPrimary>',
                    fontFamily: '"Alata", sans-serif',
                },
            },
            h5: {
                [getStyleQuery()]: {
                    fontFamily: '"Alata", sans-serif',
                },
            },
            h6: {
                [getStyleQuery()]: {
                    fontFamily: '"Alata", sans-serif',
                },
            },
            stubtitle1: {
                [getStyleQuery()]: {
                    textTransform: 'none',
                },
            },
        }),
        primaryColor: '#0c375c',
        secondaryColor: '#2d2e33',
    },
};
export default configJosefGeers;
