import checkUndefinedNullOrEmpty from './checkUndefinedNullOrEmpty';
import checkUndefinedOrNull from './checkUndefinedOrNull';

export default function uniqueArray<T>(array: T[], unifyBy?: T extends object ? keyof T : never): T[] {
    if (checkUndefinedNullOrEmpty(array)) {
        return [];
    }

    const returnArr = array?.filter(function (value, index, self) {
        if (!checkUndefinedNullOrEmpty(unifyBy) && typeof value === 'object') {
            return index === self.findIndex((t) => (
                t[unifyBy] === value[unifyBy]
            ));
        }

        return self.indexOf(value) === index;
    });
    return returnArr?.filter((e) => !checkUndefinedOrNull(e));
};
