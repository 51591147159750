import { TextField, darken, lighten } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import { checkUndefinedNullOrEmpty } from '../../utils/tools';

export type RHFDateTimePickerProps = DateTimePickerProps<Date> & {
    testId?: string
    name: string;
    label?: string;
    format: string;
};

export function RHFDateTimePicker({ testId, name, label, format, ...other }: Omit<RHFDateTimePickerProps, 'renderInput'>) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                    {...field}
                    key={`${testId}date${label}`}
                    data-testid={`${testId}date${label}`}
                    label={label}
                    ampm={false}
                    closeOnSelect={false}
                    format={format}
                    onChange={(newValue) => field.onChange({ target: { value: newValue } })}
                    onError={(err, val) => {
                        console.error(err, val);
                    }}
                    {...other}
                    slots={{ textField: TextField }}
                    slotProps={{
                        ...other?.slotProps,
                        textField: {
                            ...other?.slotProps?.textField,
                            error: !checkUndefinedNullOrEmpty(error),
                            fullWidth: true,
                            helperText: error?.message,
                            inputProps: {
                                'data-testid': `RHFDateTimePicker_${name}`,
                            },
                        },
                        digitalClockSectionItem: {
                            ...other?.slotProps?.digitalClockSectionItem,
                            sx: {
                                '&:hover': {
                                    backgroundColor: (theme) => lighten(theme.palette.primary.main, 0.95),
                                },
                                '&.Mui-selected': {
                                    'backgroundColor': (theme) => theme.palette.primary.main + ' !important',
                                    'color': '#fff',
                                    '&:hover': {
                                        backgroundColor: (theme) => darken(theme.palette.primary.main, 0.2) + ' !important',
                                    },
                                },
                            },
                        },
                    }}
                />
            )
            }
        />
    );
}
