import { Box, BoxProps, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import ReactPannellum from 'react-pannellum';

type IProps = BoxProps;

interface Props extends IProps {
    src: string;
    sx?: SxProps<Theme>;
}

function Image360iElement(props: Props) {
    const { src, sx } = props;

    return (
        <Box
            component="div"
            id="ReactPannelium"
            sx={{
                lineHeight: 0,
                overflow: 'hidden',
                borderRadius: 2,
                ...sx,
            }}
        >
            <ReactPannellum
                style={{ backgroundColor: 'transparent' }}
                sceneId="firstScene"
                id="ReactPannelium"
                imageSource={src}
                config={{ autoLoad: true, autoRotate: -2 }}
            />
        </Box>
    );
}

export default React.memo(Image360iElement);
