/**
 * @file Handles all configurations for the HOMEPAGE-TOOLs.
 * @author Phillip Kühn
 */

import { ConfigObject } from './types';
import configDefault from './_defaultValues';
import configLocal from './_local';

const configLocalSr: ConfigObject = {
    ...configDefault,
    ...configLocal,
    general: {
        ...configDefault.general,
        ...configLocal.general,
        projectName: 'AHD Autohaus Digital',
        showroooom: true,
    },
};

export default configLocalSr;
