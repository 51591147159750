import { Duration, format, formatDistance, formatDuration, intervalToDuration, isValid, parse, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { toDays, toHours, toMilliseconds, toMinutes, toMonths, toSeconds, toWeeks, toYears } from 'duration-fns';

const checkUndefined = (e: any): e is undefined => e === undefined || e === 'undefined';

const checkUndefinedOrNull = (e: any): e is (undefined | null) => checkUndefined(e) || e === null || e === 'null';

const checkUndefinedNullOrEmpty = (e: any): e is (undefined | null) => {
    if (checkUndefinedOrNull(e)) return true;
    if (Array.isArray(e) && e.length < 1) return true;
    if (!Array.isArray(e) && typeof e === 'object' && Object.keys(e).length < 1) return true;
    if (!Array.isArray(e) && typeof e !== 'object' && (e === '' || e === '""')) return true;
    return false;
};

type TimeTypes = keyof Duration | 'milliseconds';
type TimeTypesElement = {
    i: TimeTypes,
    f: (d: Duration) => number,
};

const timeTypes: TimeTypesElement[] = [
    { i: 'milliseconds', f: toMilliseconds },
    { i: 'seconds', f: toSeconds },
    { i: 'minutes', f: toMinutes },
    { i: 'hours', f: toHours },
    { i: 'days', f: toDays },
    { i: 'weeks', f: toWeeks },
    { i: 'months', f: toMonths },
    { i: 'years', f: toYears },
];

export function getDuration(d: Date, d2?: Date): Duration;
export function getDuration(d: Date, d2?: Date, string?: TimeTypes): number;
export function getDuration(d: Date, d2?: Date, string?: TimeTypes[]): string;
export function getDuration(d: Date, d2?: Date, string?: TimeTypes | TimeTypes[]): string | number | Duration {
    if (!isValid(d) || (d2 && !isValid(d2))) {
        return 'Ungültig!';
    }
    const newD2 = d2 || new Date();
    const endIsBigger = newD2 > d;
    const duration = intervalToDuration({
        start: endIsBigger ? d : newD2,
        end: endIsBigger ? newD2 : d,
    });

    if (checkUndefinedNullOrEmpty(string)) {
        return duration;
    } else {
        if (Array.isArray(string)) {
            const config = { addSuffix: true, format: string?.reduce((p, c) => (c === 'milliseconds' ? p : [...p, c]), []), delimiter: ', ', locale: de };
            return formatDuration(duration, config);
        } else {
            const timeTypeFunction = timeTypes?.find((t) => t?.i === string)?.f;
            if (!checkUndefinedNullOrEmpty(timeTypeFunction)) {
                const newDuration = timeTypeFunction(duration);
                return newDuration * (endIsBigger ? 1 : -1);
            }
            return duration;
        }
    }
}

export function getDistance(d: Date, d2?: Date): string | number | Duration {
    if (!isValid(d) || (d2 && !isValid(d2))) {
        return 'Ungültig!';
    }

    return formatDistance(d, d2, { locale: de, addSuffix: true });
}

export function formatDate(d: Date | number, formatStr: 'yyyyMMdd' | 'HH:mm' | 'HH:mm:ss' | 'yyyy' | 'MM' | 'MM.yyyy' | 'dd.MM.yyyy' | 'dd.MM.yyyy HH:mm' | 'dd.MM.yyyy HH:mm:ss' | 'dd. MMMM yyyy HH:mm' | 'T' | 'MMMM yyyy' | 'yyyy-MM-dd' | 'ddMMyyyy_HHmmss' | 'yyyy-MM-dd' | 'yyyy-MM-dd HH:mm:ss' | 'MMyy' | 'dd.MM' | 'dd.MM.yy'): string {
    if (!isValid(d)) {
        return 'Ungültig!';
    }
    return format(d, formatStr, { locale: de, weekStartsOn: 1 });
}

export function formatTimestamp(d: Date): number {
    if (!isValid(d)) {
        return null;
    }
    return parseFloat(formatDate(d, 'T'));
}

export function parseDate(d: string | number, formatStr: 'ISO' | 'yyyyMMdd' | 'HH:mm' | 'yyyy' | 'MM.yyyy' | 'dd.MM.yyyy' | 'dd.MM.yyyy HH:mm' | 'dd.MM.yyyy HH:mm:ss' | 'dd.MM yyyy HH:mm' | 'yyyy-MM-dd' | 'T'): Date {
    return formatStr === 'ISO' ? parseISO(typeof d === 'number' ? d?.toString() : d) : parse(typeof d === 'number' ? d?.toString() : d, formatStr, new Date(), { locale: de, weekStartsOn: 1 });
}

export function isBefore(d: Date, d2: Date): boolean {
    if (!isValid(d) || !isValid(d2)) {
        return false;
    }
    return formatTimestamp(d) < formatTimestamp(d2);
}

export function isBeforeOrSame(d: Date, d2: Date): boolean {
    if (!isValid(d) || !isValid(d2)) {
        return false;
    }
    return formatTimestamp(d) <= formatTimestamp(d2);
}

export function isAfter(d: Date, d2: Date): boolean {
    if (!isValid(d) || !isValid(d2)) {
        return false;
    }
    return formatTimestamp(d) > formatTimestamp(d2);
}

export function isSameOrAfter(d: Date, d2: Date): boolean {
    if (!isValid(d) || !isValid(d2)) {
        return false;
    }
    return formatTimestamp(d) >= formatTimestamp(d2);
}

export function isBetween(d: Date, d2: Date, d3: Date): boolean {
    if (!isValid(d) || !isValid(d2) || !isValid(d3)) {
        return false;
    }
    return formatTimestamp(d2) <= formatTimestamp(d) && formatTimestamp(d) >= formatTimestamp(d3);
}
